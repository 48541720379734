/* TermsConditionsModal.css */
.policy-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
    overflow: hidden;
}

.policy-modal-content {
    background: var(--screen-background-color);
    color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    border: 1px solid rgba(190, 5, 250, 0.2);
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.15);
    animation: slideUpIn 0.5s ease-out;
}

.policy-close-button {
    position: absolute;
    background-color: transparent;
    color:white;
    position: absolute;
    right:1%;
    top:1%;
    font-size: 20px;
    padding: 0px 5px;
    transition: all 0.3s ease;
    z-index: 10;
}

.policy-close-button:hover {
color: #BE05FA;
transform: rotate(90deg);
}

.policy-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.policy-icon {
    color: var(--buttons-background-color);
    margin-right: 10px;
}

.effective-date {
    font-size: 0.9rem;
    color: #eae7e1;
}

.policy-modal-body {
    line-height: 1.8;
    font-size: 1rem;
    color: #e6e6e6;
    padding: 20px;
}

.policy-modal-body h3 {
    margin-top: 20px;
    color: var(--buttons-background-color);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}
.policy-modal-body p {
    margin-bottom: 16px; /* Add space between paragraphs */
}

.policy-modal-body a {
    color: #00bfff;
    text-decoration: none;
    font-weight: bold;
}

.policy-modal-body a:hover {
    text-decoration: underline;
}

.policy-heading{
    display: flex;
    flex-direction: column;
}

.policy-company-name{
    margin-left: 40px;
}
/* Cookie List */
.policy-list {
    list-style: none;
    padding: 0;
}

.policy-list li {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 12px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.policy-list li strong {
    flex: 1;
    font-size: 1rem;
    color: var(--buttons-background-color);

}

.policy-list li span {
    flex: 3;
    font-size: 0.9rem;
    color: #ffffff;
}

.policy-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.policy-table th, .policy-table td {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px;
    text-align: left;
    color: #e6e6e6;
}

.policy-table th {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--buttons-background-color);
}

.policy-table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.05);
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUpIn {
    from {
        transform: translateY(100vh);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width:600px) {
    .policy-list li{
        flex-direction: column;
    }
}