.resume-guru {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.fancy-carousel {
  position: relative;
  flex: 0 0 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  perspective: 1000px;
  right: 130px;
  background: linear-gradient(135deg, #150050 0%, #BE05FA 100%);
}

.fancy-carousel h1 {
  margin-bottom: 0px;
}

.fancy-carousel img {
  width: 60%;
  height: 30%;
  margin: 0%;
  border-radius: 10px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  padding: 10px;
  font-size: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  border: none;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  z-index: 5;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  animation: buttonPulse 2s infinite;
}

.fancy-carousel__item .carousel-subscribe-btn {
  opacity: 0;
}

.fancy-carousel__item--active .carousel-subscribe-btn {
  opacity: 1;
}

@keyframes buttonPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 92, 246, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(139, 92, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(139, 92, 246, 0);
  }
}

.carousel-button:hover {
  background: rgba(139, 92, 246, 0.2);
  border-color: rgba(139, 92, 246, 0.3);
  color: white;
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 4px 15px rgba(139, 92, 246, 0.4);
  animation: none;  /* Stop pulse animation on hover */
}

.carousel-button:active {
  transform: translateY(-50%) scale(0.95);
  transition: all 0.2s ease;
}

.carousel-button.prev {
  left: 20px;
  animation-delay: 0.3s;  /* Stagger the pulse animation */
}

.carousel-button.next {
  right: 20px;
}

/* Glowing border effect on hover */
.carousel-button:hover::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(139, 92, 246, 0.2), transparent);
  z-index: -1;
  animation: glowPulse 1.5s ease-in-out infinite;
}

@keyframes glowPulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

.fancy-carousal-right-panel {
  margin-top: 50px;
  display: flex;
  border-radius: 20px;
  margin: 20px;
  justify-content: center;
  align-items: center;
}

.fancy-carousel__item {
  position: absolute;
  width: 380px;
  height: 520px;
  border-radius: 32px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 24px;
  background: linear-gradient(to bottom, #3A0CA3 0%, #7209B7 100%);
  backdrop-filter: blur(10px);
  
  --transition-duration: 600ms;
  --transition-easing-function: cubic-bezier(0.83,0,0.17,1);
  transition: all var(--transition-duration) var(--transition-easing-function);
}

.fancy-carousel__item:nth-child(3n + 1),
.fancy-carousel__item:nth-child(3n + 2),
.fancy-carousel__item:nth-child(3n + 3) {
  background: linear-gradient(to bottom, #BE05FA 0%, #150050 100%);
}

.fancy-carousel__item h1 {
  color: #FFFFFF;
  font-weight: 700;
  margin-bottom: 8px;
}

.fancy-carousel__item h4 {
  color: #FFFFFF;
  font-size: 25px;
  font-weight: 700;
  margin: 16px 0;
}

.fancy-carousel__item p {
  color: #E0C3FC;
  font-size: 15px;
  margin-bottom: 24px;
}

.case-study-label {
  display: inline-block;
  padding: 6px 16px;
  background: #7fdbec;
  color: #1a3847;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
}

.carousel-subscribe-btn {
  width: 100%;
  
  padding: 16px 24px;
  border: none;
  border-radius: 2rem;
  background: linear-gradient(to right, #7700ff, #d100d170);
  background-color: transparent;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.5s ease;
}

.carousel-subscribe-btn:hover {
  box-shadow: 0 5px 15px rgba(255, 0, 110, 0.4);
  background-color: transparent;
}

.carousel-subscribe-btn svg {
  width: 20px;
  height: 20px;
}

.fancy-carousel__item--active {
  transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotate(0deg) scale(1);
  opacity: 1;
  z-index: 2;
}

.fancy-carousel__item--next {
  transform: translate3d(54%, 0, -30rem) rotateX(0deg) rotateY(-45deg) rotate(0deg) scale(1);
  opacity: 1;
  z-index: 1;
}

.fancy-carousel__item--prev {
  transform: translate3d(-54%, 0, -30rem) rotateX(0deg) rotateY(45deg) rotate(0deg) scale(1);
  opacity: 1;
  z-index: 1;
}

.video0 {
  box-shadow: 0px 0px 25px rgb(197, 83, 8), 0px 0px 25px white;
}

.video1 {
  box-shadow: 0px 0px 25px rgb(105, 44, 154), 0px 0px 25px white;
}

.video2 {
  box-shadow: 0px 0px 25px rgb(36, 31, 159), 0px 0px 15px white;
}

.fancy-carousel-right-panel {
  flex: 1;
  padding: 20px;
  color: white;
  max-width: 650px;
  height: 100vh;
  display: flex;
  align-items: center;
  background: transparent;
  margin-left: -100px;
}

.content-panel {
  width: 100%;
  height: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: transparent;
  padding: 24px;
  position: relative;
}

.content-panel h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%); 
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  width: 100%;
  max-width: 540px;
  margin: 0;
}

/* Update the span inside h2 to maintain its purple color */
.content-panel h2 span {
  /* color: rgb(40, 127, 233); */
  /* background: linear-gradient(to right, var(--buttons-background-color), var(--buttons-background-color)); */
  background: white;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.content-panel .description {
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  max-width: 540px;
  width: 100%;
  margin: 0;
}

.content-panel .features-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin-top: 32px;
  list-style: none;
}

.content-panel .features-list div {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
}

/* Staggered delay for each feature */
.content-panel .features-list div:nth-child(1) {
  animation-delay: 0.1s;
}

.content-panel .features-list div:nth-child(2) {
  animation-delay: 0.2s;
}

.content-panel .features-list div:nth-child(3) {
  animation-delay: 0.3s;
}

.content-panel .features-list div:nth-child(4) {
  animation-delay: 0.4s;
}

.content-panel .features-list div:nth-child(5) {
  animation-delay: 0.5s;
}

.content-panel .features-list .icon {
  width: 32px;
  height: 32px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 20px;
}

.content-panel .features-list .text-content {
  display: flex;
  flex-direction: column;
  gap: 4px;  /* Reduced gap between title and description in features */
}

.content-panel .features-list .text-content strong {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin: 0;  /* Remove any default margins */
}

.content-panel .features-list .text-content p {
  font-size: 14px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;  /* Remove any default margins */
}

/* .content-panel .call-to-action {
  font-size: 14px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.95);
  text-align: center;
  margin-top: auto;
  padding-top: 16px;
} */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* For larger screens */
@media (max-width: 1500px) and (min-height: 616px) {
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 1rem;
  }

  .fancy-carousel {
    right: 0;
    width: 65%;
    margin-top: 5rem;
    margin-bottom: 1rem;
    perspective: 62.5rem;
    position: relative;
  }

  /* .carousel-button {
    visibility: hidden;
  } */

  .fancy-carousel__item {
    width: 24rem;
    height: 32rem;
    border-radius: 2rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 2;
  }

  .fancy-carousel__item .carousel-subscribe-btn {
    opacity: 0;
  }

  .fancy-carousel__item--active .carousel-subscribe-btn {
    opacity: 1;
  }
  .fancy-carousel__item--next {
    transform: translate3d(54%, 0, -30rem) rotateX(0deg) rotateY(-45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-54%, 0, -30rem) rotateX(0deg) rotateY(45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel-right-panel {
    margin-left: 0;
    padding: 0.5rem;
    height: 100%;
    width: 35%;
    justify-content: center;
  }

  .content-panel {
    gap: 8px;
    padding: 12px;
  }

  .content-panel h2 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .content-panel .description {
    font-size: 14px;
  }

  .content-panel .features-list div {
    gap: 8px;
  }

  .content-panel .features-list .icon {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
}


/* For large screens */
@media (max-width: 1380px) and (min-height: 1024px) { /* 1380px and 616px */
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 1rem;
  }

  .fancy-carousel {
    right: 0;
    width: 65%;
    margin-top: 5rem;
    margin-bottom: 1rem;
    perspective: 62.5rem;
    position: relative;
  }

  .fancy-carousel__item {
    width: 25rem;
    height: 35.5rem;
    padding: 1.5rem;
    border-radius: 2rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 2;
  }

  .fancy-carousel__item--next {
    transform: translate3d(54%, 0, -30rem) rotateX(0deg) rotateY(-45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-54%, 0, -30rem) rotateX(0deg) rotateY(45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel-right-panel {
    margin-left: 0;
    padding: 0.5rem;
    height: 100%;
    width: 35%;
    justify-content: center;
  }

  .content-panel {
    gap: 0.5rem;
    padding: 0.75rem;
  }
}

/* For medium-large screens */
@media (max-width: 1210px) and (min-height: 616px) {
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 1rem;
  }

  .fancy-carousel {
    right: 0;
    width: 65%;
    margin-top: 5rem;
    margin-bottom: 1rem;
    perspective: 62.5rem;
    position: relative;
  }

  .fancy-carousel__item {
    width: 23.75rem;
    height: 32.5rem;
    padding: 1.5rem;
    border-radius: 2rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 2;
  }

  .fancy-carousel__item--next {
    transform: translate3d(54%, 0, -30rem) rotateX(0deg) rotateY(-45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-54%, 0, -30rem) rotateX(0deg) rotateY(45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel-right-panel {
    margin-left: 0;
    padding: 0.5rem;
    height: 100%;
    width: 35%;
    justify-content: center;
  }

  .content-panel {
    gap: 8px;
    padding: 12px;
  }
}

/* For iPad Portrait and similar devices */
@media (max-width: 1024px) and (min-height: 616px) {
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 1rem;
  }

  .content-panel {
    gap: 8px;
    padding: 12px;
  }

  .fancy-carousel {
    right: 0;
    width: 65%;
    margin-top: 1rem;
    perspective: 62.5rem;
  }
  .carousel-button {
    visibility: hidden;
  }

  .fancy-carousel__item {
    width: 23.75rem;  
    height: 35.5rem; 
    padding: 1.5rem;
    border-radius: 2rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 2;
  }

  .fancy-carousel__item--next {
    transform: translate3d(54%, 0, -30rem) rotateX(0deg) rotateY(-45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-54%, 0, -30rem) rotateX(0deg) rotateY(45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .carousel-dots {
    position: absolute;
    bottom: -23rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.75rem;
    z-index: 10;
    padding: 0.625rem;
  }

  .carousel-dot {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: #8B5CF6;
    box-shadow: 0 0 0.5rem rgba(139, 92, 246, 0.6);
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .carousel-dot.active {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(255, 255, 255, 0.8);
    transform: scale(1.3);
  }

  .fancy-carousel-right-panel {
    margin-left: 0;
    padding: 0.5rem;
    height: 100%;
    width: 35%;
    justify-content: center;
  }

  .fancy-carousel__item h4 {
    font-size: 20px;
    margin: 12px 0;
  }

  .fancy-carousel__item p {
    font-size: 13px;
    margin-bottom: 16px;
  }

  .content-panel h2 {
    font-size: 24px;
    top: 80px;
    align-items: center;
  }

  .content-panel .description {
    font-size: 14px;
    top: 140px;
  }

  /* .content-panel .features-list {
    margin-top: 200px;
  } */
}

/* For smaller tablets and large mobile screens */
@media (max-width: 900px) and (min-height: 616px) {
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 1rem;
  }

  .fancy-carousel {
    right: 0;
    width: 65%;
    margin-top: 1rem;
    perspective: 62.5rem;
  }

  .carousel-button {
    visibility: hidden;
  }

  .fancy-carousel__item {
    width: 22rem;  
    height: 35rem; 
    padding: 1.5rem;
    border-radius: 2rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 2;
  }

  .fancy-carousel__item--next {
    transform: translate3d(54%, 0, -30rem) rotateX(0deg) rotateY(-45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-54%, 0, -30rem) rotateX(0deg) rotateY(45deg) rotate(0deg) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .carousel-dots {
    position: absolute;
    bottom: -23rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.75rem;
    z-index: 10;
    padding: 0.625rem;
  }

  .carousel-dot {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: #8B5CF6;
    box-shadow: 0 0 0.5rem rgba(139, 92, 246, 0.6);
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .carousel-dot.active {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(255, 255, 255, 0.8);
    transform: scale(1.3);
  }

  .fancy-carousel-right-panel {
    margin-left: 0;
    padding: 0.5rem;
    height: 100%;
    width: 35%;
    justify-content: center;
  }

  .fancy-carousel__item h4 {
    font-size: 1.5rem;
    margin: 0.75rem 0;
  }

  .fancy-carousel__item p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

/* For landscape mode and shorter screens */
@media (max-width: 620px) and (min-height: 300px) {
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 1rem;
  }

  .fancy-carousel {
    right: 0;
    width: 60%;
    margin-top: 1rem;
    perspective: 600px;
  }

  .fancy-carousel__item {
    width: 22rem;  
    height: 38rem; 
    padding: 1.5rem;
    border-radius: 2rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateY(0deg) scale(1);
  }

  .fancy-carousel__item--next {
    transform: translate3d(30%, 0, -15rem) rotateY(-30deg) scale(0.9);
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-30%, 0, -15rem) rotateY(30deg) scale(0.9);
  }

  .fancy-carousel-right-panel {
    margin-left: 0;
    padding: 0.5rem;
    height: 100%;
    width: 40%;
    justify-content: center;
  }

  .content-panel {
    gap: 8px;
    padding: 12px;
  }

  .content-panel h2 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .content-panel .description {
    font-size: 14px;
    display: none;
  }

  .content-panel .features-list div {
    gap: 8px;
  }

  .content-panel .features-list .icon {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }

  .content-panel .features-list .text-content strong {
    font-size: 14px;
  }

  .content-panel .features-list .text-content p {
    font-size: 12px;
  }

  .fancy-carousel__item h4 {
    font-size: 18px;
    margin: 8px 0;
  }

  .fancy-carousel__item p {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .carousel-dots {
    position: absolute;
    bottom: -25rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.75rem;
    z-index: 10;
    padding: 0.625rem;
  }

  .carousel-dot {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: #8B5CF6;
    box-shadow: 0 0 0.5rem rgba(139, 92, 246, 0.6);
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .carousel-dot.active {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(255, 255, 255, 0.8);
    transform: scale(1.3);
  }
}

/* For mobile screens */
@media (max-width: 550px){
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 0;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .fancy-carousel {
    right: unset; /* Remove right positioning */
    width: 100%;
    margin: 0 auto; /* Center the carousel */
    perspective: 37.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel-button {
    visibility: hidden;
  }

  .fancy-carousel__item {
    width: 25rem;  
    height: 35rem; 
    padding: 1rem;
    border-radius: 2.25rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateY(0deg) scale(1);
  }

  .fancy-carousel__item--next {
    transform: translate3d(30%, 0, -5rem) rotateY(-30deg) scale(0.9);
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-30%, 0, -5rem) rotateY(30deg) scale(0.9);
  }

  .fancy-carousel-right-panel,
  .content-panel {
    display: none;
  }

  .fancy-carousel__item {
    width: 28rem;  
    height: 40rem; 
  }
}

/* For smaller mobile screens */
@media (max-width: 380px){
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 0.75rem;
    gap: 0.75rem;
  }

  .fancy-carousel {
    right: 0;
    width: 100%;  /* Full width */
    margin-top: 0.75rem;
    perspective: 31.25rem;
  }

  .carousel-button {
    visibility: hidden;
  }

  .fancy-carousel__item {
    width: 20rem;  
    height: 32rem; 
    padding: 0.75rem;
    border-radius: 1rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateY(0deg) scale(1);
  }

  .fancy-carousel__item--next {
    transform: translate3d(25%, 0, -0.5rem) rotateY(-25deg) scale(0.85);
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-25%, 0, -0.5rem) rotateY(25deg) scale(0.85);
  }

  .fancy-carousel-right-panel {
    display: none;  /* Hide right panel */
  }

  .content-panel {
    gap: 0.375rem;
    padding: 0.625rem;
  }

  .content-panel h2 {
    font-size: 1.25rem;
    margin-bottom: 0.375rem;
  }

  .content-panel .description {
    font-size: 0.75rem;
    display: none;
  }

  .content-panel .features-list div {
    gap: 0.375rem;
  }

  .content-panel .features-list .icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.875rem;
  }

  .content-panel .features-list .text-content strong {
    font-size: 1rem;
  }

  .content-panel .features-list .text-content p {
    font-size: 1rem;
  }

  .fancy-carousel__item h4 {
    font-size: 1.3rem;
    margin: 0.375rem 0;
  }

  .fancy-carousel__item p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .fancy-carousel__item--active .carousel-subscribe-btn {
    font-size: 1rem;
    height: 10%;
  }
}

/* For landscape mode and shorter screens */
@media (max-height: 510px) {
  .resume-guru {
    flex-direction: row;
    height: 100vh;
    padding: 1rem;
    gap: 1rem;
    overflow-y: scroll;
  }

  .fancy-carousel {
    right: 0;
    width: 60%;
    perspective: 37.5rem;
  }

  .carousel-subscribe-btn {
    width: 100%;
    height: 15%;
  }


  .carousel-button {
    visibility: hidden;
  }
  .fancy-carousel-right-panel,
  .content-panel {
    display: none;
  }

  .fancy-carousel__item {
    width: 19.5rem;  
    height: 27rem; 
    padding: 1.5rem;
    border-radius: 2rem;
  }

  .fancy-carousel__item--active {
    transform: translate3d(0, 0, 0) rotateY(0deg) scale(1);
    opacity: 1;
    z-index: 2;
  }

  .fancy-carousel__item--next {
    transform: translate3d(30%, 0, -5rem) rotateY(-30deg) scale(0.9);
    opacity: 1;
    z-index: 1;
  }

  .fancy-carousel__item--prev {
    transform: translate3d(-30%, 0, -5rem) rotateY(30deg) scale(0.9);
    opacity: 1;
    z-index: 1;
  }

  .carousel-dots {
    position: absolute;
    bottom: -16rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.75rem;
    z-index: 10;
    padding: 0.625rem;
  }

  .carousel-dot {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: #8B5CF6;
    box-shadow: 0 0 0.5rem rgba(139, 92, 246, 0.6);
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .carousel-dot.active {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(255, 255, 255, 0.8);
    transform: scale(1.3);
  }

  .fancy-carousel-right-panel {
    margin-left: 0;
    padding: 0.5rem;
    height: 100%;
    width: 40%;
    justify-content: center;
  }

  .content-panel {
    gap: 0.5rem;
    padding: 0.75rem;
  }

  .content-panel h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .content-panel .description {
    font-size: 0.875rem;
    display: none;
  }

  .content-panel .features-list div {
    gap: 0.5rem;
  }

  .content-panel .features-list .icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
  }

  .content-panel .features-list .text-content strong {
    font-size: 0.875rem;
  }

  .content-panel .features-list .text-content p {
    font-size: 0.75rem;
  }

  .fancy-carousel__item h4 {
    font-size: 1.125rem;
    margin: 0.5rem 0;
  }

  .fancy-carousel__item p {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

