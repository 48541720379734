.notification {
    position: fixed;
    top: 130px;
    background-color:#bd05fa24 ; 
    border: 1px solid red;
    color: white;
    padding: 5px;
    border-radius: 5px;
    max-width: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation: slideInFromRight 0.3s ease-out forwards, fadeOut 0.5s 4.5s forwards; /* Slide in and fade out */
    z-index: 1000;
}

.notification.show {
    right: 20px; 
}

.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

.notification-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    background-color: red;
    width: 100%;
    animation: progress 5s linear forwards; /* Animate over 5 seconds */
}

@keyframes progress {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100vw); /* Start off-screen to the right */
        opacity: 0;
    }
    to {
        transform: translateX(0); /* End at the original position */
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}