/************ old changes ***********/
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/**********************************/


/************ New changes ***********/

/* Base Styles for the Entire Application */
/* Reset margin, padding, and box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set a default font size on the root element */
html {
  font-size: 1rem; /* 1rem = 16px */
}

/* Apply global font and background styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

@media (max-width: 1400px) {
  html{
    font-size: 14px;
  }
}

@media (max-width:1100px) {
  html{
    font-size: 13px;
  }
}
@media (max-width:900px) {
  html{
    font-size: 11px;
  }
}

/* Add responsive scaling for smaller devices */
@media (max-width: 768px) {
  html {
    font-size: 11px; /* Scale down fonts for smaller screens */
  }
}

@media (max-width: 480px) {
  html {
    font-size: 10px; /* Further scale down for very small devices */
  }
}

/**********************************/
