.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

.resume-modal-content {
    display: flex;
    flex-direction: column;
    background: #0e1428;
    width: 100%;
    height: 100vh;
    position: relative;
}

.steps-header{
    width: 100%;
    background: #0e1428;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    display: flex;
    align-items: center;
   
}
.stepper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    position: relative;
    width: 95%;
    left: 45px;
    border-radius: 10px;
    border: transparent;
    /* background: #161e36; */

}

.back-button{
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    margin-left: 30px;
    border: 2px solid rgba(190, 5, 250, 0.2);
    color: #BE05FA;
    border-color: #BE05FA;
}

.back-button i{
    font-size: 20px;
}

.back-button:hover {
    color: #BE05FA;
    border-color: #BE05FA;
    background: rgba(190, 5, 250, 0.08);
}


.resume-close-button {
    position: absolute;
    right: 0px;
    top: -5px;
    background: none;
    border: none;
    color:white;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.3s ease;
  }
  
  .resume-close-button:hover {
    color: #BE05FA;
    transform: rotate(90deg);
  }

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease;
    flex: 1;
    gap: 12px;
}

/* Add connector line between steps */
.step:not(:last-child)::after {
    content: '';
    position: absolute;
    left: calc(50% + 22px); /* Half of icon width */
    right: calc(-50% + 22px); /* Half of icon width */
    top: 22px; /* Center of the icon */
    height: 2px;
    background: rgba(190, 5, 250, 0.2);
    z-index: 1;
}

/* Active step connector styling */
.step.active::after {
    background: linear-gradient(to right, #BE05FA 50%, rgba(190, 5, 250, 0.2) 50%);
}

/* Completed step connector styling */
.step.completed::after {
    background: #BE05FA;
}

.circle {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #0E1428;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(190, 5, 250, 0.2);
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
}

.step.active .circle {
    background: #BE05FA;
    color: white;
    border-color: #BE05FA;
}

.step.completed .circle {
    background: rgba(190, 5, 250, 0.2);
    border-color: #BE05FA;
    color: #BE05FA;
}

.step.completed::after {
    background: #BE05FA;
}

/* Remove the separate step-line divs as we're using pseudo-elements */
.step-line {
    display: none;
}

.content-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.resume-form{
    flex: 1;
    padding: 20px 40px;
}

.main-div{
    height: 92%;
    /* overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: var(--buttons-background-color) tranparent; */
}
.resume-name{
    display: flex;
    justify-content: flex-start;
    padding: 15px 20px;
    font-size: 1.5em;
    color: white;
    align-items: center;
}
.resume-name i {
    margin-left: 10px; /* Adds space between the text and the icon */
    font-size: 18px; /* Adjusts icon size if necessary */
    color: white; /* Adjust icon color if needed */
    cursor: pointer;
}
.edit-input {
    font-size: 20px;
    padding: 5px;
    color: white;
    background: transparent;
    width: auto;
}
.edit-input:focus{
    border: 1px solid var(--buttons-background-color) !important;
}
.sub-div{
    width: 100%;
    height: 75%;
   padding: 15px 20px;
    margin: 0px 20px;
    flex-grow: 0;
    overflow-y: auto; 
    scrollbar-width: thin;
    scrollbar-color: var(--buttons-background-color) transparent;
    h4{
        margin:0px;
        color:#ffffff!important;
    }
} 

.row {
    display: flex;
}

.col {
    flex: 1;
    min-width: 200px;
}

.input-div{
    padding:10px 10px;
    width: 100%;
    margin-bottom: 5px !important;
    .label{
        font-weight: 600;
        color: white;
        margin-bottom: 8px !important;
        font-size: 18px;
        display: flex;
        gap: 5px;
    }
    .start-end-date{
        width:100%;
        gap:10px;
        display: flex;
        flex-direction: row;
    }

    .ss {
        width:100%;
        color: white;
        background: rgba(255, 255, 255, 0.03);
        border: 2px solid rgba(190, 5, 250, 0.2);
        border-radius:8px!important;
        padding: 23px 21px
    }
    .ss:focus{
        outline: none;
    }

    .resume-skills{
        width:100%;
        padding:12px 10px;
        min-height: 50px;
        border-radius: 6px;
        /* background: #bd05fa39; 
        border: 2px solid #ffffff1a
        !important; */
        background: rgba(255, 255, 255, 0.03);
        border: 2px solid rgba(190, 5, 250, 0.2);
        box-sizing: border-box;
        color: white;
    }
    .resume-input::placeholder {
        font-size: 1rem;
        color: #676565;
        font-style: italic;
    }
}


.error-border {
    border: 2px solid red !important;
}

.resume-error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
    margin-left: 5px;
}

.error-text {
    color: red !important;
}



.resume-input:focus,
.resume-skills:focus{
    outline: none;
    border-color: #BE05FA;
    background: rgba(190, 5, 250, 0.08);
    box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
}

.resume-input:active {
    background: #bd05fa39;
}

.resume-input:-webkit-autofill,
.resume-input:-webkit-autofill:hover,
.resume-input:-webkit-autofill:focus,
.resume-input:-webkit-autofill:active {
    -webkit-box-shadow:none !important;
    -webkit-text-fill-color: white !important;
}

.label {
    color: white;
    margin-bottom: 8px !important;
    font-size: 18px;
}

.resume-input {
    width: 100%;
    padding: 15px 20px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(190, 5, 250, 0.2);
    color: white;
    font-size: 14px;
}

.resume-input:focus {
    outline: none;
    border-color: #BE05FA;
    background: rgba(190, 5, 250, 0.08);
    box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
}

.experience-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.delete-button{
    border:none;
    background-color: transparent;
    color:rgb(167, 29, 29);
    cursor: pointer;
    font-size: 19px
}
.delete-button:hover{
    transform: scale(1.2);
}

.experience-container{
    display: flex;
    flex-direction: column;
    padding: 10px 10px; 
    color:white;
    border-radius: 5px;
    margin-bottom: 20px;
    background: #161e36;
}
.experience{    
    background-color: var(--screen-background-color);
    color:white;
    padding:15px 20px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-size: 19px;
    border: 1px solid var(--buttons-background-color);
}

.experience:disabled {
    /* background-color: var(--buttons-background-color); */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .experience-note{
    color: var(--text-primary);
  }

/* .expirence-description{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    right:100px
} */

.project-ai-generate-button:disabled{
    cursor: not-allowed;
}
.project-ai-generate-button i{
    color: #BE05FA;
}
.project-ai-generate-button {
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color) !important;
    padding: 5px 5px;
    color:white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3 ease;
    display: flex;
    position: absolute;
    right: 2px; /* Position it at the right end of the container */
    top: -32px;
    font-size: 11px;
    gap: 5px;
}
.project-ai-generate-button:hover {
    transform: scale(1.05);
}

.textarea-container {
  position: relative;
  width: 100%; /* Ensures the container is 204% wide */
}

.text-area {
    display: list-item;
    min-width :100%;
    min-height:240px;
    padding: 16px 20px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(190, 5, 250, 0.2);
    color: white;
    scrollbar-width: thin;
    scrollbar-color: var(--buttons-background-color) transparent;
}

.resumedes-loading-overlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 95%;
    /* background: rgba(0, 0, 0, 0.5); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Ensure overlay appears above the textarea */
    pointer-events: none; /* Prevent interaction with the overlay */
  }

  .text-area:focus{
    outline: none;
    border-color: #BE05FA;
    background: rgba(190, 5, 250, 0.08);
    box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
}

.text-area div {
    display: list-item;
}

.resume-skills-container{
    width:100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--buttons-background-color) !important;
    border: 2px solid rgba(190, 5, 250, 0.2);
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    padding: 10px;
}
.resume-skill-button{
    background-color: #BE05FA1A;
    padding: 10px 10px;
    color: white !important;
    margin: 5px;
    gap: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.skill-text {
    margin-right: 4px; /* Additional spacing if needed */
    
}

.remove-skill {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}
.navigation-buttons {
    display: flex;
    justify-content: flex-end; /* Adjust alignment of buttons */
    gap:10px;
    padding: 25px 20px; /* Add padding for spacing */
    /* background: #161e36; */
    width: 100%;
    border-radius: 10px;
    right: 10px;
    margin-top: 10px;
    border-top:1px solid rgba(255, 255, 255, 0.1)
}

.navigation-buttons button {
    padding: 10px 20px;
    font-size: 14px;
    color: white;
    background-color: var(--buttons-background-color); /* Match your preferred theme */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.navigation-buttons button:hover {
    transform: scale(1.05);
}
.template{
    width: 40%;
    padding: 20px;
    overflow: hidden;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    text-align: justify;
}

/* .download-resume-icon{
    margin-top: 10px; 
    margin-left: 28px;
}

.download-btn {
    padding: 12px 20px;
    background: rgba(190, 5, 250, 0.2);
    border: 1px solid rgba(190, 5, 250, 0.3);
    color: white;
    font-size: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 10px 0;
}

.download-btn i {
    font-size: 16px;
    transition: all 0.3s ease;
}

.download-btn:hover {
    background: rgba(190, 5, 250, 0.3);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(190, 5, 250, 0.2);
}

.download-btn:hover i {
    transform: translateY(-1px);
}

.download-btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(190, 5, 250, 0.2);
} */

.download-resume-icon{
    margin-top: 10px; 
    margin-left: 28px;
}

.download-btn{
    padding: 10px 10px;
    background: var(--buttons-background-color);
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.download-btn:hover{
    transform: scale(1.05);
}

.resume-template {
    font-size: 9px;
    margin:10px 20px;
    min-height: 300px;
    color:black;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--buttons-background-color) !important;
    height: calc(90vh - 180px);
    word-wrap: break-word;
    text-align: justify;
    h4{
        margin:0px;
        color:#2b1d4d;
    }
}

.new-preview-name {
    font-size: 1.75rem;
    color: #2d3748;
    margin-bottom: 1rem;
}
.new-preview-section {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #edf2f7;
}

.new-preview-section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.new-personal-details {
    text-align: center;
    margin-bottom: 1.25rem;
}

.new-preview-section-title {
    color: #2d3748 !important;
    font-size: 0.75rem !important;
    margin-bottom: 0.5rem !important;
    text-transform: uppercase !important;
    letter-spacing: 0.05em !important;
}

.new-preview-experience-item, .new-preview-education-item {
    margin-bottom: 0.75rem;
}

.new-preview-experience-header, .new-preview-education-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.new-preview-experience-title h4, .new-preview-education-title h4 {
    color: #2d3748;
    margin: 0 0 0.5rem 0;
}

.new-preview-company, .new-preview-degree {
    font-style: italic;
}

.new-preview-experience-period, .new-preview-education-period {
    text-align: right;
}

.new-preview-location, .new-preview-gpa {
    display: block;
    margin-top: 0.25rem;
}

.new-preview-responsibilities {
    list-style: none; /* Remove default bullets */
    padding-left: 0; /* Remove padding */
    margin-bottom: 0.5rem;
}

.new-preview-responsibilities li {
    position: relative; /* Positioning for pseudo-element */
    padding-left: 15px; /* Space for the bullet */
}

.new-preview-responsibilities li::before {
    content: '•'; /* Custom bullet */
    position: absolute; /* Position it absolutely */
    left: 1px; /* Align to the left */
    font-size: 11px; /* Adjust the size of the bullet */
    color: rgb(0, 0, 0); /* Bullet color */
}

.new-preview-skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
}

.new-preview-skill-tag {
    background: #ab1fbd20;
    color: #bd05fa;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
}

.new-preview-education-details {
    color: #4a5568;
    margin-top: 0.5rem;
}


.resume-template-hr{
    width: 100%;
}
.person-details-template{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        color:#2b1d4d;
        margin:0px;
        padding:0px;
    }
    p{
        margin:0px;
        padding:0px;
    }
}

.experience-details-info,.experience-details-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0px;
    padding:0px;
    p{
        margin:0px;
    }
    i{
        color:black;
        font-size:10px;
    }
}
.experience-details-description{
    p{
        margin:1px 0px;
    }
}

.skill-item {
    flex: 1 1 calc(20% - 10px); /* 5 items per row, with some spacing */
    margin: 0;
    list-style-type: none; /* Prevent default list-style */
    position: relative; /* Required for ::before */
    padding-left: 5px; /* Space for the bullet */
}

.skill-item::before {
    content: "•"; /* Bullet symbol */
    position: absolute;
    left: 0;
    color: black; /* Adjust color if needed */
}
p{
    margin:4px;
}

.currently-working {
    display: flex;
    align-items: center;
    float: right;
    gap: 10px;
    margin-top: 7px;
}

.currently-working input[type="checkbox"] {
    margin: 0;
    flex:none;
    background:var(--buttons-background-color) !important;
    
}


.currently-working label {
    margin: 0; /* Ensure no margin on the label */
}


/* Hide default checkbox */
.currently-working input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid purple;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    display: inline-block;
    position: relative;
}

Custom checkmark when checked
.currently-working input[type="checkbox"]:checked {
    background-color: purple;
    border-color: purple;
}

.currently-working input[type="checkbox"]::before {
    content: '✔'; 
    color: white;
    display: none;
    position: absolute;
    left: 2px;
    top: -2px;
}

/* Show checkmark when checked */
.currently-working input[type="checkbox"]:checked::before {
    display: block;
}
