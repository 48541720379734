.screen1-scrolling-banner {
    width: 100%;
    height: 6rem;
    background-color: var(--screen-background-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    gap:0.5rem;
    /* border: 1px solid gold; */
    padding: 0.2rem;
  }
  
  .screen1-scrolling-banner::before,
  .screen1-scrolling-banner::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10rem;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }
  
  .screen1-scrolling-banner::before {
    left: 0;
    background: linear-gradient(to right, var(--screen-background-color), transparent);
  }
  
  .screen1-scrolling-banner::after {
    right: 0;
    background: linear-gradient(to left, var(--screen-background-color), transparent);
  }
  
  .screen1-scroll-container{
    width: 100%;
    height: 100%;
    font-size: 1rem;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    /* border: 1px solid gold; */
  }

.screen1-scroll-holder{
  width: 100%;
  height: 100%;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  /* border: 1px solid gold; */
}

.screen1-profession-scroll-left{
  object-fit: cover;
  height: 100%;
}
.profession-logo{
  height: 100%;
  margin: 0 0.5rem;
}

  
  .scroll-row {
    display: inline-flex;
    white-space: nowrap;
    will-change: transform;
    position: absolute;
  }
  
  .scroll-item {
    padding: 0rem 0.5rem;
    color: var(--text-primary);
    font-size: 1.5rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    margin-right: 1rem;
    border: 1px solid var(--HOVER-BG);
    border-radius: 0.5rem;
    background-color: var(--HOVER-BG);
    /* border: 1px solid gold; */
  }
  
  .screen1-scroll-left .scroll-item {
    color: var(--text-primary);
  }
  
  .screen1-scroll-right .scroll-item {
    color: var(--text-primary);
  }
  
  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    /* border: 1px solid gold; */
    height: 2rem;
  }
  
  .screen1-scroll-left .icon {
    color: var(--buttons-background-color);
  }
  
  .screen1-scroll-right .icon {
    color: var(--buttons-background-color);
  }
  
  .screen1-scroll-left, .screen1-profession-scroll-left {
    animation: scrollLeft 60s linear infinite;
  }
  
  .screen1-scroll-right {
    animation: scrollRight 50s linear infinite;
  }
  
  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-90%);
    }
  }
  
  @keyframes scrollRight {
    0% {
      transform: translateX(-85%);
    }
    100% {
      transform: translateX(0);
    }
  }