.FAQ-content {
    width: 70%;
    height: 80%;
    margin: 0 auto;
    font-family: 'Poppins';
    padding: 0.5rem;
    overflow-y: auto;
}

.FAQ-Item {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.FAQ-Question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1.1rem;
    color: var(--text-primary);
    transition: background 0.3s ease;
}

.FAQ-Question:hover {
    background: rgba(255, 255, 255, 0.2);
}

.FAQ-Icon {
    transition: transform 0.3s ease;
}

.FAQ-Item.open .FAQ-Icon {
    transform: rotate(180deg);
}

/* Smooth Height Animation */
.FAQ-Answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    padding: 0 1rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.2;
    background-color: rgba(255, 255, 255, 0.05);
}

.FAQ-Item.open .FAQ-Answer {
    max-height: 500px; 
    padding: 0.5rem;
}
