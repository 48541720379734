.session-card {
    background: #0A0B14;
    border-radius: 20px;
    padding: 16px 16px 16px 16px;
    width: 220px;
    height: 340px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid rgba(190, 5, 250, 0.3);
    box-shadow: 0 0 20px rgba(190, 5, 250, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.new-session-card{
    background: #0A0B14;
    border-radius: 20px;
    padding: 16px 16px 16px 16px;
    width: 220px;
    height: 340px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    border: 1px solid rgba(190, 5, 250, 0.3);
    box-shadow: 0 0 20px rgba(190, 5, 250, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.invalid-card{
    min-width: fit-content;
    width: 240px;
    background: linear-gradient(to bottom right, var(--buttons-background-color), var(--screen-background-color));
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: fit-content;
}

.prep-session-card-logo{
    width: 120px;
    height: 120px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(190, 5, 250, 0.5);
    animation: shine 1s infinite ease-in-out alternate;
}
.sub-container{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid var(--buttons-background-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.prep-session-card-features{
    display: flex;
    flex-direction: column;
}
.prep-session-card-feature{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap:0.5rem;
}
.prep-session-card-icon{
    width: 1rem;
    height: 1rem;
}

@keyframes shine {
    0%{
        background: rgba(161, 4, 214, 0.3);
    }
    100%{
        background: rgba(164, 3, 218, 0.9);   
    }
}


 
/* Add the top gradient line */
.session-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(190, 5, 250, 0.5), transparent);
}
 
 
.session-card:hover {
    transform: translateY(-5px) scale(1.02);
    border: 1px solid rgba(190, 5, 250, 0.8);
    box-shadow:
        0 0 20px rgba(190, 5, 250, 0.2),
        0 0 40px rgba(190, 5, 250, 0.1),
        inset 0 0 15px rgba(190, 5, 250, 0.1);
}


.tooltip {
    visibility: hidden;
    background-color: #bd05fac0;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    font-size: 12px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top:20%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 100%; /* Move triangle below the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #bd05fabe transparent transparent transparent; /* Triangle pointing up */
}


.session-card-header:hover ~ .tooltip,
.donut-wrapper:hover ~ .tooltip {
    visibility: visible;
    opacity: 1;
}
 
 
 
.session-card-header {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: none;
}
 
.role-icon {
    background: rgba(190, 5, 250, 0.1);
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BE05FA;
}
 
.role-info {
    color: white;
}
 
.role-info h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}
 
.role-info span {
    font-size: 12px;
    color: #BE05FA;
}
 
 
.action-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
    color: White;
    background: transparent;
    border: none;
    padding: 2px;
    cursor: pointer;
    transition: color 0.2s ease;
    white-space: nowrap;
    width: 100%;
}
.action-link i{
    color:#BE05FA ;
}
 
.link-icon {
    font-size: 10px;
    margin-left: 2px;
    color: #BE05FA;
}
 
.action-link:hover {
    color: #BE05FA;
}
 
.action-link:hover .link-icon {
    transform: translateX(2px);
    transition: transform 0.2s ease;
}
 
.redo-button {
    width: calc(100% - 32px);
    margin: 2px 16px 12px 16px;
    padding: 8px;
    background: #BE05FA;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    bottom: 6px;
}


.redo-button:disabled{
    cursor: not-allowed !important;
    background-color: grey;
}

.create-session-button{
    width: calc(100% - 32px);
    margin: 5px 15px;
    padding: 8px;
    background: #BE05FA;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0.5rem;
}

/* .create-resume-button{
    width: calc(100% - 32px);
    margin: 2px 16px 12px 16px;
    padding: 8px;
    background: #BE05FA;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    top: 12px;
} */

.show-tour-btn{
    position: absolute;
    top: 0;
    left: 50px;
    border: 1px solid var(--buttons-background-color);
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.1rem 0.3rem;
    transform: translateY(-100%);
    border-radius: 10px;
    color: var(--text-primary);
    cursor: pointer;
    background-color: rgba(190, 5, 250, 0.5);
}
.show-tour-btn:active{
    transform: translateY(-100%) scale(0.95);
}
 
.card-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(220px, 1fr));
    gap: 24px;
    /* max-width: 1200px; */
    /* margin: 0 auto; */
    padding: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 220px);
    margin-left: 25px;
}
 
 
  /* Scrollbar */
  .card-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .card-container::-webkit-scrollbar-track {
    background: rgba(190, 5, 250, 0.5) rgba(10, 11, 20, 0.6);
    border-radius: 4px;
  }
  
  .card-container::-webkit-scrollbar-thumb {
    background: #BE05FA;
    border-radius: 4px;
  }



 
 
 
.new-session-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
}
 
.new-session-circle {
    background: rgba(190, 5, 250, 0.1) !important;
    cursor: pointer;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
}
 
 
.new-session-circle .fas.fa-plus-circle {
    font-size: 45px;
    color: #BE05FA;
    transition: transform 0.3s ease;
}
 
.new-session-circle:hover .fas.fa-plus-circle {
    transform: scale(1.1);
}
 
 
 
.donut-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
}
 
.donut-chart {
    position: relative;
    width: 120px;
    height: 120px;
 
}
 
 
 
/* Add animation for score changes */
@keyframes scoreChange {
    0% {
        stroke-dashoffset: 339.292; /* Full circle */
    }
}
 
.donut-chart circle:first-child {
    opacity: 0.2;
}
 
 
 
/* Highlight word effect */
.highlight {
    background: linear-gradient(90deg, #BE05FA, #4A0082);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
 
 
.hero-description {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.5;
    width: 1220px;
    padding-right: 270px;
}
.hero-description .highlight {
    color: #BE05FA;
    font-weight: 500;
    transition: color 0.3s ease;
}
 
 
.improvement-indicator {
    display: inline-flex;
    align-items: center;
    padding: 4px 10px;
    border-radius: 6px;
    margin: auto auto;
    width: fit-content;
}
 
.improvement-indicator span {
    font-size: 13px;
    font-weight: 500;
    text-shadow: 0 0 10px rgba(46, 204, 113, 0.2);
}
 
/* Optional hover effect
.improvement-indicator:hover {
    background: rgba(39, 174, 96, 0.2);
    transform: translateY(-1px);
    transition: all 0.3s ease;
} */

/* R-BOTGURU/botguru/src/Components/LandingPages/PrepGuruPage.css */
.empty-indicator {
    display: inline-flex; /* Match display type */
    align-items: center; /* Center align items */
    padding:12px; /* Match padding */
    border-radius: 6px; /* Match border radius */
    margin: auto; /* Center the indicator */
    width: fit-content; /* Allow it to fit content */
    /* Optionally, you can add a background color or border if needed */
    background: transparent; /* Or any other color */
}

.iframe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.session-iframe {
    width: 100%;
    height: 100%;
    border: none;
}
.iframe-close-button {
    position: absolute;
    bottom: 20px;  /* Position it at the bottom */
    left: 20px;    /* Position it at the left */
    background-color: #be01f9;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
}
.iframe-close-button:hover {
    background-color: #be01f9;
}
.improvement-negative {
    color: red; /* Color for negative improvement */
    background-color:#990d0d45
}

.improvement-positive {
    color: green; /* Color for positive improvement */
    background: rgba(39, 174, 96, 0.15);
}

/* Tour guide styles */
.reactour__helper {
    background-color: #0e1428 !important;
    border: 2px solid #a86cda !important;
    color: #ffffff !important;
    border-radius: 10px !important;
}

.reactour__arrow {
    color: #a86cda !important;
}

.reactour__badge {
    background-color: #BE05FA !important;
    color: #ffffff !important;
}

.reactour__navigation {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 10px !important;
}

.reactour__navigation-number {
    color: #ffffff !important;
    font-size: 14px !important;
    margin: 0 15px !important;
    font-weight: 500 !important;
}

.reactour__dot {
    width: 8px !important;
    height: 8px !important;
    margin: 0 4px !important;
}

.reactour__dot--is-active {
    background-color: #BE05FA !important;
}

.reactour__button {
    color: #ffffff !important;
    background-color: #BE05FA !important;
    border: none !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    margin: 0 5px !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
}

.reactour__button:hover {
    background-color: #9555c8 !important;
    transform: translateY(-1px) !important;
}

/* Large screens - 1450px */
@media screen and (max-width: 1500px) {
    .card-container {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
        gap: 20px;
        padding: 16px;
    }

    .role-icon {
        width: 28px;
        height: 28px;
    }

    .role-info h3 {
        font-size: 14px;
    }

    .role-info span {
        font-size: 11px;
    }

    .action-link {
        font-size: 11px;
    }

    .redo-button, .create-session-button {
        font-size: 13px;
        padding: 7px;
    }
}

@media screen and (max-width: 1400px) {
    .card-container {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
        gap: 18px;
    }

    .role-icon {
        width: 26px;
        height: 26px;
    }

    .role-info h3 {
        font-size: 13px;
    }
}

/* Medium-large screens - 1245px */
@media screen and (max-width: 1245px) {
    .card-container {
        grid-template-columns: repeat(3, minmax(160px, 1fr));
        gap: 16px;
        padding: 14px;
    }

    .donut-chart {
        width: 100px;
        height: 100px;
    }

    .hero-description {
        width: 100%;
        padding-right: 20px;
        font-size: 14px;
    }
}

/* Tablet screens - 1024px */
@media screen and (max-width: 1024px) {
    .card-container {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        gap: 16px;
        padding: 12px;
    }

    .new-session-circle {
        width: 100px;
        height: 100px;
    }

    .new-session-circle .fas.fa-plus-circle {
        font-size: 35px;
    }
}

/* Medium tablet screens - 950px */
@media screen and (max-width: 950px) {
    .card-container {
        grid-template-columns: repeat(2, minmax(180px, 1fr));
        gap: 14px;
    }
}

@media screen and (max-width: 870px) {
    .card-container {
        grid-template-columns: repeat(2, minmax(160px, 1fr));
        gap: 12px;
    }
}

@media screen and (max-width: 785px) {
    .card-container {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
        gap: 10px;
    }
}

/* Small tablet screens - 715px */
@media screen and (max-width: 715px) {
    .session-card,
    .new-session-card {
        width: 180px;  /* Reduced from 220px */
        height: 280px; /* Reduced from 340px */
        padding: 12px;
        gap: 10px;
    }

    .card-container {
        grid-template-columns: repeat(2, minmax(140px, 1fr));
        margin-left: 0;
        padding: 10px;
    }

    .hero-description {
        padding-right: 0;
        text-align: center;
    }
}

/* Mobile screens - 530px */
@media screen and (max-width: 530px) {
    .session-card,
    .new-session-card {
        width: 160px;
        height: 260px;
        padding: 10px;
        gap: 8px;
    }

    .card-container {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
        gap: 12px;
        padding: 10px;
        margin: 0 auto;
    }
}

/* Even smaller screens */
@media screen and (max-width: 445px) {
    .session-card,
    .new-session-card {
        width: 140px;
        height: 240px;
        padding: 8px;
        gap: 6px;
    }

    .card-container {
        grid-template-columns: 1fr;
        gap: 10px;
        padding: 8px;
    }

    .role-icon {
        width: 24px;
        height: 24px;
    }

    .role-info h3 {
        font-size: 12px;
    }

    .role-info span {
        font-size: 10px;
    }

    .action-link {
        font-size: 10px;
    }

    .redo-button, .create-session-button {
        font-size: 12px;
        padding: 6px;
        margin: 2px 12px 8px 12px;
    }

    .donut-chart {
        width: 80px;
        height: 80px;
    }

    .new-session-circle {
        width: 70px;
        height: 70px;
    }

    .new-session-circle .fas.fa-plus-circle {
        font-size: 25px;
    }

    .reactour__helper {
        background-color: #0e1428 !important;
        border: 2px solid #a86cda !important;
        color: #ffffff !important;
        border-radius: 10px !important;
        height: 130px !important;
        width: 230px !important;
    }

    .redo-button, .create-session-button {
        font-size: 12px;
        padding: 7px;
    }
}