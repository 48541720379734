/* JD Guru specific container */
.jd-guru-page {
    width: 100%;
    min-height: 200vh;
    background-color: #0E1428;
    background-image: radial-gradient(
        circle at center,
        rgba(190, 5, 250, 0.08) 0%,
        rgba(14, 20, 40, 0) 100%
    );
}

.jd-guru-container {
    /* width: 100%;
    max-width: 1200px; */
    margin: 0 auto; 
    display: grid;
    grid-template-columns: repeat(4, minmax(220px, 1fr));
    gap: 24px; /* Consistent gap between cards */
    padding: 20px;
    height: calc(100vh - 220px);
    overflow-y: auto;
}

/* JD Guru specific card */
.jd-guru-card {
    width: 100%;
    max-width: 240px;
    background: #0A0B14 !important;
    border-radius: 20px;
    padding: 24px;
    border: 1px solid rgba(190, 5, 250, 0.15);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 340px; /* Fixed height */
    margin: 0 auto;
    transition: all 0.3s ease;
}

.jd-guru-card:hover {
    transform: translateY(-5px) scale(1.02);
    border: 1px solid rgba(190, 5, 250, 0.8);
    box-shadow: 0 0 20px rgba(190, 5, 250, 0.2), 0 0 40px rgba(190, 5, 250, 0.1), inset 0 0 15px rgba(190, 5, 250, 0.1);
}
.jd-guru-card::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(190, 5, 250, 0.5), transparent);
}

/* .upload-error{
    position: absolute;
    top:-10%;
    left:0;
    display: flex;
    gap:0.5rem;
    flex-wrap: wrap;
} */


.jd-user-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
}

.show-jd-tour-btn{
    position: absolute;
    bottom: 0;
    left: 30px;
    border: 1px solid var(--buttons-background-color);
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.1rem 0.3rem;
    transform: translateY(-50%);
    border-radius: 10px;
    color: var(--text-primary);
    cursor: pointer;
    background-color: rgba(190, 5, 250, 0.5);
}
.show-jd-tour-btn:active{
    transform: translateY(-50%) scale(0.95);
}

.jd-user-icon {
    color: #BE05FA;
    font-size: 14px;
    margin-right: 4px;
}

.jd-name {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    flex: 1;
}

.jd-id-number {
    background: white;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0.8px;
}
.jd-file-type {
    background: rgba(190, 5, 250, 0.1);
    color: #BE05FA;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    margin-left: auto;
    cursor: pointer;
}

.jd-job-title {
    color: #BE05FA;
    font-size: 15px;
    text-align: justify;
}

.jd-info-row {
    display: grid; /* Change to grid layout */
    grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
    gap: 20px; /* Space between columns */
    margin-top: 35px;
}

.jd-info-item {
    display: flex;
    flex-direction: column;
    gap: 6px;   
}

.jd-info-label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
}

.jd-info-value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 13px;
}

/* Card content sections */
.jd-guru-top {
    flex: 0 0 auto;
    margin-bottom: 15px;
    text-align: left;
    position: relative;
    padding-left: 35px; /* Space for edit button */
}

.jd-guru-role h3 {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 15px 0;
    color: #fff;
    line-height: 1.2;
    text-align: left;
    margin-right: 5px; /* Removed extra margin */
}

.jd-guru-company {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: calc(100% - 40px);
    margin-left: 0px;
    margin-top: 15px;
}

.jd-guru-company-badge {
    display: inline-block;
    padding: 4px 12px;
    background: rgba(190, 5, 250, 0.1);
    border-radius: 20px;
    font-size: 13px;
    color: var(--buttons-background-color);
    text-align: center;
}

.jd-guru-type {
    display: inline-block;
    padding: 4px 12px;
    background: transparent;
    border-radius: 20px;
    font-size: 12px;
}

.jd-guru-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 25px 0;
}

.jd-guru-detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #c7c7cc;
}

.jd-guru-detail-item i {
    color: var(--buttons-background-color);
    font-size: 14px;
    width: 16px;
}

/* Action icons section */
.jd-guru-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: auto;
    padding: 15px;
    width: 100%;
}

.jd-guru-footer {
    display: none; /* Or you can completely remove this class */
}

.jd-guru-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .jd-guru-container::-webkit-scrollbar-track {
    background: rgba(190, 5, 250, 0.5) rgba(10, 11, 20, 0.6);
    border-radius: 4px;
  }
  
  .jd-guru-container::-webkit-scrollbar-thumb {
    background: #BE05FA;
    border-radius: 4px;
  }

/* Updated action links styling */
.jd-guru-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: auto;
    padding: 15px;
    width: 100%;
}

.jd-guru-action-link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    padding: 8px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.jd-guru-action-link i {
    font-size: 16px;
    color: #BE05FA;
}

.jd-guru-action-link:hover {
    background: rgba(190, 5, 250, 0.1);
    border-radius: 6px;
}

/* .jd-guru-edit {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.jd-guru-edit i {
    color: #BE05FA;
    font-size: 14px;
}

.jd-guru-edit:hover {
    transform: translateY(-2px);
} */

/* Role and edit icon container */
.jd-guru-role-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Role title */
  .jd-guru-role-edit h3 {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    flex-grow: 1; /* Ensures h3 takes up the available space */
    text-align: left;
  }
  
  /* Edit icon */
  .jd-guru-edit-icon {
    font-size: 14px;
    color: var(--buttons-background-color);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .jd-guru-edit-icon:hover {
    color: #be05fa;
    transform: scale(1.1);
  }

/* .jd-guru-edit {
    position: absolute;
    top: 15px;
    z-index: 10;
    left: 15px;
}

.jd-guru-edit i {

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--buttons-background-color);
    transition: all 0.3s ease;
}

.jd-guru-edit i:hover {
    background: rgba(190, 5, 250, 0.4);
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(190, 5, 250, 0.3);
} */

.jd-guru-resume-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-top: auto;
    padding: 20px 15px;
    width: 100%;
}

.jd-action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 1px;
    border-radius: 8px;
    border: none;
    background: transparent;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: fit-content;
}

.jd-action-btn i {
    font-size: 14px;
    color: #BE05FA;
}

.jd-action-btn:hover {
    background: rgba(190, 5, 250, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 0 15px rgba(190, 5, 250, 0.2);
}

.preview-jd, .download-jd {
    border: none;
}

/* Header section from MockGuru */
.header-section {
    position: relative;
    padding: 40px 20px;
    margin-bottom: 30px;
    text-align: left;
    max-width: 1200px;
    margin: 0 auto;
}

.header-title {
    font-size: 2.4rem;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.header-title::before {
    content: '';
    width: 4px;
    height: 24px;
    background: #BE05FA;
    border-radius: 2px;
}

.header-content {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    padding: 30px;
    background: rgba(190, 5, 250, 0.05);
    border-radius: 16px;
    border: 1px solid rgba(190, 5, 250, 0.1);
}

/* Gradient underline effect */
.header-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    height: 2px;
    background: linear-gradient(90deg, 
        rgba(190, 5, 250, 0) 0%,
        rgba(190, 5, 250, 1) 50%,
        rgba(190, 5, 250, 0) 100%
    );
}

/* Accent dots */
.header-title::before {
    content: '•';
    position: absolute;
    color: #BE05FA;
    font-size: 2rem;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.7;
}

.header-title::after {
    content: '•';
    position: absolute;
    color: #BE05FA;
    font-size: 2rem;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.7;
}

/* Hero section styles */
.hero-section {
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    background: linear-gradient(180deg, rgba(190, 5, 250, 0.05) 0%, rgba(10, 11, 20, 0) 100%);
}

.hero-content {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.hero-left {
    text-align: center;
}

.hero-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 10px;
    line-height: 1.2;
    letter-spacing: -0.02em;
    background: var(--buttons-background-color);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    display: inline-block;
}

.hero-title::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, 
        rgba(190, 5, 250, 0) 0%,
        rgba(190, 5, 250, 0.5) 50%,
        rgba(190, 5, 250, 0) 100%
    );
}

.hero-description {
    font-size: 0.95rem;
    color: #c7c7cc;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
    padding: 10px 20px;
    background: rgba(190, 5, 250, 0.03);
    border-radius: 8px;
    border: 1px solid rgba(190, 5, 250, 0.1);
    white-space: pre-line;
}

.highlight {
    background: linear-gradient(90deg, #BE05FA, #4A0082);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.hero-description .highlight:hover {
    color: #d442ff;
}


/* JD Guru specific card styles */
.jd-action-card {
    background: #0A0B14;
    border: 1px solid rgba(190, 5, 250, 0.15);
    border-radius: 15px;
    padding: 24px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    min-height: 200px;
    width: 100%;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.jd-action-card:hover {
    transform: translateY(-2px);
    border-color: rgba(190, 5, 250, 0.3);
    border-top-color: #BE05FA;
    box-shadow: 0 4px 20px rgba(190, 5, 250, 0.15), 0 0 30px rgba(190, 5, 250, 0.1), inset 0 0 20px rgba(190, 5, 250, 0.05), 0 1px 0 #BE05FA;
}
.jd-action-card::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
        90deg,
        rgba(190, 5, 250, 0) 0%,
        rgba(190, 5, 250, 0.3) 50%,
        rgba(190, 5, 250, 0) 100%
    );
}

.jd-action-content {
    position: relative;
    z-index: 0;
    text-align: center;
    padding: 20px 0;
}

.jd-action-icon {
    width: 60px;
    height: 60px;
    background: rgba(190, 5, 250, 0.15);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
}

.jd-action-icon i {
    font-size: 24px;
    color: #BE05FA;
    animation: jdIconPulse 2s infinite;
}

.jd-action-card h3 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 600;
}

.jd-action-card p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    margin-bottom: 20px;
}

.jd-upload-button {
    background: linear-gradient(135deg, #BE05FA 0%, #8A2BE2 100%);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 80%;
    margin: 10px auto 0;
}

.jd-upload-button:hover {
    background: linear-gradient(135deg, #8A2BE2 0%, #BE05FA 100%);
    transform: translateY(-2px);
}

.jd-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
        circle at top right,
        rgba(190, 5, 250, 0.1) 0%,
        transparent 70%
    );
    pointer-events: none;
}

@keyframes jdIconPulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

/* Tour screen styles */
.reactour__helper {
    background-color: #0e1428 !important;
    border: 2px solid #a86cda !important;
    color: #ffffff !important;
    border-radius: 10px !important;
}

.reactour__arrow {
    color: #a86cda !important;
}

.custom-helper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ffffff;
    margin-top: 10px;
}

/* New styles for tour navigation */
.reactour__navigation {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 10px !important;
}

.reactour__navigation-number {
    color: #ffffff !important;
    font-size: 14px !important;
    margin: 0 15px !important;
    font-weight: 500 !important;
}

.reactour__dot {
    width: 8px !important;
    height: 8px !important;
    margin: 0 4px !important;
}

.reactour__dot--is-active {
    background-color: #a86cda !important;
}

.reactour__button {
    color: #ffffff !important;
    background-color: #a86cda !important;
    border: none !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    margin: 0 5px !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
}

.reactour__button:hover {
    background-color: #9555c8 !important;
    transform: translateY(-1px) !important;
}

/* Media Queries */
@media screen and (max-width: 1500px) {
    .jd-guru-container {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        gap: 20px;
        padding: 16px;
    }
    .jd-guru-card {
        height: 320px;
        padding: 16px;
    }
}

@media screen and (max-width: 1400px) {
    .jd-guru-container {
        grid-template-columns: repeat(3, minmax(190px, 1fr));
        gap: 18px;
    }
    .jd-guru-card {
        height: 300px;
        padding: 14px;
    }
}

@media screen and (max-width: 1245px) {
    .jd-guru-container {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
        gap: 16px;
        padding: 14px;
    }
    .jd-guru-card {
        height: 290px;
        padding: 12px;
    }
}

@media screen and (max-width: 1024px) {
    .jd-guru-container {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        gap: 16px;
        padding: 12px;
    }
    .jd-guru-card {
        height: 280px;
        padding: 12px;
    }
}

@media screen and (max-width: 900px) {
    .jd-guru-container {
        grid-template-columns: repeat(2, minmax(160px, 1fr));
        gap: 12px;
    }
    .jd-guru-card {
        height: 260px;
        padding: 10px;
    }
}

@media screen and (max-width: 785px) {
    .jd-guru-container {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
        gap: 10px;
    }
    .jd-guru-card {
        height: 250px;
        padding: 8px;
    }
}

@media screen and (max-width: 715px) {
    .jd-guru-container {
        grid-template-columns: repeat(2, minmax(140px, 1fr));
        gap: 10px;
        padding: 10px;
    }
    .jd-guru-card {
        height: 240px;
        padding: 8px;
    }
    .hero-section {
        padding: 0;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 530px) {
    .jd-guru-container {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
        gap: 12px;
        padding: 10px;
        height: calc(100vh - 80px); /* Restore fixed height */
        overflow-y: auto; /* Restore scrolling */
        padding-bottom: 120px;
        margin-bottom: 40px; /* Add margin to ensure visibility of last items */
    }
    .jd-guru-card {
        height: 180px;
        max-width: 180px;
        margin: 0 auto;
        padding: 12px;
    }
    .hero-section {
        padding: 0;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 445px) {
    .jd-guru-container {
        /* grid-template-columns: 1fr; */
        gap: 10px;
        padding: 8px;
        height: calc(100vh - 120px);
        overflow-y: auto;
        padding-bottom: 100px;
        margin-bottom: 40px;
    }
    .jd-guru-card {
        height: 150px;
        max-width: 150px;
        padding: 8px;
    }
    .hero-section {
        padding: 0;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 360px) {
    .jd-guru-container {
        padding: 6px;
        gap: 8px;
        height: calc(100vh - 220px); /* Adjusted height to ensure content is scrollable */
        overflow-y: auto;
        padding-bottom: 20px; /* Reduced padding to prevent content hiding */
        margin-bottom: 20px; /* Reduced margin */
    }
    .jd-guru-card {
        height: 120px;
        max-width: 120px;
        padding: 6px;
    }
    .hero-section {
        padding: 0;
        margin-bottom: 0;
    }
}