.launch-modal-content {
  background: #0e1428; /* Semi-transparent backdrop */
  border-radius: 8px;
  min-width: 180vh;
  min-height: 90vh;
  max-width: 1200px;
  max-height: 800px;
  box-shadow: 0 0 6px 2px var(--buttons-background-color);
  position: relative;
  font-family: Arial, sans-serif;
  display: flex;
  gap: 12px;
  width: 90vw;
  height: 90vh;
  overflow: hidden;
}
.left-launch-page {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  min-height:  90vh;
  align-items: center;
  height:100%;
  background: linear-gradient(180deg, var(--screen-background-color),var(--buttons-background-color));
  /* .preplanding-services{
    margin:20px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
  } */
  .circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 35px; /* Adjust size as needed */
    height: 35px; /* Adjust size as needed */
    border: 2px solid #ffffff; /* Circle border color */
    border-radius: 50%; /* Makes it a circle */
    font-weight: bold; /* Keeps the bold text */
    text-align: center;
    color:white;
    background-color:transparent;
  }
}

.launch-service-button:disabled{
  cursor: not-allowed ;
};
.launch-service-button:hover{
  transform: translateX(5px);
  border-color: rgba(190, 5, 250, 0.2);
  background: rgba(190, 5, 250, 0.05);
}
.launch-service-button.active{
  border-color: rgba(190, 5, 250, 0.25);
  box-shadow: 
      0 0 20px rgba(190, 5, 250, 0.1),
      inset 0 0 15px rgba(190, 5, 250, 0.05);
  font-weight: 500;
  letter-spacing: 0.3px;
} 


  .preplanding-services{
    margin:20px 0px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    display: flex;
  } 

  .launch-service-button{
    font-size: 16px;
    background-color: transparent;
    color: white;
    border: none;
    padding: 14px 20px;
    margin: 4px 0px;
    text-align: left;
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 15px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(190, 5, 250, 0.1);
  }


.right-launch-page{
  display: flex;
  flex:1;
  color:white;
  margin:10px;
}
.launch-right{
  display:flex;
  width:100%;
  flex-direction: column;
  h4,
  h2{
    margin:10px;
    color: var(--buttons-background-color);
  }
}
.jdlist-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  margin:50px 10px 0px 0px;
  gap:20px;
  max-height: 425px !important;
  padding: 0 10px;
  overflow-y: auto;
}
 
/* Scrollbar */
.jdlist-card-container::-webkit-scrollbar {
  width: 8px;
}
 
.jdlist-card-container::-webkit-scrollbar-track {
  background: rgba(190, 5, 250, 0.1);
  border-radius: 4px;
}
 
.jdlist-card-container::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
}
.launch-card-footer{
position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    padding: 15px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: #0A0B14;
    border-radius: 0 0 22px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 0px;
}
 
 
.resumelist-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(232px, 1fr));
  margin: 50px 10px 0px 0px;
  gap: 20px;
  max-height: 425px !important;
  padding: 0 10px;
  overflow-y: auto;
}

.resumelist-card-container::-webkit-scrollbar {
  width: 8px;
}

.resumelist-card-container::-webkit-scrollbar-track {
  background: rgba(190, 5, 250, 0.5) rgba(10, 11, 20, 0.6);
  border-radius: 4px;
}

.resumelist-card-container::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
}

.resume-list-card {
  width: 200px;
  height: 320px;
  background-color: var(--screen-background-color);
  border-radius: 15px;
  border: 2px solid transparent;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
  color: #fff;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 6px 2px var(--buttons-background-color);
}
 
.resume-list-card:hover .resume-image {
  transform: scale(1.02);
}
 
.resume-list-card:hover .resume-action-overlay {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
}

.upload-error{
  color: red;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap:0.5rem;
}
 
.resume-list-card:hover {
  transform: translateY(-5px);
  border-color: var(--buttons-background-color);
  box-shadow: 0 0 15px 5px var(--buttons-background-color);
}
.resume-list-card.selected{
  transform: translateY(-5px);
  border-color: var(--screen-background-color);
  background-color: var(--buttons-background-color);
}
 
 
.persona-container{
  width:100%;
  display: flex;
  color:white;
  margin-top:20px;
  justify-content: space-evenly!important;
  align-items: center;
  .persona-name{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .persona {
    width: 250px;
    height: 300px; /* Circle size */
    border: 2px solid white; /* Border color for the circle */
    border-radius: 50%; /* Makes the div a circle */
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* box-shadow: 0 0 6px 2px white; */
    cursor: pointer;
  }
  .persona:hover,
  .persona.active{
    border:2px solid var(--buttons-background-color);
    box-shadow: 0 0 10px 5px var(--buttons-background-color);
  }
 
  .persona img {
    width: 100%; /* Ensures the image fills the circle */
    height: 100%; /* Ensures the image fills the circle */
    object-fit: cover; /* Ensures the image scales proportionally and fills the circle */
  }
  .persona h4{
    text-align: center;
  }
 
}
 
.interview-container{
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .interviewtype-card-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    height: 330px;
    background-color: var(--screen-background-color);
    border-radius: 15px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    text-align: center;
    color: #fff;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    box-shadow: 0 0 6px 2px var(--buttons-background-color);
    border: 2px solid transparent; /* Default border */
 
  }
  .interviewtype-card-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 15px 5px var(--buttons-background-color);
  }
  .interviewtype-card-content.selected{
    transform: translateY(-5px);
    border-color: var(--screen-background-color);
    background-color: var(--buttons-background-color);
  }
  img {
    width: 150px;
    height: auto;
    margin-top: 10px;
  }
}
/* .schedule-selector {
 box-shadow: 0 0 4px 1px var(--buttons-background-color);
 display: flex;
 justify-content: center;
 background-color: #f5f9fd;
 margin-top: 25px;
 width:340px;
}
 
.option-button {
 width:100%;
 border: 1px solid transparent;
 padding: 10px 36.1px;
 border-radius: 4px;
 font-size: 16px;
 font-weight: 700!important;
 background-color: transparent;
 color: #3c4858;
 cursor: pointer;
 font-weight: 500;
 transition: all 0.2s ease;
}
 
.option-button.active {
 background-color: var(--buttons-background-color);
 color: #ffffff;
}
 
.option-button:hover {
 background-color: var(--buttons-background-color);;
 color:#ffffff;
}
.date-name-picker{
 display: flex;
 flex-direction: column;
 margin:40px 0px;
 .date-label{
   padding:4px 0px;
 }
}
.date{
 display: flex;
 flex-direction: row;
 width:38%;
 justify-content: center;
 align-items: center;
 border: 1px solid var(--buttons-background-color);
 box-shadow: 0 0 4px 1px var(--buttons-background-color);
 border-radius: 20px;
 
}
.react-datepicker-wrapper{
 width:30%;
}
.datepicker{
border-radius: 20px;
width:80%;
padding:25px 0px;
background-color: #0e1428;
border: none;
color:white;
box-shadow: none;
}
.datepicker:focus{
background-color: transparent;
color:white;
border: none !important;
}
.time-zone-select {
width: 38%;
padding: 15px 10px ;
border-radius: 20px;
font-size: 14px;
color: #ffffff;
background-color: transparent;
cursor: pointer;
border: 1px solid var(--buttons-background-color);
box-shadow: 0 0 4px 1px var(--buttons-background-color);
}
 
.time-zone-select option{
color:black;
 
}
 
.timezone-picker{
margin-top: 25px;
display: flex;
flex-direction: column;
 
} */
 
 
.launch-navigation-buttons {
  position: absolute;
  bottom: 20px;
  right: 40px; /* Move Previous and Next buttons to the left */
  display: flex;
  gap: 10px;
}
 
.prev-button,
.next-button {
  padding: 10px 20px;
  background-color: var(--buttons-background-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: transform 0.3s ease;
}
 
.prev-button:hover,
.next-button:hover {
  transform: scale(1.05);
}
 
.next-button:disabled {
  background-color: #ccc; /* Light grey background */
  color: #666;           /* Dimmed text color */
  cursor: not-allowed;   /* Disabled cursor */
  transform: none;       /* Disable hover transform */
}
 
.launch-btn-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px; /* Ensure this aligns the Launch button */
  right: 40px; /* Move Launch button to the right */
  gap: 10px;
 
}
 
.launch-button {
  width: auto;
  padding: 10px 20px;
  background: var(--buttons-background-color);
  border: 0.5px solid var(--buttons-background-color);
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
 
.launch-button:hover {
  transform: scale(1.05);
}
 
/* Loading Overlay Styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above all other elements */
}
 
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}
 
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 
.loading-overlay p {
  margin-top: 10px;
  color: white;
  font-size: 18px;
}
 
 
.selected-cards-container {
  display: flex;
  gap: 90px;
  margin-top: 20px;
}
 
.launch-persona-container{
  width:90%;
  color:white;
  margin-top:20px;
  
  
  .persona-name{
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .launch-persona {
    width: 200px;
    height: 250px !important; 
    border: 2px solid white;
    border-radius: 50%; 
    display: flex;
    background-color: transparent;
    overflow: hidden;
    cursor: pointer;
  }
  .launch-persona.active{
    border:2px solid var(--buttons-background-color);
    box-shadow: 0 0 10px 5px var(--buttons-background-color);
    
  }
 
  .launch-persona img {
    width: 100%; /* Ensures the image fills the circle */
    height: 100%; /* Ensures the image fills the circle */
    object-fit: cover; /* Ensures the image scales proportionally and fills the circle */
  }
 
}
 
 
.launch-resume-list-card {
  width: 325px;
  height: 290px;
  background-color: var(--screen-background-color);
  border-radius: 15px;
  border: 2px solid transparent;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
  color: #fff;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 6px 2px var(--buttons-background-color);
}
 
.launch-resume-list-card.selected{
  transform: translateY(-5px);
  border-color: var(--screen-background-color);
  background-color: var(--buttons-background-color);
}
 
.launch-jd-list-card {
  width: 325px;
  height: 290px;
  background-color: var(--screen-background-color);
  border-radius: 15px;
  border: 2px solid transparent;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
  color: #fff;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 6px 2px var(--buttons-background-color);
}
 
.launch-jd-list-card.selected{
  transform: translateY(-5px);
  border-color: var(--screen-background-color);
  background-color: var(--buttons-background-color);
}
 
.resume-id{
  font-size: 13px;
}
 
.launch-session-text{
  margin-top: 20px !important;
  margin-left: 0px !important;
  padding: 20px;
}
 
.pre-sub-title{
  margin-top: 10px;
}
 
/* JD Guru specific card styles */
.jd-action-card {
  background: rgba(190, 5, 250, 0.1);
  border: 1px solid rgba(190, 5, 250, 0.2);
  border-radius: 12px;
  padding: 24px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  min-height: 200px;
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
 
.jd-action-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(190, 5, 250, 0.15);
  border-color: rgba(190, 5, 250, 0.4);
}
 
.jd-action-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 20px 0;
}
 
.jd-action-icon {
  width: 60px;
  height: 60px;
  background: rgba(190, 5, 250, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}
 
.jd-action-icon i {
  font-size: 24px;
  color: #BE05FA;
  animation: jdIconPulse 2s infinite;
}
 
.jd-action-card h3 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
 
.jd-action-card p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 20px;
}
 
.jd-upload-button {
  background: linear-gradient(135deg, #BE05FA 0%, #8A2BE2 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 80%;
  margin: 10px auto 0;
}
 
.jd-upload-button:hover {
  background: linear-gradient(135deg, #8A2BE2 0%, #BE05FA 100%);
  transform: translateY(-2px);
}
 
.jd-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
      circle at top right,
      rgba(190, 5, 250, 0.1) 0%,
      transparent 70%
  );
  pointer-events: none;
}
 
@keyframes jdIconPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
 
.jd-guru-card.selected {
  border-color: rgba(190, 5, 250, 0.5);
  background: rgba(190, 5, 250, 0.2)!important;
  transform: translateY(-2px);
}
 
.jd-action-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid rgba(190, 5, 250, 0.3);
  background: rgba(190, 5, 250, 0.1);
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
 
.jd-action-btn:hover {
  background: rgba(190, 5, 250, 0.2);
  border-color: rgba(190, 5, 250, 0.5);
}
 
.jd-action-btn i {
  font-size: 14px;
}
 
.jd-guru-id {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 8px;
}
 
.jd-guru-company-badge {
  display: inline-block;
  padding: 4px 12px;
  background: rgba(190, 5, 250, 0.1);
  border-radius: 20px;
  font-size: 13px;
  color: var(--buttons-background-color);
}
 
.jd-guru-type {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 4px;
}
 
.jd-guru-detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #c7c7cc;
}
 
.jd-guru-detail-item i {
  color: var(--buttons-background-color);
  font-size: 14px;
  width: 16px;
}
 
.preview-jd, .download-jd {
  border: none;
}
 
.launch-resume-card{
  width: 100%;
  min-width: 230px;
  max-width: 260px;
  background: #0A0B14 !important;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid rgba(190, 5, 250, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 320px;
  margin: 0 auto;
}

.launch-resume-card.selected {
  border-color: rgba(190, 5, 250, 0.5);
  background: rgba(190, 5, 250, 0.2) !important;
}
 
.card-footer.selected{
  border-color: rgba(190, 5, 250, 0.5);
  background: rgba(190, 5, 250, 0.2) !important;
}
 
 
/* For the selected card in the overview section */
.selected-cards-container .jd-guru-card.selected {
  min-height: 200px;
  width: 450px; /* Adjust this value to match the original card height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
 

.launch-resume-list-card,
.launch-jd-list-card,
.jd-action-card {
  width: 100%; /* Full width for cards */
  max-width: 250px; /* Reduced maximum width for larger screens */
  height: auto; /* Allow height to adjust based on content */
  padding: 15px; /* Reduced padding for content */
  box-sizing: border-box; /* Include padding in width calculation */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}
 
 
/* Adjust button sizes */
.prev-button,
.next-button,
.launch-button,
.jd-upload-button {
  padding: 8px 16px; /* Adjust padding for buttons */
  font-size: 14px; /* Adjust font size */
}
 
/* Modern button styles */
.next-button,
.prev-button,
.launch-button {
  padding: 12px 24px; /* Increased padding for a more substantial look */
  border: none; /* Remove default border */
  border-radius: 25px; /* Rounded corners for a modern feel */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  color: #fff; /* White text color */
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); /* Gradient background */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}
 
.next-button:hover,
.prev-button:hover,
.launch-button:hover {
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}
 
.next-button:disabled,
.prev-button:disabled,
.launch-button:disabled {
  background: #ccc; /* Light grey background for disabled state */
  color: #666; /* Dimmed text color */
  cursor: not-allowed; /* Not-allowed cursor for disabled state */
  box-shadow: none; /* Remove shadow for disabled state */
}
 
/* Example Color Palette */
:root {
    --primary-color: #BE05FA; /* Vibrant Purple */
    --hover-color: #9B00B3; /* Darker shade of purple for hover */
    --text-color: #ffffff; /* White text */
}
 
/* Consistent modern button styles */
.button {
  padding: 12px 24px; /* Consistent padding */
  border: none; /* Remove default border */
  border-radius: 30px; /* More rounded corners for a modern feel */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  color: var(--text-color); /* Text color */
  background: var(--primary-color); /* Primary button color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}
 
.button:hover {
  background: var(--hover-color); /* Darker shade for hover */
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}
 
.button:disabled {
  background: #ccc; /* Light grey background for disabled state */
  color: #666; /* Dimmed text color */
  cursor: not-allowed; /* Not-allowed cursor for disabled state */
  box-shadow: none; /* Remove shadow for disabled state */
}
 
/* Specific styles for the launch button */
.launch-button {
  display: flex; /* Flexbox for icon and text alignment */
  align-items: center; /* Center items vertically */
}
 
/* Icon styles */
.launch-icon {
  margin-right: 8px; /* Space between icon and text */
}
 
/* Styles for Next and Previous buttons */
.prev-button,
.next-button {
  background: var(--primary-color); /* Same primary color */
}
 
.mock-launch-pod-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    padding: 20px; /* Add padding for spacing */
    max-width: 100%; /* Prevent overflow */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}
 
.launch-btn-container {
    display: flex;
    justify-content: space-between; /* Space between buttons */
    width: 100%; /* Full width */
    max-width: 600px; /* Optional: Limit max width */
}
 
.prev-button, .launch-button {
    flex: 1; /* Allow buttons to grow equally */
    margin: 0 10px; /* Space between buttons */
}
 
/* Ensure buttons are responsive */
.launch-button {
    display: flex; /* Flexbox for icon and text alignment */
    align-items: center; /* Center items vertically */
}
 
 
/* Main container for the launch pad */
.launch-pad-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    padding: 20px; /* Add padding for spacing */
    width: 100%; /* Full width */
    max-width: 100vw; /* Prevent overflow beyond viewport */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}
 
/* Button container */
.launch-btn-container {
    display: flex;
    justify-content: space-between; /* Space between buttons */
    width: 100%; /* Full width */
    max-width: 300px; /* Optional: Limit max width */
}
 
/* Buttons */
.prev-button, .launch-button {
    flex: 1; /* Allow buttons to grow equally */
    margin: 0 10px; /* Space between buttons */
}
 
/* Ensure body does not scroll horizontally */
body {
    overflow-x: hidden; /* Prevent horizontal scrolling on the body */
}

/* Tour Guide Specific Styles */
.selected-cards-container.tour-active {
  display: flex;
  gap: 40px; /* Increased gap between all flex items */
  justify-content: center; /* Center all items */
  margin: 20px;
  align-items: flex-start;
  padding: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: calc(100% - 40px);
}

.launch-persona-container.tour-active {
  display: flex;
  color: white;
  margin: 0;
  margin-left: 40px; /* Additional space before the persona */
  flex-shrink: 0;
  width: auto;
  transform: translateX(-2%);
}

.launch-resume-card.tour-active {
  width: 280px;
  min-height: 290px;
  background: #0A0B14 !important;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid rgba(190, 5, 250, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin: 0;
  flex-shrink: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added subtle shadow for better separation */
}

/* Scrollbar styling for the container */
.selected-cards-container.tour-active::-webkit-scrollbar {
  height: 8px;
}

.selected-cards-container.tour-active::-webkit-scrollbar-track {
  background: rgba(190, 5, 250, 0.1);
  border-radius: 4px;
}

.selected-cards-container.tour-active::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
}

/* Tour Guide Media Queries */
/* @media (max-width: 1200px) {
  .selected-cards-container.tour-active {
    gap: 40px;
  }
  
  .launch-persona-container.tour-active {
    margin-left: 30px;
  }
}

@media (max-width: 992px) {
  .selected-cards-container.tour-active {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .launch-persona-container.tour-active {
    margin-left: 0;
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .selected-cards-container.tour-active {
    padding: 10px;
    gap: 20px;
  }

  .launch-resume-card.tour-active {
    width: 100%;
    max-width: 280px;
  }
} */

/* Add these media queries at the end of your existing CSS file */

/* Large Desktop (1440px) */
@media (max-width: 1440px) {
  .launch-modal-content {
    min-width: 140vh;
    max-width: 1200px;
  }

  .jdlist-card-container,
  .resumelist-card-container {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  .selected-cards-container {
    gap: 60px;
  }

  .launch-resume-card,
  .launch-jd-list-card {
    max-width: 240px;
  }

  .persona {
    width: 240px;
    height: 290px;
  }

  .launch-resume-list-card,
  .launch-jd-list-card {
    width: 300px;
    height: 280px;
  }

  .jd-action-card {
    min-height: 190px;
    width: 220px;
  }

  .interview-container {
    gap: 50px;
  }

  .selected-cards-container.tour-active {
    gap: 50px;
  }

  .launch-service-button {
    padding: 14px 18px;
    font-size: 16px;
  }

  .jd-action-icon {
    width: 50px;
    height: 50px;
  }

  .launch-resume-card.upload-card {
    width: 300px;
    height: 320px;
  }

  .launch-resume-card.create-card {
    width: 300px;
    height: 320px;
  }
}

@media (max-width: 1140px) {
  .launch-modal-content {
    min-width: 120vh;
    max-width: 900px;
  }

  .jdlist-card-container,
  .resumelist-card-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .selected-cards-container {
    gap: 50px;
  }

  .launch-resume-card,
  .launch-jd-list-card {
    max-width: 220px;
  }

  .persona {
    width: 220px;
    height: 270px;
  }

  .launch-resume-list-card,
  .launch-jd-list-card {
    width: 280px;
    height: 260px;
  }

  .jd-action-card {
    min-height: 180px;
    width: 210px;
  }

  .interview-container {
    gap: 40px;
  }

  .selected-cards-container.tour-active {
    gap: 40px;
  }

  .launch-service-button {
    padding: 12px 16px;
    font-size: 15px;
  }

  .jd-action-icon {
    width: 45px;
    height: 45px;
  }

  .launch-resume-card.upload-card {
    width: 280px;
    height: 300px;
  }

  .launch-resume-card.create-card {
    width: 280px;
    height: 300px;
  }
}

@media (max-width: 992px) {
  .launch-modal-content {
    min-width: 100vh;
    max-width: 800px;
  }

  .jdlist-card-container,
  .resumelist-card-container {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .selected-cards-container {
    gap: 40px;
  }

  .launch-resume-card,
  .launch-jd-list-card {
    max-width: 200px;
  }

  .persona {
    width: 200px;
    height: 250px;
  }

  .launch-resume-list-card,
  .launch-jd-list-card {
    width: 260px;
    height: 240px;
  }

  .jd-action-card {
    min-height: 170px;
    width: 200px;
  }

  .interview-container {
    gap: 30px;
  }

  .selected-cards-container.tour-active {
    gap: 30px;
  }

  .launch-service-button {
    padding: 10px 14px;
    font-size: 14px;
  }

  .jd-action-icon {
    width: 40px;
    height: 40px;
  }

  .launch-resume-card.upload-card {
    width: 260px;
    height: 300px;
  }

  .launch-resume-card.create-card {
    width: 260px;
    height: 300px;
  }
}

@media (max-width: 840px) {
  .launch-modal-content {
    min-width: 80vh;
    max-width: 680px;
    width: 95vw;
  }

  .jdlist-card-container,
  .resumelist-card-container {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
  }

  .selected-cards-container {
    gap: 30px;
  }

  .launch-resume-card,
  .launch-jd-list-card {
    max-width: 175px;
  }

  .persona {
    width: 175px;
    height: 225px;
  }

  .launch-resume-list-card,
  .launch-jd-list-card {
    width: 235px;
    height: 215px;
  }

  .jd-action-card {
    min-height: 155px;
    width: 185px;
  }

  .interview-container {
    gap: 25px;
  }

  .selected-cards-container.tour-active {
    gap: 25px;
  }

  .launch-service-button {
    padding: 8px 11px;
    font-size: 11px;
  }

  .jd-action-icon {
    width: 34px;
    height: 34px;
  }

  .launch-resume-card.upload-card {
    min-width: 185px;
    height: 300px;
  }

  .launch-resume-card.create-card {
    min-width: 185px;
    height: 300px;
  }

  .launch-resume-card.upload-card.job-title{
    font-size: 120px;
  }
  .launch-card-footer{
    padding: 10px 10px;
  }
}

@media screen and (max-width: 715px) {
  .launch-modal-content {
    flex-direction: column;
  }

  .left-launch-page {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 0 0 60px; 
    align-items: center;
    padding: 0 15px;
    width: 100%;
    min-height: 60px;
    background: linear-gradient(90deg, var(--screen-background-color), var(--buttons-background-color));
  }

  .burger-menu-btn {
    display: flex;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px;
    z-index: 1000;
  }

  .burger-menu-btn::before {
    content: '\2630';
    font-size: 20px;
  }

  .preplanding-services {
    position: relative;
    top: 0;
    left: -100%;
    width: 250px;
    height: 100vh;
    background: var(--buttons-background-color);
    flex-direction: column;
    padding: 80px 20px 20px;
    transition: left 0.3s ease;
    z-index: 999;
  }

  .preplanding-services.menu-open {
    left: 0;
    transform: translate(-10%, 45%);
  }

  .right-launch-page {
    flex: 1;
    height: calc(100vh - 60px); 
    overflow-y: auto;
    padding: 20px;
  }

  .launch-service-button {
    width: 100%;
    padding: 12px 20px;
    margin: 5px 0;
    font-size: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    color: white;
    transform: translateY(-90px);
  }

  .launch-navigation-buttons {
    position: absolute;
    bottom: 20px;
    right: 90px;
    display: flex;
    gap: 10px;
    transform: translate(40%, 40%);
    height: 30px;
    width: 20px;
}

  .next-button{
    padding: 8px 16px;
  }

  .jdlist-card-container,
  .resumelist-card-container {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }

  
  .menu-open::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  
  .modal-overlay {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .launch-modal-content {
    flex-direction: column;
    min-width: 60vw;
    max-width: 450px;
    margin: 0 auto;
  }

  .launch-navigation-buttons {
    position: absolute;
    bottom: 20px;
    right: 40px;
    display: flex;
    gap: 10px;
    transform: translate(-50%, 20%);
}

  .left-launch-page {
    flex: 0 0 7vh;
    min-height: 7vh;
    padding: 0 2.5vw;
    width: 100%;
  }

  .burger-menu-btn {
    left: 3.5vw;
    padding: 1.2vh;
  }

  .preplanding-services {
    width: 85vw;
    max-width: 250px;
    padding: 10vh 3.5vw 3.5vw;
  }

  .right-launch-page {
    height: calc(93vh - var(--header-height));
    padding: 2.5vw;
    width: 100%;
    overflow-x: hidden;
  }

  .launch-service-button {
    font-size: clamp(13px, 1.8vw, 15px);
    padding: 1.8vh 2.5vw;
    width: 100%;
  }

  .selected-cards-container.tour-active {
    gap: 15px;
  }

  
  .jdlist-card-container,
  .resumelist-card-container {
    /* display: grid; */
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    /* gap: 15px;
    padding: 10px;
    width: 100%; */
  }
  
  .launch-resume-card,
  .launch-jd-list-card {
    width: 100%;
    min-height: 180px;
    margin: 0 auto;
  }

  .launch-resume-card.upload-card {
    min-width: 155px;
    height: 300px;
}

.launch-resume-card.create-card {
  min-width: 155px;
  height: 300px;
}

}

@media screen and (max-width: 420px) {
  .launch-modal-content {
    width: 95vw;
    max-width: 360px;
  }

  .left-launch-page {
    flex: 0 0 6vh;
    min-height: 6vh;
  }

  .landing-services {
    width: 90vw;
    max-width: 220px;
  }

  .jdlist-card-container,
  .resumelist-card-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    /* gap: 12px;
    padding: 8px; */
  }

  .launch-resume-card,
  .launch-jd-list-card {
    min-height: 160px;
  }

  .launch-navigation-buttons {
    position: absolute;
    bottom: 20px;
    right: 60px;
    display: flex;
    gap: 10px;
    transform: translate(40%, 20%);
}

  .launch-service-button {
    font-size: clamp(12px, 1.6vw, 14px);
    padding: 1.5vh 2vw;
  }

  .launch-resume-card.upload-card {
    min-width: 125px;
    height: 280px;
}

.launch-resume-card.create-card {
  min-width: 125px;
  height: 280px;
}
.selected-cards-container.tour-active {
  gap: 5px;
}

}

@media screen and (max-width: 320px) {
  .launch-modal-content {
    width: 98vw;
    max-width: 310px;
  }

  .left-launch-page {
    flex: 0 0 5vh;
    min-height: 5vh;
  }

  .landing-services {
    width: 95vw;
    max-width: 200px;
  }

  .jdlist-card-container,
  .resumelist-card-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    padding: 5px;
  }

  .launch-resume-card,
  .launch-jd-list-card {
    min-height: 140px;
  }

  .launch-navigation-buttons {
    position: absolute;
    bottom: 20px;
    right: 60px;
    display: flex;
    gap: 10px;
    transform: translate(40%, 20%);
    width: 10px;
  } 

}