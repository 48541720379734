.jdguru-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0e1428 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.jdguru-modal {
  background: #0E1428;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  border: 1px solid rgba(190, 5, 250, 0.2);
  outline: none;
}

.jdguru-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 90vh;
}

/* Header */
.jdguru-modal-header {
  padding: 24px;
  border-bottom: 1px solid rgba(190, 5, 250, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(135deg, rgba(190, 5, 250, 0.1) 0%, rgba(14, 20, 40, 0.2) 100%);
}

.jdguru-header-content {
  flex: 1;
}

.jdguru-title {
  color: #fff;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

.jdguru-company {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}

.jdguru-close-btn {
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 20px;
}

.jdguru-close-btn:hover {
  color: #BE05FA;
  transform: rotate(90deg);
}

/* Body */
.jdguru-modal-body {
  padding: 24px;
  overflow-y: auto;
  flex: 1;
  background: #0e1428
}

.jdguru-info-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-bottom: 30px;
  background: rgba(190, 5, 250, 0.05);
  padding: 16px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.jdguru-info-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
}

.jdguru-info-item i {
  color: #BE05FA;
  font-size: 18px;
  min-width: 20px;
  text-align: center;
}

.jdguru-info-item div {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

.jdguru-info-item label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
  text-align: center;
}

.jdguru-info-item span {
  color: #fff;
  font-size: 13px;
  text-align: center;
}

/* Description Section */
.jdguru-description-section {
  background: rgba(190, 5, 250, 0.05);
  border-radius: 12px;
  padding: 24px;
}

.jdguru-main-heading {
  color: #fff;
  font-size: 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(190, 5, 250, 0.2);
}

.jdguru-main-heading i {
  color: #BE05FA;
}

.jdguru-subsection {
  margin-bottom: 24px;
  padding: 16px;
  background: rgba(14, 20, 40, 0.6);
  border-radius: 8px;
}

.jdguru-subheading {
  color: #BE05FA;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}

.jdguru-content {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.jdguru-list-item {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  align-items: flex-start;
}

.bullet {
  color: #BE05FA;
  min-width: 12px;
}

/* Footer */
.jdguru-modal-footer {
  padding: 20px 24px;
  border-top: 1px solid rgba(190, 5, 250, 0.2);
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.jdguru-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.jdguru-btn-primary {
  background: linear-gradient(135deg, #BE05FA 0%, #8A2BE2 100%);
  color: white;
  border: none;
}

.jdguru-btn-secondary {
  background: transparent;
  color: #BE05FA;
  border: 1px solid rgba(190, 5, 250, 0.2);
}

.jdguru-btn:hover {
  transform: translateY(-2px);
}

/* Scrollbar */
.jdguru-modal-body::-webkit-scrollbar {
  width: 8px;
}

.jdguru-modal-body::-webkit-scrollbar-track {
  background: rgba(190, 5, 250, 0.1);
  border-radius: 4px;
}

.jdguru-modal-body::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
}

/* Responsive */
@media screen and (max-width: 768px) {
  .jdguru-modal {
      width: 95%;
      max-height: 95vh;
  }

  .jdguru-modal-header {
      padding: 16px;
  }

  .jdguru-title {
      font-size: 20px;
  }

  .jdguru-info-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
  }

  .jdguru-modal-footer {
      flex-direction: column;
  }

  .jdguru-btn {
      width: 100%;
      justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .jdguru-info-grid {
      grid-template-columns: 1fr;
  }
}

.jdguru-details-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin: 20px 0 30px 0;
  background: rgba(190, 5, 250, 0.05);
  padding: 16px;
  border-radius: 12px;
}

.jdguru-detail-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
}

.jdguru-detail-item i {
  color: #BE05FA;
  font-size: 18px;
  min-width: 20px;
}

.jdguru-detail-item div {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

.jdguru-detail-item label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
}

.jdguru-detail-item span {
  color: #fff;
  font-size: 13px;
}

.jdguru-role-container, .jdguru-company-container {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
}

.jdguru-role-container {
    margin-bottom: 8px;
}

.jdguru-role-container i, .jdguru-company-container i {
    color: #BE05FA;
    width: 24px;
    text-align: center;
}

.jdguru-role-container i {
    font-size: 24px;
}

.jdguru-company-container i {
    font-size: 18px;
    padding: 3px 0;
}

.jdguru-company-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.jdguru-company-container i {
    color: #BE05FA;
    font-size: 18px;
}

.jdguru-pdf-content {
    background: #0E1428;
    padding: 20px;
    color: #fff;
}

/* PDF-specific styles to ensure proper rendering */
@media print {
    .jdguru-pdf-content {
        background: #0E1428 !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .jdguru-modal-header,
    .jdguru-info-grid,
    .jdguru-details-grid,
    .jdguru-description-section {
        break-inside: avoid;
    }
}