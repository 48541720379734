.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .success-modal-content {
    background:#0e1428 ;
    border: 1px solid var(--buttons-background-color);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
  }
  
  .success-modal-header {
    font-size: 30px;
    color: #28a745;
  }
  
  .success-modal-body {
    margin-top: 15px;
  }
  
  .sucess-modal-footer {
    margin-top: 20px;
  }
  
  .success-btn{
    background-color:var(--buttons-background-color);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .success-btn:hover {
    background-color: var(--buttons-background-color);
  }
  