body{
  background-color: var(--screen-background-color) !important
}
/* .with-black-border{
    width:100%;
    height: 100vh;
} */

.pricing-container{
  margin-top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* font-family: "greycliff-cf", sans-serif; */
  justify-content: flex-end;
  align-items: center;
    .section-title{
      color:var(--text-primary); 
      height: fit-content;
      margin-bottom: clamp(0rem, 1rem, 2rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-wrap: balance;
      text-align: center;
      width: 50%;
      /* max-width: 70%; */
      gap:0.5em;
      h2{
        padding: 0;
        font-size: 1.6rem;
        font-weight: 700;
      }
      span{
        color: var(--text-secondary);
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .saas-pricing-tabs{
      width: 90%;
      min-height: 75%;
    }
}

.saas-pricing-tabs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* gap:clamp(5%, 8%, 15%); */

  .tabList{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    min-height: fit-content;
    height: 8%;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    .tab{
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      width: 100%;
      height: 100%;
      cursor: pointer;
      font-size: 1rem;
      transition: all 0.2s ease-in-out;
      .tab-logo{
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .active{
      background-color: var(--buttons-background-color);
      color: white;
    }
  }
}

.tab:hover{
  background-color: var(--buttons-background-color);
  color: var(--text-primary) !important;
}
.tabData .pricing-section .plan-title, .session-features{
  color:var(--text-black);
}

.tabList-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* gap:1em; */
  width:90%;
  height: fit-content;
  min-height: 80%;  
  margin-bottom: 0.5rem;
  .tabData{
    border: 1px solid black;
    position: relative;
    cursor: pointer;
    background-color: #ffffffd6;
    width: 30%;
    height: 100%;
    max-height: 600px;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    transition: all 0.5s ease;
    .free-trial-tag{
      position: absolute;
      top: 0;
      right: 0%;
      background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%); 
      z-index: 10;
      color:var(--text-primary);
      padding: 1.3rem 3.5rem;
      transform: translateX(32%) rotate(45deg);
      font-size: 1.1rem;
      display: inline;
    }
    .tip{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%); 
      z-index: 10;
      color:var(--text-primary);
      border-radius: 100px;
      padding: 0.2rem 0.5rem;
      display: inline;
    }
    .content{
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-content: start;
      gap:clamp(0.5rem, 1rem, 2rem);
    }
    .pricing-section{
      margin-top:clamp(5%, 10%, 15%);
      width: 100%;
      display: flex;
      flex-direction: column;
      height: fit-content;
      text-align: center;
      .plan-title{
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0;
      }
      .price{
        color: black !important;
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
      }
      .session-info{
        font-size: 0.9rem;
        display: flex;
        flex-direction: column;
        color: rgb(67, 66, 66);
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        padding: 0 1rem;
        align-items: center;
        .unactive{
          text-decoration: line-through;
        }
        .discount{
          display: inline;
          color: white;
          width: fit-content;
          padding: 0 0.5rem;
          background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%); 
          border-radius: 1rem;
          margin-left: 0.3rem;
        }
      }
    }
    .session-features{
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap:clamp(0.1rem, 0.2rem, 0.4rem);
      div{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap:0.5rem;
        align-items: center;
        font-size: 0.8rem;

        .check{
          color: var(--buttons-background-color);
          flex:1;
          aspect-ratio: 1 / 1;
        }
        span{
          width:80%;
          height: 100%;
          text-align: left;
        }
      }
    }
    /* temporary css */
    .free{
      margin-top: 4rem;
    }
    .custom{
      margin-top: 0;
    }
    .starter{
      margin-top: 3rem;
    }
    /* */
    .counter-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap:1.2em;
      color: black;
      .counter-btn{
        border:1px solid black;
        border-radius: 50%;
        width:1.2rem;
        height: 1.2rem;
        font-size: 1rem;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color:black;
      }
      .counter-display{
        font-size: 1.5rem;
        font-weight: 300;
        text-decoration: underline;
        /* color: black; */
      }
    }
    .choose-planBtn{
      width: 50%;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1em;
    }
  }
  .freePlan{
    overflow: hidden;
  }
}


.tabData:hover{
  background-color: var(--buttons-background-color);
  transform: translateY(-5px) scale(1.02);
  .pricing-section > *, .pricing-section .price {
    color: white !important;
  }
  .session-features{
    color: white !important;
    div .check{
      color: white;
    }
  }
  .choose-planBtn{
    color:black !important;
    background-color: white;
  }
  .content{
    .free-trial-tag{
      background: white;
      color:var(--buttons-background-color);
      font-weight: 600;
    }
    .tip{
      background: white;
      color:var(--buttons-background-color);
      font-weight: 600;
    }
  }
}

 
  
