.preview-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(14, 20, 40, 0.85);
    backdrop-filter: blur(5px);
}

.preview-modal-content {
    width: 95%;
    max-width: 1000px;
    height: 90vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.resume-preview-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid rgba(190, 5, 250, 0.3);
    background: linear-gradient(135deg, rgba(190, 5, 250, 0.1) 0%, rgba(14, 20, 40, 0.2) 100%);
    border-radius: 12px 12px 0 0;
}

.preview-header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.resume-preview-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #FFFFFF;
}

.preview-back-button {
    background: transparent;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border: 2px solid rgba(190, 5, 250, 0.2);
    color: #BE05FA;
    border-color: #BE05FA;
}

.resumeview-close-button {
    position: absolute;
    top: -5px; /* Adjust distance from the top */
    right: -10px; /* Adjust distance from the right */
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 10; /* Ensure it appears above other elements */
    transition: all 0.3s ease;
  }

  
  
  .resumeview-close-button:hover {
    color: #BE05FA;
    transform: rotate(90deg);
  }

.preview-download-btn {
    padding: 0.75rem 1.5rem;
    background: var(--buttons-background-color);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    transition: background-color 0.2s;
}

.preview-download-btn:hover {
    transform: scale(1.05);
}

.preview-body {
    flex: 1;
    overflow-y: auto;
    padding: 2rem;
    background: linear-gradient(135deg, rgba(190, 5, 250, 0.1) 0%, rgba(14, 20, 40, 0.2) 100%);
}

  /* Scrollbar */
  .preview-body::-webkit-scrollbar {
    width: 8px;
  }
  
  .preview-body::-webkit-scrollbar-track {
    background: rgba(190, 5, 250, 0.1);
    border-radius: 4px;
  }
  
  .preview-body::-webkit-scrollbar-thumb {
    background: #BE05FA;
    border-radius: 4px;
  }

.resume-preview-template {
    background: white;
    padding: 3rem;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    border-radius: 8px;
    color: black;
    text-align: justify;
}

.preview-section {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #edf2f7;
}

.preview-section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.personal-details {
    text-align: center;
    margin-bottom: 0.5rem;
}

.preview-name {
    font-size: 2.5rem;
    color: #2d3748;
    margin-bottom: 1rem;
}

.preview-contact-info, .preview-social-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    color: #4a5568;
    margin-bottom: 0.5rem;
}

.preview-contact-info span, .preview-social-links span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.preview-section-title {
    color: #2d3748 !important;
    font-size: 1.25rem !important;
    margin-bottom: 0.5rem !important;
    text-transform: uppercase !important;
    letter-spacing: 0.05em !important;
}

.preview-experience-item, .preview-education-item {
    margin-bottom: 1.5rem;
}

.preview-experience-header, .preview-education-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.preview-experience-title h4, .preview-education-title h4 {
    color: #2d3748;
    margin: 0 0 0.5rem 0;
}

.preview-company, .preview-degree {
    font-style: italic;
}

.preview-experience-period, .preview-education-period {
    text-align: right;
}

.preview-location, .preview-gpa {
    display: block;
    margin-top: 0.25rem;
}

.preview-skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
}

.preview-skill-tag {
    background: #ab1fbd20;
    color: #bd05fa;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
}

.preview-education-details {
    color: #4a5568;
    margin-top: 0.5rem;
}

.preview-responsibilities {
    list-style: none; /* Remove default bullets */
    padding-left: 0; /* Remove padding */
    margin-bottom: 0.5rem;
}

.preview-responsibilities li {
    position: relative; /* Positioning for pseudo-element */
    padding-left: 15px; /* Space for the bullet */
}

.preview-responsibilities li::before {
    content: '•'; /* Custom bullet */
    position: absolute; /* Position it absolutely */
    left: 1px; /* Align to the left */
    font-size: 15px; /* Adjust the size of the bullet */
    color: rgb(0, 0, 0); /* Bullet color */
}