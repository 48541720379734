
  
  .account-modal-content {
    position: relative;
    background: var(--screen-background-color);
    padding: 1.5rem;
    border-radius: 1rem;
    height: auto;
    width: 100%;
    color: white;
    /* margin: auto; */
  }
  
  .account-close-modal {
    position: absolute; /* Absolute positioning inside the modal-content */
    top: 0px; /* Adjust distance from top of the header */
    right: 0px; /* Align button to the right */
    float: right;
    background: transparent;
    border: none;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    z-index: 0; /* Ensure it's above other elements, including header */
  }
  
  .profile-header{
    display: flex;
    justify-content: space-between;
    background: rgba(30, 30, 63, 0.5);
    /* height: 25%; */
  }  
  /* Profile Header */
  .profile-section {
    display: flex;
    align-items: center;
    gap: 35px;
    padding: 20px;
    border-radius: 12px;
    /* height: 25vh; */
  }
  
  .user-profile-image{
    position: relative;
    width: 120px;  /* Fixed width */
    height: 120px; /* Fixed height */
    flex-shrink: 0; /* Prevent shrinking */
    border: 3px solid #BE05FA;
    border-radius: 50%;
  }

  .user-profile-image img {
    width: 114px;
    height: 114px;
    top: 0;
    left: 0;
    /* border-radius: 50%;
    border: 3px solid #BE05FA; */
    position: absolute;
  }

  .default-user-icon {
    width: 75px;
    height: 55px;
    top: 25px;
    left: 20px;
    position: absolute;
    /* border-radius: 50%;
    border: 3px solid #BE05FA; */
}


  .loading-spinner-container {
    position: absolute;
    width: 116px;
    height: 116px;
    top: 0;
    left: 0;
    /* border-radius: 50%;
    border: 3px solid #BE05FA; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: rgba(30, 30, 63, 0.5); */
}

.img-loading-spinner {
    font-size: 40px;
    color: #BE05FA;
}

.profile-image {
  border-radius: 50%;
  /* border: 3px solid #BE05FA; */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.profile-image.loading {
  opacity: 0;
}

.user-profile-image.uploading {
  pointer-events: none;
  opacity: 0.8;
}


  .camera-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: var(--screen-background-color);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    z-index: 2;
}

.camera-icon:hover {
  transform: translateY(-2px);
}

.delete-icon{
  position: absolute;
  bottom: 18px;
  left: 50px;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 2;
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.delete-icon i{
  background-color: var(--screen-background-color);
  color: white;
}

.delete-icon:hover{
  transform: translateY(-2px);
}
  
.myaccount-info{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}
  
  .profile-info h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .profile-info .highlight {
    color: #BE05FA;
  }
  
  .profile-info p {
    margin: 5px 0;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .profile-options{
    display: flex;
    gap: 15px;
    margin: 10px;
    padding: 10px;
    color: #BE05FA;
    font-size: 20px;
  }
  .profile-options i:hover{
    transform: translateY(-2px);
  }

  .sign-out-icon{
    color: red;
  }
  .skills-info-header{
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensures vertical alignment */
  }
  .skills-title {
    display: flex;
    align-items: center; /* Keeps h3 and error inline */
    gap: 10px; /* Adds spacing between h3 and error */
}

  .skills-save-button{
    background-color:var(--buttons-background-color) ;
    margin-bottom: 10px;
    padding: 5px 5px;
    font-size: 14px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
  }

  .user-skills{
    width:100%;
    padding:5px 10px;
    /* min-height: 50px; */
    height: 25%;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(190, 5, 250, 0.2);
    box-sizing: border-box;
    color: white;
    font-size: 15px;
    border-color: #BE05FA;
}
  
.user-skills:focus{
  outline: none;
  /* border-color: #BE05FA; */
  background: rgba(190, 5, 250, 0.08);
  box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
}

.user-skills-container{
  width:100%;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  min-height: 90px;
  max-height: 90px;
  overflow-y: auto;
  border: 2px solid rgba(190, 5, 250, 0.2);
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  padding: 5px;
}
.user-skill-button{
  background-color: #BE05FA1A;
  color: white;
  padding: 5px 5px;
  margin: 5px;
  gap: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  align-items: center;
  height: fit-content;
}

.user-skill-text {
  margin-right: 4px; /* Additional spacing if needed */
  
}

.user-remove-skill {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}
  /* Info Boxes */
  .user-profile-details {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    height: 30vh;
  }
  
  .info-box {
    background:rgba(30, 30, 63, 0.5);
    padding: 20px;
    border-radius: 12px;
    flex: 1;
    overflow: hidden;
  }
  .skill-content-box{
    /* height: 80%; */
    overflow: hidden;

  }
  
  .info-box h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
  }
  
  .info-box i{
color: #BE05FA;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px ;
    margin-top: 10px;
    overflow-y: auto;
    max-height: 90px;
  }
  
  .tags span {
    background: #bd05fa2f;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 14px;
    color: white;
    /* height: fit-content; */
  }
  
  .active-subscriptions{
    margin-top: 1rem;
    height: 30vh;
    width: 100%;
  }

  .subscription-title{
    font-size: 1.2rem;
    margin-top: 0.1rem;
    display: flex;
    justify-content: space-between;
    /* flex-direction: row; */
    gap: 1rem;
    .purchase-plan{
      font-size: 1.4rem;
      padding: 0.3rem 1.2rem;
      transition: all 0.5s ease;
      display: flex;
      align-items: center;
      background: linear-gradient(160deg, #dd1ac7, var(--buttons-background-color));
      /* background-clip: text; */
      /* color: transparent; */
      gap:0.4rem;
      /* border: 1px solid var(--buttons-background-color); */
      margin-right: 1rem;
      margin-bottom: 0.2rem;
    }
    .purchase-plan:active{
      transform: scale(0.9);
    }
    .purchase-plan:hover{
      transform: scale(1.04);
    }
  }
  .subscription-section{
    padding: 0.5rem;
    max-width: 100% !important;
    display: flex;
    flex-direction: row;
    gap:1rem;
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 100%;
    margin-bottom: 0.5rem;
    background: rgba(30, 30, 63, 0.5);
    border-radius: 15px;
  }

  .subscription-section::-webkit-scrollbar {
    height: 0.5rem;
  }


  .subscription-box {
    background: var(--screen-background-color);
    min-width:fit-content;
    min-height: fit-content;
    padding:0.5rem 0.5rem;
    border-radius: 1rem;
    display: flex; 
    flex-direction: column;
  }
  .plan-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.5rem;
    font-size: 1.2rem;
    .session-name{
      color: var(--buttons-background-color);
      font-weight: 600;
    }
    .plan-type{
      display: flex;
      align-items: center;
      gap:0.3rem;
      flex-wrap: wrap;
    }
    .active{
      padding: 0 0.5rem;
      border: 1px solid green;
      border-radius: 1rem;
    }

  }

  .plan-details{
    display: flex;
    flex-direction: row;
    gap:1.5rem;
    flex-wrap: wrap;
    overflow-y: auto;
  }
  
  
  .plan-name {
    color: #BE05FA;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .plan-price {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .status.active {
    color: #4CAF50;
  }
  
  .billing {
    font-size: 14px;
    margin-top: 5px;
  }
  
  
  .plan-features ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .plan-features li {
    margin-bottom: 5px;
    font-size: 14px;
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .account-skill-error {
    color: red;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap; /* Prevents text from wrapping */
}
  
@media screen and (max-width: 714px) {
  .profile-details-section{
    overflow-y: auto;
    height: 50%;
  }
  .user-profile-details{
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }
  .user-skills{
    height: 40%;
  }

  .info-box h3{
    font-size: 12px;
  }
  .tags span{
    font-size: 10px;
  }
  .profile-section{
    gap: 10px;
  }
.user-profile-image{
  height: 105px;
  width: 105px;
}
  
.user-profile-image img{
  height: 100px;
  width: 100px;
}
.delete-icon{
  left: 45px;
}
.profile-options{
  margin: 0px;
}
}

@media screen and (max-width: 425px) {
.profile-options{
  margin: -4px;
  justify-content: flex-end;
  /* flex-direction: column; */
}

.user-profile-details{
  flex-direction: column;
  height: auto;
  overflow-y: auto;
}

.profile-header{
  /* height: 22%; */
  flex-direction: column-reverse;
}

.profile-details-section{
  overflow-y: auto;
  height: 55% !important;
}

.user-profile-image{
  height: 80px;
  width: 80px;
}
  
.user-profile-image img{
  height: 75px;
  width: 75px;
}
.delete-icon{
  left: 35px;
}
.profile-section {
  padding: 12px;
  gap: 28px;
}
}

@media screen and (max-width: 1496px) {
  
}