.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .jd-modal-content {
    background: #ffffff;
    padding: 30px!important;
    border-radius: 8px;
    width: 400px!important;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .jd-close-button {
    color: #161515 !important;
    position: absolute;
    font-size: 20px;
    margin: 10px;
    border: none;
    cursor: pointer;
    right: 5px;
    top: 3px;
    background: transparent;
  }
  
  .jd-modal-content h2 {
    margin: 0 0 10px;
    font-size: 20px;
    color: #333;
  }
  
  .jd-modal-content label {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom:5px
  }
  
  .jd-modal-content input{
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    color: #0e0e0e;
    background-color: #f8f9fa;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .jd-modal-content textarea{
    width: 95%;
    min-height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    color: #0e0e0e;
    background-color: #f8f9fa;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  .jd-modal-content select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    color: #0e0e0e;
    background-color: #f8f9fa;
    cursor: pointer;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .jd-modal-content input:focus
  {
    border: 1px solid #786bce !important;
    box-shadow: 0 0 5px rgba(120, 107, 206, 0.5);
  }

  .jd-modal-content textarea:focus, .jd-modal-content select:focus{
    outline: none;
    border-color: #786bce;
    box-shadow: 0 0 5px rgba(120, 107, 206, 0.5);
  }
  
  .job-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .header-button{
      padding:10px 15px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3 ease;
      margin-bottom: 5px;
    }
      .save{
        background: linear-gradient(to left top, #ffffff, #dddce1); 
        border: 0.1px solid #786bce !important;
        color: #2d2943 !important;
      }
      .header-button:hover{
      transform: scale(1.1);
      }
  }

  .description{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .ai-generate-button {
      background: transparent; 
      border: none;
      color: #786bce;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 5px;
      transition: all 0.3 ease;
      display: flex;
    }
  
    .ai-generate-button:hover {
      transform: scale(1.1);
      
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: #888;
  }
  
  .jd-modal-content label span {
    color: #888;
    font-size: 12px;
    margin-left: 5px;
  }