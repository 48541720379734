.datacard-container {
    position: absolute;
    top: 45%; /* Center vertically */
    left: 30%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust positioning to true center */
    z-index: 100; /* Place above all background layers */
    display: flex;
    justify-content: center;
    align-items: center;

}




/* Shine Effect */
.datacard::before {

    position: absolute;
    content: "";
    background: linear-gradient(
            120deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%
    );
    top: -50%;
    left: -150%; /* Start far left */
    width: 300%;
    height: 300%;
    transform: rotate(-45deg);
    z-index: 1; /* Place above the card content */
    pointer-events: none; /* Avoid interaction */
    transition: left 1.5s ease-in-out; /* Smooth transition */
    opacity: 0; /* Initially invisible */
}

/* Hover Effect */
.datacard:hover::before {

    left: 150%; /* Move to the far right */
    opacity: 1; /* Make it visible */
    transition: left 1.5s ease-in-out, opacity 0.5s ease-in;
}

.datacard:hover {
    border: 1px solid #ffffff44;
    box-shadow: 0 7px 50px 10px #000000aa;
    transform: scale(1.015);
    filter: brightness(1.3);
}

/* Reset Shine Back on Hover End */
.datacard:not(:hover)::before {
    left: -150%; /* Return to the far left */
    opacity: 0; /* Fade out */
    transition: left 1.5s ease-in-out, opacity 0.5s ease-out;
}

/* =



/* Scoped for Loader */
html.loader-html {
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;
    margin: 0;
    padding: 0;
}

body.loader-body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-large-header {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

.loader-star-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.loader-star {
    position: absolute;
    background: #c004f4;
    border-radius: 50%;
    animation: star-move 5s linear infinite;
}

@keyframes star-move {
    0% {
        transform: translateY(30vh); /* Start at the bottom */
        opacity: 1; /* Fully visible */
    }
    30% {
        transform: translateY(0); /* Reach the top */
        opacity: 1; /* Fully visible */
    }
    100% {
        transform: translateY(0); /* Stay at the top */
        opacity: 1; /* Fade out */
    }
}


.loader-main-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #c004f4;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 50px;
    letter-spacing: 10px;
    z-index: 10;
}

.loader-main-title .thin {
    font-weight: 200;
}


.youtube-container {
    position: fixed;
    top: 20%;
    right: 1%;
    width: 600px;
    height: 400px;
    z-index: 2;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Add this to Loader.css */

/* Loader Progress Bar (3D Cube) */
/* Loader Progress Bar (3D Cube) */
/* Loader Progress Bar (3D Capsule) */
.loader-progress-bar {
    position: absolute;
    bottom: 20px; /* Position at the bottom */
    left: 50%;
    transform: translateX(-50%);
    width: 80%; /* Adjust bar width */
    height: 30px; /* Capsule height */
    background: white; /* Capsule background */
    border-radius: 15px; /* Round edges for capsule effect */
    overflow: hidden; /* Clip overflowing content */
    perspective: 1000px; /* Perspective for 3D effect */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.loader-progress-bar .progress {
    position: relative;
    width: 0%; /* Initial progress width */
    height: 100%;
    background: linear-gradient(
            to right,
            #ba05f5 10%,          /* Main liquid color starts slightly in */
            #ba05f5 80%,          /* Ensure most of the bar is purple */
            rgba(255, 255, 0, 1) 100% /* Yellow shiny head at the leading edge */
    );
    transform-style: preserve-3d;
    transition: width 0.5s ease; /* Smooth width animation */
    border-radius: 15px; /* Curve the top of the liquid */
    box-shadow: inset 0 -3px 6px rgba(0, 0, 0, 0.3); /* Inner shadow for depth */
}

.loader-progress-bar .progress-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: bold;
    color: black; /* High contrast color */
    z-index: 2; /* Ensure it's on top of everything */
    text-shadow: 0 1px 2px rgba(255, 255, 255, 0.8); /* Optional for better visibility */
    pointer-events: none; /* Ignore mouse events */
    background: rgba(255, 255, 255, 0.7); /* Optional: translucent background for contrast */
    padding: 2px 6px; /* Optional: small padding for better readability */
    border-radius: 5px; /* Optional: rounded edges */
}

/* Create the 3D cube effect with pseudo-elements */
.loader-progress-bar .progress::before,
.loader-progress-bar .progress::after {
    content: '';
    position: absolute;
    height: 100%;
    transform-origin: left;
    transform-style: preserve-3d;
}

/* Right face of the cube */
.loader-progress-bar .progress::before {
    width: 10px; /* Depth of the cube */
    background: rgba(186, 5, 245, 0.8); /* Side color */
    left: 100%; /* Positioned at the end of the bar */
    transform: rotateY(90deg) translateZ(-5px); /* Create the right face */
}

/* Top face of the cube */
.loader-progress-bar .progress::after {
    width: 100%; /* Same width as the progress bar */
    background: rgba(186, 5, 245, 0.8); /* Top color matching liquid */
    top: -10px; /* Positioned above the bar */
    transform: rotateX(90deg) translateZ(-5px); /* Create the top face */
}

/* Exit Button: fixed at bottom center */
.exit-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4; /* Make sure it's in front of other elements */
    background-color: #ff00ff; /* Example color */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    outline: none;
}

.exit-button:hover {
    background-color: #e400e4; /* Slightly darker on hover */
}