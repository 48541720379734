.resume-guru-page {
    background: #0E1428;
    height: calc(100vh - 80px);
    padding: 0;
    position: relative;
}

.resume-cards-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(220px, 1fr));
    gap: 24px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 40px;
    height: calc(100vh - 220px);
    overflow-y: auto;
}
/* Add smooth padding at the bottom when scrolling */
.resume-cards-container::after {
    content: '';
    display: block;
    height: 20px;
    width: 100%;
    grid-column: 1 / -1;
}

.resume-cards-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .resume-cards-container::-webkit-scrollbar-track {
    background: rgba(190, 5, 250, 0.5) rgba(10, 11, 20, 0.6);
    border-radius: 4px;
  }
  
  .resume-cards-container::-webkit-scrollbar-thumb {
    background: #BE05FA;
    border-radius: 4px;
  }

.resume-card {
    width: 100%;
    max-width: 240px;
    background: #0A0B14 !important;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid rgba(190, 5, 250, 0.15);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 340px; /* Fixed height */
    margin: 0 auto;
    transition: all 0.3s ease;
}



/* Top edge effect */
.resume-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(190, 5, 250, 0.5), transparent);
}

.resume-invalid-card{
    background: linear-gradient(to bottom right, var(--buttons-background-color), var(--screen-background-color)) !important;
    color: var(--text-primary);
    align-items: center;
    font-size: 14px;
    min-width: fit-content;
    min-height: fit-content;
}

.resume-user-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
    text-align: justify;
}

.user-icon {
    color: #BE05FA;
    font-size: 14px;
    margin-right: 4px;
}

.name {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    flex: 1;
}

.file-type {
    background: rgba(190, 5, 250, 0.1);
    color: #BE05FA;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    margin-left: auto;
    cursor: pointer;
}

.job-title {
    color: #BE05FA;
    font-size: 15px;
}

.show-resume-tour-btn{
    position: absolute;
    bottom: 0;
    left: 30px;
    border: 1px solid var(--buttons-background-color);
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.1rem 0.3rem;
    /* transform: translateY(100%); */
    border-radius: 10px;
    color: var(--text-primary);
    cursor: pointer;
    background-color: rgba(190, 5, 250, 0.5);
}
.show-resume-tour-btn:active{
    transform: scale(0.95);
}

.info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin: 5px 0;
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1;
}

.info-label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
}

.info-value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 13px;
}

.skills-section {
    /* margin-top: 8px; */
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.skills-label {
    color: rgba(255, 255, 255, 0.7);
    font-size: 10px;
    text-align: justify;
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.skill-tag {
    background: rgba(190, 5, 250, 0.1);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 11px;
}

.card-footer {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    padding: 15px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: #0A0B14;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.resume-id {
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
}
.resume-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
 
.actions {
    display: flex;
    gap: 50px;
}

.action-btn {
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.action-btn i {
    font-size: 14px;
    color: #BE05FA;
}

.action-btn:hover {
    background: rgba(190, 5, 250, 0.1);
    color: #BE05FA;
}

.action-btn:hover i {
    transform: scale(1.1);
}

/* Hover effects */
.resume-card:hover {
    transform: translateY(-5px) scale(1.02);
    border: 1px solid rgba(190, 5, 250, 0.8);
    box-shadow: 0 0 20px rgba(190, 5, 250, 0.2), 0 0 40px rgba(190, 5, 250, 0.1), inset 0 0 15px rgba(190, 5, 250, 0.1);
}

/* Media queries */
@media (max-width: 1140px) {
    .resume-cards-container {
        grid-template-columns: repeat(3, minmax(220px, 1fr));
    }
}

@media (max-width: 860px) {
    .resume-cards-container {
        grid-template-columns: repeat(2, minmax(220px, 1fr));
    }
}

@media (max-width: 580px) {
    .resume-cards-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Styles for Create and Upload cards
.create-card, .upload-card {
    background: linear-gradient(145deg, rgba(190, 5, 250, 0.05) 0%, rgba(10, 11, 20, 0.9) 100%) !important;
    border: 1px solid rgba(190, 5, 250, 0.2);
} */

.create-content, .upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    /* flex: 1; */
}

.create-description, .upload-description {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    margin-top: 8px;
    line-height: 1.4;
}

.create-btn, .upload-btn {
    width: auto;
    background: rgba(190, 5, 250, 0.1);
    color: #BE05FA;
    padding: 8px 24px;
    border-radius: 6px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
}

.create-btn:hover, .upload-btn:hover {
    background: rgba(190, 5, 250, 0.2);
    transform: translateY(-1px);
}

.create-card .user-icon, .upload-card .user-icon {
    font-size: 18px;
}

.feature-icon {
    font-size: 40px;
    color: #BE05FA;
    margin-bottom: 16px;
    opacity: 0.9;
    background: rgba(190, 5, 250, 0.1);
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(190, 5, 250, 0.2);
    transition: all 0.3s ease;
}

.create-card:hover .feature-icon,
.upload-card:hover .feature-icon {
    transform: scale(1.05);
    background: rgba(190, 5, 250, 0.15);
    border-color: rgba(190, 5, 250, 0.3);
}

.create-card .card-footer,
.upload-card .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
}

.create-btn i, .upload-btn i {
    font-size: 14px;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #0E1428;
    border-radius: 12px;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    border: 1px solid rgba(190, 5, 250, 0.2);
    box-shadow: 
        0 4px 20px rgba(190, 5, 250, 0.15),
        0 0 30px rgba(190, 5, 250, 0.1),
        inset 0 0 20px rgba(190, 5, 250, 0.05);
}

.modal-content::-webkit-scrollbar {
    width: 5px;
}

.modal-content::-webkit-scrollbar-track {
    background: transparent;
}

.modal-content::-webkit-scrollbar-thumb {
    background: rgba(190, 5, 250, 0.3);
    border-radius: 20px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background: rgba(190, 5, 250, 0.5);
}

.modal-header {
    padding: 20px;
    border-bottom: 1px solid rgba(190, 5, 250, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background: #0E1428;
    z-index: 1;
}

.modal-title {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
}

.modal-close {
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    transition: all 0.2s ease;
}

.modal-close:hover {
    color: #BE05FA;
}

.modal-body {
    padding: 20px;
}

.modal-footer {
    padding: 20px;
    border-top: 1px solid rgba(190, 5, 250, 0.1);
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    position: sticky;
    bottom: 0;
    background: #0E1428;
}

.modal-btn {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.modal-btn-secondary {
    background: transparent;
    border: 1px solid rgba(190, 5, 250, 0.3);
    color: rgba(255, 255, 255, 0.8);
}

.modal-btn-primary {
    background: #BE05FA;
    border: none;
    color: #FFFFFF;
}

.modal-btn:hover {
    transform: translateY(-1px);
}

.modal-btn-secondary:hover {
    border-color: #BE05FA;
    color: #BE05FA;
}

.modal-btn-primary:hover {
    background: #a004d9;
}

/* Form field styles */
.form-group {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 8px;
    font-size: 14px;
}

.form-input {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(190, 5, 250, 0.2);
    color: #FFFFFF;
    font-size: 14px;
    transition: all 0.2s ease;
}

.form-input:focus {
    outline: none;
    border-color: #BE05FA;
    background: rgba(190, 5, 250, 0.1);
}

.resume-id-badge {
    display: inline-flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    padding: 8px 16px;
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    border: 1.5px solid rgba(190, 5, 250, 0.3);
    box-shadow: 
        0 2px 10px rgba(190, 5, 250, 0.1),
        inset 0 0 20px rgba(190, 5, 250, 0.05);
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    gap: 10px
}

.resume-id-badge:hover {
    background: rgba(190, 5, 250, 0.1);
    border-color: rgba(190, 5, 250, 0.5);
    box-shadow: 
        0 4px 15px rgba(190, 5, 250, 0.2),
        inset 0 0 25px rgba(190, 5, 250, 0.1);
    transform: translateY(-2px);
}

.id-prefix {
    color: #BE05FA;
    font-weight: 600;
    font-size: 15px;
    margin-right: 4px;
    text-shadow: 0 0 10px rgba(190, 5, 250, 0.5);
}
.id-icon {
    color: #BE05FA; /* Your website's purple accent color */
    font-size: 14px;
    opacity: 0.9;
    text-shadow: 0 0 10px rgba(190, 5, 250, 0.5);
}

.id-number {
    background: white;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0.8px;
}

/* Large screens - 1450px */
@media screen and (max-width: 1500px) {
    .resume-cards-container {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        gap: 10px;
        padding: 16px;
    }

    .resume-card {
        height: 320px;
        padding: 16px;
    }

    .job-title {
        font-size: 14px;
    }

    .info-label {
        font-size: 11px;
    }

    .info-value {
        font-size: 12px;
    }

    .skill-tag {
        font-size: 10px;
        padding: 3px 7px;
    }

}

@media screen and (max-width: 1400px) {
    .resume-cards-container {
        grid-template-columns: repeat(3, minmax(190px, 1fr));
        gap: 18px;
    }

    .resume-card {
        height: 310px;
        padding: 14px;
    }

}

/* Medium-large screens - 1245px */
@media screen and (max-width: 1245px) {
    .resume-cards-container {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
        gap: 16px;
        padding: 14px;
    }

    .resume-card {
        height: 300px;
        padding: 12px;
    }

}

/* Tablet screens - 1024px */
@media screen and (max-width: 1024px) {
    .resume-cards-container {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        gap: 16px;
        padding: 12px;
    }

    .resume-card {
        height: 300px;
        padding: 10px;
    }

    .job-title {
        font-size: 13px;
    }

}

/* Medium tablet screens - 950px */
@media screen and (max-width: 950px) {
    .resume-cards-container {
        grid-template-columns: repeat(2, minmax(180px, 1fr));
        gap: 10px;
    }

    .resume-card {
        height: 295px;
        padding: 10px;
    }

}

@media screen and (max-width: 870px) {
    .resume-cards-container {
        grid-template-columns: repeat(2, minmax(160px, 1fr));
        gap: 10px;
    }

    .resume-card {
        height: 295px;
        padding: 10px;
    }

    .job-title {
        font-size: 12px;
    }

    .info-label {
        font-size: 10px;
    }

    .info-value {
        font-size: 11px;
    }

}

@media screen and (max-width: 785px) {
    .resume-cards-container {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
        gap: 10px;
    }

    .resume-card,
    .new-session-card {
        height: 280px;
        padding: 8px;
        display: flex;
        flex-direction: column;
    }

    .feature-icon {
        font-size: 35px;
        width: 70px;
        height: 70px;
        margin-bottom: 12px;
    }

    .job-title {
        font-size: 15px;
    }

    .info-label {
        font-size: 10px;
    }

    .info-value {
        font-size: 11px;
    }

    .skill-tag {
        font-size: 9px;
        padding: 2px 6px;
    }
}

/* Small tablet screens - 715px */
@media screen and (max-width: 715px) {
    .resume-cards-container {
        grid-template-columns: repeat(2, minmax(140px, 1fr));
        gap: 10px;
        padding: 10px;
    }

    .resume-card,
    .new-session-card {
        height: 270px;
        padding: 8px;
        display: flex;
        flex-direction: column;
    }



    .feature-icon {
        font-size: 32px;
        width: 65px;
        height: 65px;
        margin-bottom: 10px;
    }

    .job-title {
        font-size: 14px;
    }

    .info-label {
        font-size: 9px;
    }

    .info-value {
        font-size: 10px;
    }

    .skill-tag {
        font-size: 8px;
        padding: 2px 5px;
    }
}

/* Mobile screens - 530px */
@media screen and (max-width: 530px) {
    .resume-cards-container {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
        gap: 12px;
        padding: 10px;
        margin-top: -8px;
    }

    .hero-section {
        padding: 0;
        margin-bottom: 0;
    }

    .resume-card {
        height: 290px;
        max-width: 200px;
        padding: 8px;
        display: flex;
        flex-direction: column;
    }

    .new-session-card {
        max-height: 290px;
        max-width: 210px;
        margin: 0 auto;
        padding: 12px;
        display: flex;
        flex-direction: column;
    }
}

/* Even smaller screens */
@media screen and (max-width: 445px) {
    .resume-cards-container {
        grid-template-columns: 1fr;
        gap: 10px;
        padding: 8px;
        margin-top: -8px;
    }

    .hero-section {
        padding: 0;
        margin-bottom: 0;
    }

    .resume-card {
        height: 280px;
        max-width: 190px;
        padding: 8px;
    }

    .job-title {
        font-size: 11px;
    }

    .info-label {
        font-size: 9px;
    }

    .info-value {
        font-size: 10px;
    }

    .skill-tag {
        font-size: 8px;
        padding: 2px 5px;
    }
}

/* Smallest screens */
@media screen and (max-width: 320px) {
    .resume-cards-container {
        padding: 6px;
        gap: 8px;
        margin-top: -6px;
    }

    .hero-section {
        padding: 0;
        margin-bottom: 0;
    }

    .resume-card,
    .new-session-card {
        height: 240px;
        max-width: 150px;
        padding: 6px;
        display: flex;
        flex-direction: column;
    }

    .create-content, 
    .upload-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4px 0;
        flex: 1;
    }

    .create-card,
    .upload-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .create-card .card-footer,
    .upload-card .card-footer {
        padding: 4px 8px;
        margin-top: 2px;
        position: relative;
        bottom: 0;
    }

    .create-btn,
    .upload-btn {
        padding: 4px 8px;
        margin: 0;
    }

    .job-title {
        font-size: 10px;
    }

    .info-label {
        font-size: 8px;
    }

    .info-value {
        font-size: 9px;
    }

    .skill-tag {
        font-size: 7px;
        padding: 1px 4px;
    }
}