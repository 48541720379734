.datacard {
    user-select: none;
    max-width: 700px;
    max-height: 500px;
    margin: 2rem auto;
    border: 1px solid rgba(255, 255, 255, 0.2); /* Semi-transparent border */
    background: linear-gradient(
            135deg,
            rgba(162, 178, 217, 0.5) 0%,
            rgba(59, 48, 68, 0.5) 100%
    ); /* Semi-transparent gradient */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    backdrop-filter: blur(10px); /* Frosted glass effect */
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
    position: relative;

    opacity: 1; /* Makes the card semi-transparent */
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    padding: 2rem;

}

/* Custom Scrollbar Styling */
.datacard-content::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.datacard-content::-webkit-scrollbar-thumb {
    background: #a89ec9; /* Color of the scroll thumb */
    border-radius: 4px; /* Round edges */
}

.datacard-content::-webkit-scrollbar-thumb:hover {
    background: #ee83e5; /* Change color on hover */
}

.datacard-content::-webkit-scrollbar-track {
    background: transparent; /* Transparent track */
}

/* Prevent content from being cut off */
.datacard-content {
    position: relative;
    z-index: 1;
    opacity: 1;
    padding: 2rem;
    color: #ffffff;
    overflow: hidden; /* Ensure content stays within bounds */
    max-height: 500px; /* Set a smaller height for the scrollable content */
    overflow-y: auto; /* Allow vertical scrolling only within this area */
}

.datacard h1,
.datacard h2,
.datacard h3 {
    color: #ffffff;
}

.datacard ul {
    padding-left: 1.5rem;
}

.datacard ul li {
    margin-bottom: 0.5rem;
}

.datacard .cta button {
    margin-top: 1.5rem; /* Adjust spacing above button */

    background: #6c63ff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.datacard .cta button:hover {
    background: #4f4bcc;
}


/* Hover Effect */
.datacard:hover {

    transform: scale(1.05);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.6);
    filter: brightness(1.2);
}

/* Heading Styles */
.datacard h1 {
    color: #ee83e5;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.datacard h2 {
    color: #a89ec9;
    font-size: 1.5rem;
    margin-top: 1rem;
}

/* Section Heading Styles */
.datacard h3 {
    color: #ee83e5;
    font-size: 1.2rem;
    margin: 1.5rem 0 0.5rem;
}

/* Paragraph Text */
.datacard p {
    color: #ffffffcc;
    line-height: 1.6;
    margin: 0.5rem 0;
}

/* List Styling */
.datacard ul {
    padding-left: 1.5rem;
    margin: 0.5rem 0;
}

.datacard li {
    margin: 0.5rem 0;
    color: #a89ec9;
}

/* Call-to-Action Styling */
.datacard .cta {
    text-align: center;
    margin-bottom: 2rem; /* Reduce the top margin */
    padding: 0; /* Ensure no extra padding */

}

.datacard .cta button {
    background: #ee83e5;
    border: none;
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
}

.datacard .cta button:hover {
    background: #d472cb;
}