.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .jd-editable-modal-content{
    background: var(--screen-background-color); 
    padding: 30px;
    border-radius: 8px;
    /* width: 850px !important; */
    min-width: 100%;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 6px 2px var(--buttons-background-color);
    position: relative;
    display: flex;
    /* flex-direction: column; */
    /* overflow: hidden; */
    
    gap: 12px;
  }

  .jd-editable-modal-content h2{
      color: #ffffff !important;
      margin: 0;
  }
  
  .jd-close-icon {
    color: #ffffff;
    position: absolute;
    font-size: 25px;
    border: none;
    cursor: pointer;
    right:0px;
    top: 0px;
    background: transparent;
    transition: all 0.3s ease;
  }
  .jd-close-icon:hover{
  color: #BE05FA;
  transform: rotate(90deg);
  }

  .jd-editable-modal-details {
    display: flex;
    flex-direction: column;
    /* background: #bd05fa72; */
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px 5px var(--buttons-background-color); */
    color:white;
    overflow-y: auto; 
  }

  /* Scrollbar */
.jd-editable-modal-details::-webkit-scrollbar {
  width: 8px;
}

.ai-generate-error{
  color: red;
}

.jd-editable-modal-details::-webkit-scrollbar-track {
  background: rgba(190, 5, 250, 0.1);
  border-radius: 4px;
}

.jd-editable-modal-details::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
}
  
  .jd-editable-modal-field{
  display: flex;
  /* padding: 10px 15px; */
  border-radius: 8px;
  background: #bd05fa39; 
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  */
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
  }
  
  .jd-editable-modal-field textarea {
    min-height: 278px; 
    /* min-height: unset; */
    /* width: 97%; */
    overflow-y: auto; 
    padding: 10px;
    border-radius: 5px; 
    font-size: 18px; 
    line-height: 1.5; 
    scrollbar-width: thin;
    scrollbar-color: var(--buttons-background-color) transparent;
    color: white;
    background: none;
    border: 2px solid #ffffff1a
    !important;
  }

  .jd-editable-modal-field textarea:focus {
    outline: none;
    /* border: 2px solid var(--buttons-background-color) !important; */
  }

  .jd-save-btn-container {
    display: flex;
    justify-content: flex-end; /* Align button to the right */
  }
  
  .jd-save-btn{
    padding: 10px 20px;
    max-width: 100px;
    background: var(--buttons-background-color);
    border: 0.5px solid var(--buttons-background-color);
    color: #fff; 
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    float: right;
    margin-top: 10px;
  }

  .jd-save-btn:hover {
    transform: scale(1.05); 
  }

  .jd-header {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
  }

  .jd-header .header-item {
    display: flex;
    text-align: center; 
    gap:10px
  }
  .header-item select{
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color);
  }
  
  .header-label {
    display: block; 
    font-size: 15px;
    color: gray; 
    margin-top: 4px;
  }
  
  .jd-header h2 {
    margin: 0;
    font-size: 20px;
  }
  
  .side-by-side-fields {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;  */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Three columns for job_role, job_type, and company */
    gap: 20px;
    margin-bottom: 20px;
  }
  

  .field-container {
    flex: 1; 
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: white;
  }

  .job-label{
    display: flex;
    gap: 5px;
  }

  .error-border {
    border: 1px solid red;
}
.jd-error-message {
  color: red;
  font-size: 12px;
  font-weight: 600;
  display: block;
}
  
  .field-container h3{
    color: #ffffff !important;
    margin: 0 !important;
    font-size: 0.95rem !important;
    font-weight: 600 !important;
  }
  .field-container input {
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background: rgba(255, 255, 255, 0.03);
    border: 2px solid rgba(190, 5, 250, 0.2);
    flex: none;
  }


  .field-container input:focus{
    outline: none;
    border-color: #BE05FA;
    background: rgba(190, 5, 250, 0.08);
    box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
    /* border: 2px solid var(--buttons-background-color) !important; */
  }
  .input-with-dropdown {
    position: relative;
    display: flex;
    align-items: center;
}

.input-with-dropdown input {
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  margin: auto;
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(190, 5, 250, 0.2);
}

.input-with-dropdown input:focus {
outline: none;
border-color: #BE05FA;
background: rgba(190, 5, 250, 0.08);
box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
}

.input-with-dropdown .currency-dropdown {
    position: absolute;
    right: 10px; /* Properly position the dropdown */
    top: 50%;
    transform: translateY(-50%);
    width: 60px; /* Set a fixed width for the dropdown */
    background: transparent;
    border: none;
    color: white;
    font-size: 14px;
    padding: 0 5px; /* Add spacing inside the dropdown */
    cursor: pointer;
    outline: none;
    appearance: none; /* Removes default dropdown styling for a cleaner look */
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 5px center; /* Adjust the dropdown arrow's position */
    background-size: 20px; /* Resize the dropdown arrow */
}

.input-with-dropdown .currency-dropdown option {
    color: #ffffff; /* Ensure dropdown text is visible in the dropdown menu */
    background: rgba(255, 255, 255, 0.9); /* Dropdown background color for options */
}


.input-with-dropdown select:focus {
  outline: none !important;
  border: none !important;
  background: none !important;
  box-shadow: none!important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}
  /* Style for select dropdown */
.field-container select {
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(190, 5, 250, 0.2);
  height: 45px;
  appearance: none;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

/* Style for dropdown options */
.field-container select option {
  background-color: var(--screen-background-color) !important;
  color: white;
  padding: 12px;
}

/* Focus effect */
.field-container select:focus {
outline: none;
border-color: #BE05FA;
background: rgba(190, 5, 250, 0.08);
box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
}


  /* Add styles for the new text areas container */
.jd-sections-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.jd-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.jd-section textarea {
  min-height: 100px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(190, 5, 250, 0.2);
  resize: vertical;
}

.jd-section textarea:focus {
  outline: none;
  border-color: #BE05FA;
  background: rgba(190, 5, 250, 0.08);
  box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
}

.jd-section h3 {
  margin: 0 !important;
  color: white !important;
  margin-bottom: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-size: 0.95rem !important;
  font-weight: 600 !important;
}

.ai-generate-button {
  background-color: var(--screen-background-color)!important;
  border: 1px solid var(--buttons-background-color) !important;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  margin-left: 8px;
}
.ai-generate-button i {
  font-size: 12px;
  color: var(--buttons-background-color);
}
.ai-generate-button:hover {
  transform: scale(1.05);
}
.ai-generate-button:disabled{
  cursor: not-allowed;
}

  .job-description{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .ai-generate-button {
      background-color: var(--screen-background-color)!important;
      border: 1px solid var(--buttons-background-color) !important;
      padding: 4px 8px;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      margin-left: 8px;
    }
    .ai-generate-button i {
      font-size: 12px;
      color: var(--buttons-background-color);
    }
    .ai-generate-button:hover {
      transform: scale(1.05);
      background: rgba(190, 5, 250, 0.1);
    }
  }
  
  .createjd-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .jd-upload-btn{
    padding: 10px 20px;
    background: var(--buttons-background-color);
    border: 0.5px solid var(--buttons-background-color);
    color: #fff; 
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    }
    .jd-upload-btn:hover {
      transform: scale(1.05);
    }

  }

  /* Style for error message */
.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 2px;
}

/* Error Modal Container */
.error-modal {
  background-color: var(--screen-background-color); /* Dark overlay */
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
  z-index: 1000; /* Ensure it appears above other content */
}

/* Error Modal Content */
.error-modal-content {
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 6px 2px var(--buttons-background-color);
  text-align: center;
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center the content */
}

/* Close Button */
.error-modal-close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: var(--buttons-background-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.error-modal-close-btn:hover {
  transform: scale(1.05);
}

.error-modal-content i{
  margin-right: 10px;
  color: red;
}

.split-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px); /* Accounting for padding */
  gap: 20px;
}

.form-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
  padding-right: 20px;
  border-right: 1px solid var(--buttons-background-color);
}

.preview-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  white-space: normal; /* Allow wrapping of text */
  word-wrap: break-word; /* Break words if they are too long */
  overflow: hidden; /* Hide any overflow content */
  height: 100%; /* Make sure the height of the preview side is contained */
  max-height: 100%; /* Prevent any overflow in height */
}

.preview-header {
  display: flex;
    justify-content: space-between;
    align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--buttons-background-color);
}

.preview-content {
  flex: 1;
  padding: 20px;
  background: #ffffff;
  /* background: rgba(0, 0, 0, 0.2); */
  border-radius: 8px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--buttons-background-color) transparent;
  position: relative; 
  color: black;
  text-align: justify;
}
.preview-company-header {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 20px;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-align: center;
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.preview-company-header .current-date {
  position: absolute;
  right: 0;
  top: 30px;
  color: black;
  font-size: 14px;
}

.preview-item {
  padding-bottom: 15px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

.preview-item:last-child {
  border-bottom: none;
}

.preview-side::-webkit-scrollbar {
  width: 8px;
}

.preview-side::-webkit-scrollbar-thumb {
  background: var(--buttons-background-color);
  border-radius: 4px;
}

.jd-section-header {
  /* Add these to the existing styles */
  padding-bottom: 5px;
  border-bottom: 2px solid #2e2b2b;
  text-transform: uppercase;
}

.download-pdf-btn{
  padding: 10px 10px;
  background: var(--buttons-background-color);
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-pdf-btn i{
  margin-right: 10px;
}

.non-editable-field{
  padding: 8px;
  background: #bd05fa39; 
  border: 2px solid #ffffff1a
  !important;
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
  cursor:not-allowed;
}

.editable-field{
  padding: 8px;
  border-color: #BE05FA;
  background: rgba(190, 5, 250, 0.08);
  box-shadow: 0 0 0 3px rgba(190, 5, 250, 0.1);
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
}

/* Update preview-row styles */
.preview-row {
  display: flex;
  flex-direction: column; 
  gap: 15px; 
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(12, 7, 7, 0.1);
  padding-bottom: 15px;
  text-align: justify;
}

/* Update preview-item styles */
.preview-row .preview-item {
  display: flex;
  align-items: flex-start; /* Align to top */
  gap: 20px; /* Space between h3 and p */
  width: 100%; /* Full width */
}

.preview-row h3 {
  font-size: 16px !important;
  color: black !important;
  margin: 0 !important;
  min-width: 120px !important; /* Fixed width for labels */
  flex-shrink: 0 !important; /* Prevent shrinking */
}

.preview-item h3 {
  font-size: 16px !important;
  color: black !important;
  margin: 0 !important;
  min-width: 120px !important; /* Fixed width for labels */
  flex-shrink: 0 !important; /* Prevent shrinking */
}

.preview-row p {
  color: black !important;
  line-height: 1.5 !important;
  margin: 0 !important;
  flex: 1 !important; /* Take remaining space */
  white-space: pre-wrap !important;
  font-size: 14px !important;
}

.preview-item p {
  color: black !important;
  line-height: 1.5 !important;
  margin: 0 !important;
  flex: 1 !important; /* Take remaining space */
  white-space: pre-wrap !important;
  font-size: 14px !important;
}

.empty-preview-state {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.empty-preview-content {
  width: 100%;
}

.empty-preview-line {
  height: 16px;
  background-color: #cbc7c74a;
  margin-bottom: 16px;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.empty-preview-line:nth-child(1) {
  width: 75%;
}

/* .empty-preview-line:nth-child(3) {
  width: 90%;
}

.empty-preview-line:nth-child(4) {
  width: 80%;
}

.empty-preview-line:nth-child(5) {
  width: 88%;
}

.empty-preview-line:nth-child(6) {
  width: 82%;
}

.empty-preview-line:nth-child(7) {
  width: 86%;
} */

@keyframes pulse {
  0% {
      opacity: 0.6;
  }
  50% {
      opacity: 0.4;
  }
  100% {
      opacity: 0.6;
  }
}

.jd-loading-screen {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.jd-loading-content {
  display: flex;
  align-items: center;
  background:var(--screen-background-color);
  padding: 20px;
  border-radius: 10px;
  justify-content: space-between;
  width: 400px;
  text-align: justify;
}

.icon-container {
  height: 50px;
  width: 50px;
  background-color: #be05fa;
  border-radius: 50%;
  padding: 12px;
}

.text-container{
display: flex;
}

.jd-loading-data{
 display: flex;
 gap: 10px;
}

.icon {
  color: white;
}

.loading-title{
  color: #be05fa;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.dots {
  display: flex;
  gap: 5px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #be05fa;
  border-radius: 50%;
  animation: pulse 1.2s infinite alternate;
}

@keyframes pulse {
  0% { opacity: 1; }
  100% { opacity: 0.4; }
}

.loading-subtext {
  color: #ccc;
  font-size: 14px;
  margin:0
}
