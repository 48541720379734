.no-scroll {
  overflow: hidden !important;
  position: fixed; /* Optionally add this to fix the body scroll */
  width: 100%;
}


.modal {
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:10000;
    overflow: hidden;
  }
  
  .login-modal-content{
    max-width: 1000px;
    max-height:660px;
    padding:20px 20px;
    display: flex;
    flex-direction: row;
    background-color:rgb(43, 29, 77,1) ;
    border-radius: 8px;
    height: auto;
    overflow: scroll;
    gap:100px;
    flex-wrap: wrap;
    color:white;
    position: relative;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.3) !important;

    .close-button{
        background-color: transparent;
        color:white;
        position: absolute;
        right:1%;
        top:1%;
        font-size: 25px;
        padding: 0px 5px;
        transition: all 0.3s ease;
      }
      
.close-button:hover {
  color: #BE05FA;
  transform: rotate(90deg);
}
  }
  .left-modal-content{
    flex: 0 0 40%; 
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: space-evenly;
    /* gap: 20px;  */
    margin-left: 10px;
    .logo-image{
    max-width:50%; 
    height: auto;
    padding: 15px;
    }
    .avatar-image{
      max-width: 100%; 
      height:450px;
      margin-left: 50px;
      object-fit: cover; 
      border-radius: 40px;
    }
  }
  .right-modal-content{
    flex: 1; 
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 45px;
    flex-wrap: wrap;
    align-items: center;
    h1{
        font-size: 40px;
    }
  }
  .email-container{
    position: relative;
  }

  .email-icon {
    position: absolute;
    left: 3%; /* Position the icon inside the input field */
    top: 35%;
    /* transform: translate(-45%, -50%); */
    color: var(--screen-background-color); /* Icon color */
    background-color: #ffffff; /* Background color for circle */
    border-radius: 50%; /* Makes the icon background circular */
    padding: 8px; /* Adjust for icon size */
    pointer-events: none; /* Icon does not interfere with clicking */
    font-size: 14px; /* Adjust icon size */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* input{
    width:300px;
    padding:10px;
    margin:10px 30px 30px 0px;
    height:24px;
    border-radius: 20px;
    border: none!important;
    background:  rgba(238, 238, 238, 0.5) ;
  } */
  input[type="email"] {
    width:320px;
    height:auto;
    position: relative;
    padding: 15px 10px 15px 50px;
    margin: 20px 0px ;
    border-radius: 20px;
    color: white;
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color);
    box-sizing: border-box; /* Ensure padding does not affect width */
    /* transform: translateX(-5%); */
  }

  input[type="name"] {
    width:320px;
    height:auto;
    position: relative;
    padding: 15px 10px 15px 20px;
    margin:10px 0px ;
    border-radius: 20px;
    color: white;
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color);
    box-sizing: border-box;
    .error-fields{
      border: 2px solid red !important;
    }
  }
  input[type="emailname"] {
    width:320px;
    height:auto;
    position: relative;
    padding: 15px 10px 15px 50px;
    margin:10px 0px ;
    border-radius: 20px;
    color: white;
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color);
    box-sizing: border-box;
  }

  .phone-container{
    color: white;
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color);
    /* border:1px solid #ccc;
    background: rgba(238, 238, 238, 0.5)!important; */
    max-width:320px;
    height:45px;
    border-radius: 20px;
    margin: 10px 0px;
  }
  .phone-container.focused{
    /* outline: rgb(47, 184, 255)!important; 
    border: 2px solid rgb(47, 184, 255)!important;
    background: rgb(45, 41, 41,0.2)!important;;   */
    outline:none; 
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color); 
    color:white
  }
  .PhoneInput:focus,
  .PhoneInputInput:focus
  {
    background-color: transparent!important;
  }


  .PhoneInput{
    display: flex;
    flex-direction: row;
    width:310px;
    border:none;
    margin-left:12px;
  }
  .PhoneInputInput{
    height:41px;
    max-width: 261px;
    border-radius: 20px;
    border:none;
    color: white;
    background-color: transparent;
  }
  input::placeholder {
    line-height: 40px; 
    color: #ffffff;
    position: absolute;
    font-size: 14px;
  }
  input:focus {
    outline:none; 
    background: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color); 
    color:white
  }

  input[type="phone"]:focus {
    border: none!important;
  }
  


  input[type="email"]::placeholder {
    line-height: 40px; 
    color: #ffffff;
    position: absolute;
    font-size: 14px;
    /* left:16%; */
  }


  .sign-in {
    width:320px;
    padding: 12px 45px; 
    border-radius: 20px;
    border: none!important;
    cursor:pointer;
    background: var(--buttons-background-color);
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.login-spinner{
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}
.loading-text {
  font-size: 16px;
  font-weight: bold;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
/* .sign-in:hover{
  transform: translateY(-2px) scale(1.05); 
} */
hr{
    width:80%;
    margin: 20px auto;
}

.login-with-google-btn {
    width: 220px;  /* Match width with other buttons */
    margin: 10px auto;
    transition: background-color .3s, box-shadow .3s;
    padding: 10px 16px 10px 42px;
    border-radius: 15px;
    border: 1px solid white;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: block;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    
}
  .login-tooltip{
    display: flex;
    gap:5px;
    align-items: center;
    font-size: 12px;
    color: rgb(231, 216, 216);
    margin: 5px 0 15px 7px;
    position: relative;
    width: 100%;
    transform: translateX(10%);
  }
  .warning{
    width:3.5%;
  }
  .error{
    color:  red!important;
  }
  .otp-header{
    max-width: 400px;
    text-align: center;
   display: flex;
   flex-direction: column;
    align-items: center;
    justify-content: center;
    text-wrap: wrap!important;
    word-wrap: break-word;
    margin:20px 20px 40px 20px; 
  }
  .details-box{
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    .confirm{
        position:absolute;

    }
  }
  .otp-icon {
    font-size: 50px;
    margin-left: -20px;
    color: white; /* Set icon color */
    background-color: #2b1d4d; /* Background color for the circle */
    border-radius: 50%; /* Make it circular */
    padding: 20px; /* Add padding for spacing */
    display: inline-flex; /* Center the icon inside the circle */
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(169, 167, 172, 0.6), /* Inner glow */
                0 0 20px rgba(125, 117, 142, 0.4), /* Outer glow */
                0 0 30px rgba(127, 120, 145, 0.2); /* Larger, softer glow */
  }
  /* .change-email{
    background-color: transparent;
    border-radius: 10px;
    padding:3px 5px;
    color:white;
    margin-left: 10px;
    cursor: pointer;
    font-size: 12px;
    border:1px solid white;
  } */
  .otp-field{
    font-size: 24px;
    font-weight: 1000;
    color:white;
    background-color: var(--screen-background-color);
    border: 2px solid var(--buttons-background-color);
  }

 .otp-error-border{
      border-color: red !important;
  }
  .confirm{
    margin-top:15px;
    width:100%;
  }

.resend-code{
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--buttons-background-color);
  margin-right: 0.5rem;
}

.resend-code:hover{
  text-decoration: underline;
  cursor: pointer;
  color: var(--buttons-background-color);
}

.otp-btn{
  width:250px;
  border-radius: 20px;
  padding:15px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin: 20px 60px;
  border-radius: 50px;
  background-color: var(--buttons-background-color);
  cursor: pointer; 
  border: 1px solid var(--buttons-background-color)!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .otp-btn:hover{
  transform: translateY(-2px) scale(1.05); 
} */

.otp-error {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}

.submit-btn{
  padding: 12px 45px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  top:20px;
  border-radius: 50px;
  background-color: var(--buttons-background-color);
  cursor: pointer; 
  border: 1px solid var(--buttons-background-color)!important
}

.submit-btn:disabled{
  background-color: rgb(195, 191, 191);
  border: 1px solid  rgb(195, 191, 191) !important;
}
/* .otp-btn:hover{
  transform: translateY(-2px) scale(1.05); 
} */

.otp-error {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 1000px var(--screen-background-color) inset !important; /* Set background color */
    -webkit-text-fill-color: white !important; /* Set text color */
    transition: background-color 5000s ease-in-out 0s; /* Delay background reset */
}


.error-fields {
  border: 2px solid red !important;
}

.register-error-message{
color: red;
line-height: 1.2; /* Reduces space between lines */

}

/* .question-fields {
  width:450px;
  height:auto;
  position: relative;
  padding: 15px 10px 15px 30px;
  margin:2px 0px ;
  border-radius: 20px;
  color: white;
  background: var(--screen-background-color);
  border: 2px solid var(--buttons-background-color);
  box-sizing: border-box;
}  */

.label-text{
  color: white;
  margin: 5px;
}

/* 
.tag-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 5px;
  border: 2px solid var(--buttons-background-color);
  border-radius: 20px;
  background: var(--screen-background-color);
}

.tag {
  background-color: var(--buttons-background-color);
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.tag .close-btn {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
}

.question-fields {
  flex-grow: 1;
  border: none;
  outline: none;
  background: transparent;
  color: white;
  font-size: 14px;
  padding: 5px;
  margin: 2px 0;
}

.question-fields:focus{
  border: none;
}

.extradetails-form {
  max-height: 350px; 
  overflow-y: auto; 
  scrollbar-width: thin; 
  scrollbar-color: var(--buttons-background-color) transparent;
} */

/* Form container */
/* .extradetails-form {
  max-height: 350px;
  overflow-y: auto;

  padding: 20px;

} */

.tag-input-container::-webkit-scrollbar {
  width: 8px;
}

.tag-input-container::-webkit-scrollbar-track {
  background: rgba(27, 13, 32, 0.5) rgba(10, 11, 20, 0.6);
  border-radius: 12px;
  margin: 2px; 
}

.tag-input-container::-webkit-scrollbar-thumb {
  background: #b306ec;
  border-radius: 12px;
    margin: 2px; /* Optional: Add spacing between scrollbar and edges */
    border: 2px solid transparent;

}

.tag-input-container.scrollbar-space {
  padding-right: calc(10px + 8px); /* Account for scrollbar width */
}

/* Form field */
.form-field {
  margin-bottom: 20px;
}

/* Form label */
/* .form-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 8px;
} */

.additional-form-label {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 8px;
}

/* Icon styling */
.label-icon {
  color: var(--buttons-background-color); /* Match icon color to your theme */
  font-size: 16px; /* Adjust icon size */
}


.tag-input-wrapper{
  border-radius: 12px; /* Match the border radius */
  overflow: hidden; /* Clip the content inside, including the scrollbar */
  border: 2px solid var(--buttons-background-color);
  background: var(--screen-background-color);
  transition: border-color 0.3s ease;
}
/* Tag input container */
.tag-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 10px;
  width: 100%;
  min-height: 60px;
  max-height: 90px;
  overflow-y: auto;
  box-sizing: border-box; /* Padding is included within the dimensions */
  scrollbar-gutter: stable both-edges; /* Keeps space for the scrollbar */
}



.tag-input-container.error-fields {
  border-color: #ff4d4f; /* Red border for error state */
}

/* Tag */
.tag {
  background-color: var(--buttons-background-color);
  color: white;
  border-radius: 15px;
  padding: 6px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.tag .tag-text {
  flex-grow: 1;
}

.tag .close-btn {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: white;
  transition: opacity 0.2s ease;
}

.tag .close-btn:hover {
  opacity: 0.8;
}

/* Tag input */
.tag-input {
  flex-grow: 1;
  border: none;
  outline: none;
  background: transparent;
  color: var(--text-color);
  font-size: 14px;
  padding: 6px;
  margin: 2px 0;
}

.tag-input::placeholder {
  color: #a0a0a0;
}

.tag-input:focus {
  border: none;
  box-shadow: none;
}

/* Error message */
.register-error-message {
  color: #ff4d4f;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
}

.next-btn{
  padding: 12px 45px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-top: 20px;

  border-radius: 50px;
  background-color: var(--buttons-background-color);
  cursor: pointer; 
  border: 1px solid var(--buttons-background-color)!important
}

 .login-with-microsoft-btn {
    width: 220px;  /* Match width with other buttons */
    margin: 10px auto;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    color: #000;
    border: 1px solid #d6d6d6;
    cursor: pointer;
    font-size: 14px;
}

.login-with-microsoft-btn img {
  width: 20px;
  margin-right: 30px;
} 

.highlighted {
  border: 2px solid #4caf50; /* Green highlight */
  background-color: #e8f5e9; /* Light green background */
}

/* Media Queries */

/* Large screens - 1024px */
@media screen and (max-width: 1024px) {
  .login-modal-content {
    max-width: 900px;
    max-height: 90vh;
    gap: 10px;
  }

  .left-modal-content .avatar-image {
    height: 350px;
    margin-left: 30px;
  }
}

/* Medium-large screens - 910px */
@media screen and (max-width: 910px) {
  .login-modal-content {
    max-width: 800px;
    gap: 40px;
    padding: 15px;
  }

  .left-modal-content {
    flex: 1 1;
  }

  .left-modal-content .avatar-image {
    height: 350px;
    margin-left: 20px;
  }

  .right-modal-content h1 {
    font-size: 35px;
  }
}

/* Tablets - 835px */
@media screen and (max-width: 835px) {
  .login-modal-content {
    max-width: 80vw;
    gap: 20px;
    flex-direction: row;
    max-height: 80vh;
    overflow: scroll;
    align-items: center;
  }

  .left-modal-content {
    flex: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 10px;
  }

  .left-modal-content .logo-image {
    max-width: 150px;
    transform: none;
    margin-bottom: 10px;
  }

  .left-modal-content .avatar-image {
    max-height: 150px;
    max-width: 200px;
    transform: none;
    margin: 0 auto;
  }

  .right-modal-content {
    width: 100%;
    margin: 10px;
    align-items: center;
    text-align: center;
  }

  .right-modal-content h1 {
    font-size: 24px;
  }

  .email-container {
    width: 100%;
    max-width: 320px;
    transform: translateX(5%);
  }

  .sign-in {
    width: 320px;
    max-width: 100%;
    transform: translateX(5%);
    margin: 10px auto;
  }

  .login-tooltip{
    transform: translateX(25%);
  }

}

/* Small tablets - 715px */
@media screen and (max-width: 715px) {
  .login-modal-content {
    max-width: 85vw;
    padding: 15px;
    flex-direction: row;
  }

  .left-modal-content .logo-image {
    max-width: 120px;
  }

  .left-modal-content .avatar-image {
    max-height: 180px;
  }

  .phone-container {
    width: 100%;
    max-width: 280px;
  }

  .email-container{
    width: 100%;
    max-width: 320px;
    transform: translateX(5%);
  }

  .sign-in {
    max-width: 320px;
  }

  .email-icon {
    left: 15px;
  }
}

/* Large phones - 540px */
@media screen and (max-width: 540px) {
  .login-modal-content {
    max-width: 90vw;
    gap: 15px;
    padding: 15px;
  }

  .left-modal-content .logo-image {
    max-width: 140px;
  }

  .left-modal-content .avatar-image {
    max-height: 150px;
  }

  .right-modal-content h1 {
    font-size: 22px;
  }

  .tag-input-container {
    max-height: 120px;
  }

  .phone-container {
    max-width: 250px;
  }

  .sign-in {
    max-width: 250px;
  }

  .login-tooltip{
    transform: translateX(10%);
  }

}

/* Medium phones - 420px */
@media screen and (max-width: 420px) {
  .login-modal-content {
    max-width: 95vw;
    padding: 10px;
  }

  .left-modal-content .logo-image {
    max-width: 130px;
  }

  .left-modal-content .avatar-image {
    max-height: 130px;
  }


  .phone-container {
    max-width: 220px;
  }

  .sign-in {
    max-width: 220px;
  }

  .PhoneInput {
    width: 100%;
    max-width: 220px;
  }

  .login-with-google-btn {
    max-width: 180px;
    font-size: 13px;
  }
}

/* Small phones - 320px */
@media screen and (max-width: 385px) {
  .login-modal-content {
    max-width: 80vw;
    padding: 10px;
  }

  .left-modal-content .logo-image {
    max-width: 120px;
  }

  .left-modal-content .avatar-image {
    max-height: 150px;
  }

  .email-container {
    width: 100%;
    max-width: 180px;
  }

  input[type="email"],
  input[type="name"],
  input[type="emailname"] {
    width: 180px;
    padding: 12px 10px 12px 40px;
  }

  .phone-container {
    max-width: 180px;
  }

  .sign-in {
    max-width: 120px;
    font-size: 12px;
  }

  .PhoneInput {
    max-width: 180px;
  }

  .PhoneInputInput {
    max-width: 140px;
  }

  .right-modal-content h1 {
    font-size: 20px;
  }

  .login-with-google-btn {
    max-width: 160px;
    font-size: 12px;
  }
  .email-icon {
    visibility: hidden;
  }
  .login-tooltip{
    transform: translateX(9%);
  }
}

/* Add consistent container for all buttons */
.auth-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;  /* Consistent spacing between buttons */
}


@media screen and (max-width: 320px) {
  .login-tooltip{
    transform: translateX(5%);
  }
}
