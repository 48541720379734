:root{
  --LIGHT-TEXT: rgb(187, 182, 182);
}

.page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* font-family: Poppins!important; */
}

.row-1{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-family: Poppins!important;
  .row-1-container{
    display: flex;
    flex-direction: column;
    align-items: left;
    width:85%;
    color: var(--text-primary);
    h1{
      font-size: 2rem;
    }
    h2{
      width:fit-content;
      font-size: 1.8rem; 
      font-weight: 600; 
      background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%); 
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
}
.row-2{
    min-height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row-2-container{
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.row-3{
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-primary); 
}

.steps-title{
  /* border: 1px solid gold; */
  width: 82%;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

}
.progress-container {
    height: 100%;
    width: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-2rem);
  }
  
  .progress-bar {
    width: 100%;
    position: relative;
    height: 0.2rem;
    background-color: rgba(147, 51, 234, 0.2);
  }
  
  .progress-line {
    position: absolute;
    height: 100%;
    background-color: var(--buttons-background-color);
    transition: width 1s ease-in-out;
  }

  .progress-line::after {
    content: ">";
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  
  .step-container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0;
    opacity: 0;
    transition: all 0.5s ease-out;
  }
  
.step-container.active {
    opacity: 1;
    animation: dropIn 0.5s ease-out;
  } 

  .icon-container:hover{
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0), 
              0 0 10px rgba(255, 255, 255, 0.2),
              0 0 10px rgba(255, 255, 255, 0);
  }
  
  .icon-container {
    background-color: #1e1b4b !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--buttons-background-color);
    /* transform: scale(1); */

  }
  
  .icon {
    color: #9333ea;
  }
  
  .label-container {
    margin-top: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    min-width: fit-content;
    width: 140px;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    color: white;
    .step-description{
      font-size: 0.8rem;
      color: var(--LIGHT-TEXT);
      display: inline;
    }
  }
  
  @keyframes dropIn {
    0% {
      opacity: 0;
      transform: translateY(-70px) translateX(-50%);
    }
    80% {
        opacity: 0.5;
      transform: translateY(0.5%) translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  
  
  /* ::-webkit-scrollbar {
    display: none;
  }
  
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  } */

.feature-list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 50%;
  color: var(--text-primary);
  height: 100%;
  .feature-item {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    height: calc(100%/5);
    min-height: fit-content;
    width: 100%;
    .feature-logo{
      width: 2rem;
      height: 2rem;
      min-width: 1rem;
      border: none;
      color: var(--buttons-background-color);
    }
    .feature-content{
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 0rem ;
      .feature-title{
        font-size: 1rem;
        font-weight: 600;
        margin-bottom:0; 
      }
      .feature-description{
        width: 100%;
        font-size: 1rem;
        margin: 0rem;
        color: var(--text-secondary);
      }

    }
  }
}
.videoPlayer{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-end;
    padding: 1rem;
    color:white;
    gap:1rem;
    div{
      width: 100%;
      max-height: 80%;
      text-align: right;
      video{
        max-width: 90%;
        max-height: 100%;
      }
    }
}
.subscribe-btn{
    padding: 1rem 3rem;
    background-color: var(--buttons-background-color);
    border-radius: 4rem;
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.5s ease;
}
.subscribe-btn:hover{
    background-color: white;
    color: black;
}
