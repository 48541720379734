.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease;
}

.completed-modal-content {
  background: #0A0B14;
  border-radius: 20px;
  padding: 25px;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(190, 5, 250, 0.3);
  box-shadow: 0 0 20px rgba(190, 5, 250, 0.2);
  animation: slideIn 0.3s ease;
}

/* .complete-content-info{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
  width: 100%;
  max-width: 900px;
}

.complete-content-info::-webkit-scrollbar {
  width: 8px;
}

.complete-content-info::-webkit-scrollbar-track {
  background: rgba(190, 5, 250, 0.5) rgba(10, 11, 20, 0.6);
  border-radius: 4px;
}

.complete-content-info::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
} */

.completed-close-button {
  position: absolute;
  right: 0px;
  top: -5px;
  background: none;
  border: none;
  color:white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s ease;
}

.completed-close-button:hover {
  color: #BE05FA;
  transform: rotate(90deg);
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between icon and session count */
  gap: 12px;
  margin-bottom: 24px;
}

.session-count {
  flex-grow: 1; /* Allow this to take up available space */
  display: flex;
  align-items: center;
  justify-content: left; /* Center the text */
  text-align: center;
}

.header-icon {
  background: rgba(190, 5, 250, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #BE05FA;
}

.modal-header h2 {
  color: white;
  margin: 0;
  font-size: 20px;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 32px;
  padding: 0 10px;
  max-width: 900px;
  margin: 0 auto;
}

.stat-box {
  background: rgba(190, 5, 250, 0.05);
  border: 1px solid rgba(190, 5, 250, 0.1);
  border-radius: 12px;
  padding: 12px 16px;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stat-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(190, 5, 250, 0.15);
  border-color: rgba(190, 5, 250, 0.5);
}

.stat-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(190, 5, 250, 0.03), transparent);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.stat-box:hover::before {
  transform: translateX(100%);
}

.stat-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  height: 32px;
}

.stat-label {
  color: #7B7B8F;
  font-size: 13px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.stat-label-text {
  order: 1;
}

.stat-label i {
  color: #BE05FA;
  font-size: 20px;
  order: 2;
  transition: all 0.3s ease;
}

.stat-box:hover .stat-label i {
  transform: scale(1.2);
  color: #d442ff;
}

.stat-value {
  color: white;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}

.stat-improvement {
  color: #2ecc71;
  font-size: 12px;
  background: rgba(46, 204, 113, 0.1);
  padding: 2px 8px;
  border-radius: 4px;
  align-self: center;
  order: 2;
  height: fit-content;
  transition: all 0.3s ease;
}

.negative-score {
  color: red; /* Change text color to red for negative scores */
}

.stat-box:hover .stat-improvement {
  background: rgba(46, 204, 113, 0.2);
  transform: scale(1.05);
}

.timeline-section {
  margin-top: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
  padding: 15px;

}

.timeline-section::-webkit-scrollbar {
  width: 8px;
}

.timeline-section::-webkit-scrollbar-track {
  background: rgba(190, 5, 250, 0.5) rgba(10, 11, 20, 0.6);
  border-radius: 4px;
}

.timeline-section::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
}


.timeline-section h3 {
  color: white;
  margin-bottom: 16px;
  font-size: 18px;
}

.timeline-container {
  position: relative;
  transition: all 0.3s ease;
}

.timeline-container::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(190, 5, 250, 0.2);
  transition: all 0.3s ease;
}

.timeline-item {
  position: relative;
  padding-left: 45px;
  margin-bottom: 24px;
  transition: all 0.3s ease;
}

.timeline-content:hover {
  transform: translateX(5px);
}

.timeline-marker {
  position: absolute;
  left: 8px;
  top: 0;
  width: 16px;
  height: 16px;
  background: #BE05FA;
  border-radius: 50%;
  border: 3px solid #0A0B14;
}

.timeline-content:hover .timeline-marker {
  transform: scale(1.2);
  box-shadow: 0 0 15px rgba(190, 5, 250, 0.5);
}

.timeline-content {
  background: rgba(190, 5, 250, 0.05);
  border: 1px solid rgba(190, 5, 250, 0.1);
  border-radius: 12px;
  padding: 16px;
  transition: all 0.3s ease;
}

.timeline-item:hover .timeline-content {
  border-color: rgba(190, 5, 250, 0.5);
  box-shadow: 0 4px 15px rgba(190, 5, 250, 0.15);
  background: rgba(190, 5, 250, 0.08);
}

.session-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.session-header h4 {
  color: white;
  margin: 0;
  font-size: 16px;
}

.session-date {
  color: white;
  font-size: 12px;
}

.session-stats {
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
}

.session-stats span {
  color: #7B7B8F;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.report-conatiner{
  display: flex;
  gap: 10px;
}

.download-report-btn {
  background: rgba(190, 5, 250, 0.1);
  border: 1px solid rgba(190, 5, 250, 0.2);
  color: #BE05FA;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.download-report-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(190, 5, 250, 0.1), transparent);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.download-report-btn:hover {
  background: rgba(190, 5, 250, 0.2);
  border-color: rgba(190, 5, 250, 0.4);
  transform: translateY(-1px);
}

.download-report-btn:hover::before {
  transform: translateX(100%);
}

.download-report-btn:hover i {
  animation: bounce 0.5s ease infinite;
}

@media (max-width: 768px) {
  .stats-container {
    grid-template-columns: 1fr;
    padding: 0;
  }
  
  .completed-modal-content {
    width: 95%;
    padding: 16px;
    max-width: 95%;
  }

  .stat-box {
    min-height: 100px;
  }
}

.stat-box:last-child .stat-label {
  width: auto;
  position: relative;
}

.stat-box:last-child .stat-content {
  position: relative;
  width: 62px;
  text-align: center;
  margin-top: auto;
}

.stat-box:last-child .stat-value {
  font-size: 28px;
  line-height: 32px;
}

/* Animations */
@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Timeline line glow effect */
.timeline-item:hover + .timeline-container::before {
  background: linear-gradient(
    to bottom,
    rgba(190, 5, 250, 0.2),
    rgba(190, 5, 250, 0.4),
    rgba(190, 5, 250, 0.2)
  );
  box-shadow: 0 0 10px rgba(190, 5, 250, 0.3);
}

.view-modal-content{
  display: flex;
  position: relative;
  
}

.view-close-button{
  position: absolute;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  top: -2px; /* Adjust as needed for spacing */
  right: 8px;
  cursor: pointer;
  transition: all 0.3s ease
}

