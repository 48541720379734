.container{
 
}
.header-container{
    display: flex;
    align-items: center;
    width:100%;
    height:60px;
    background: linear-gradient(45deg,  #2b1d4d,#2b1d4d);
    .header-right-container{
        display: flex;
        align-items: center;
        gap:100px;
        justify-content: space-between;
        margin-left: 180px;
    }
    .services-section{
        display: flex;
        align-items: center;
        gap:60px;
    }
    .profile-section{
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        right: 2%;
        .profile-icon{
            padding-right: 10px;
 
        }
        .profile-dropdown-icon{
            padding-left: 10px;
            font-size: 12px;
        }
    }
 
    button{
        background-color: transparent;
        border: none;
        color:white;
        font-size: 16px;
        cursor: pointer;
        padding:10px;
        border-radius: 10px;
 
    }
    button:hover,
    button.active{
        background-color: #c7c1c170;
    }
    .logo{
        position: relative;
        width: 170px;
        left:20px;
        height: auto;
    }
}