body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent horizontal overflow */
  background: #1A1F36; /* Set a consistent background color */
  font-family: 'Poppins' !important;
}

.payment-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 2rem; /* Add padding around the main container */
  box-sizing: border-box;
  background: linear-gradient(135deg, #13151A 0%, #1A1F36 100%);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
  font-family: 'Poppins';
}

.freePlan-popup {
  background: #1A1F36;
  border-radius: 1rem;
  padding: 2rem;
  min-width: 30%;
  height: 25%;
  min-height: fit-content;
  color: var(--text-primary);
  /* min-width: 480px; */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.4s ease-out;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.popup-header h2 {
  margin: 0;
  font-size: 1.5rem;
}
.error-message{
  color: red;
}

.close-btn {
  background: none;
  border: none;
  color: #8792A2;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;
}

/* .close-btn:hover {
  color: #BE05FA;
} */

.popup-content {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  line-height: 1.5;
}

.popup-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.popup-buttons button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'Poppins';
}

.cancel-btn {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #8792A2;
}

.cancel-btn:hover {
  border-color: #8792A2;
  color: var(--text-primary);
}

.activate-btn {
  background: var(--buttons-background-color);
  border: none;
  color: white;
}

.activate-btn:hover {
  background: #a004d8;
  transform: translateY(-1px);
}

.payment-content {
  display: flex;
  width: 100%;
  max-width: 1200px; /* Limit the width for better readability */
  height: 90vh; /* Set a fixed height to enable scrolling */
  background: rgba(255, 255, 255, 0.05); /* Unified background for the entire content */
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.payment-details, .modern-payment-form {
  width: 50%;
  padding: 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto; /* Allow vertical scrolling within each container */
}

.paymentPage-logo{
  text-align: left;
  width: 50%;
  height: auto;
}

.paymentPage-logo img {
 min-width: 100%;
 max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 1rem;
}
.subscription-details{
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  width: 70%;
  background-color: #13151A;
  color: var(--text-primary) !important;
}

.subscription-details .plan-type {
  color: var(--text-primary);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.subscription-details .plan-detail {
  margin: 0.25rem 0;
  font-size: 1rem;
}

.subtotal, .tax, .total-due {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.promo-code {
  /* display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
  text-align: left; 
}

.promo-button {
  background: none;
  border: none;
  color: #BE05FA;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none; /* Removes the underline */
  transition: color 0.3s ease;
  padding: 0; /* Optional: Removes default button padding */
}

.promo-button:hover {
  color: #9B08C7;
}


.promo-link {
  color: #BE05FA;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.promo-link:hover {
  color: #9B08C7;
}

.promo-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.promo-input input {
  flex: 1;
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
}

.promo-input button {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: #BE05FA;
  color: white;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.promo-input button:hover {
  background: #9B08C7;
}

.promo-code input:focus, .promo-code button:focus {
  outline: none;
  border-color: #BE05FA;
  box-shadow: 0 0 0 2px rgba(190, 5, 250, 0.2);
}

.back-btn{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: left; 
  background-color: transparent;
  font-size: 1.1rem;
  border: 1px solid gray;
  transform: all 0.3s ease;
}
.back-btn:active{
  transform: scale(0.9);
}



.form-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
  overflow: visible;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 2rem;
}

.section-title i {
  color: #BE05FA;
  font-size: 1.25rem;
}

.section-title h2 {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-group {
  margin-bottom: 2rem;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #8792A2;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

.modern-input {
  width: 100%;
  padding: 14px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.modern-input:focus {
  outline: none;
  border-color: #BE05FA;
  box-shadow: 0 0 0 2px rgba(190, 5, 250, 0.2);
}

.payment-action {
  margin-top: 3rem;
}

.modern-pay-button {
  width: 100%;
  padding: 18px;
  background: linear-gradient(135deg, #BE05FA 0%, #9B08C7 100%);
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.modern-pay-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(190, 5, 250, 0.3);
}

.modern-pay-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  color: white;
}

.loading-component{
  width: 20rem;
  height: 5px;
  background-color: var(--buttons-background-color);
  position: relative;
  overflow: hidden;
  .loader{
    width:30%;
    height: 100%;
    background-color:white;
    position: absolute;
    top: 0;
    left:-30%;
    animation: moveLoader 2s infinite ease-in-out;
  }
}
@keyframes moveLoader {
  0% {
    left: -30%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -30%;
  }
}


.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

.express-checkout {
  margin: 2rem 0;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #8792A2;
  margin: 1.5rem 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.divider span {
  padding: 0 1rem;
  font-size: 0.9rem;
}

.error-message {
  margin-top: 1.5rem;
  padding: 1rem;
  background: rgba(255, 82, 82, 0.1);
  border: 1px solid rgba(255, 82, 82, 0.2);
  border-radius: 12px;
  color: #FF5252;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.95rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

/* Modern Scrollbar Styling */
.payment-details::-webkit-scrollbar,
.modern-payment-form::-webkit-scrollbar {
  width: 8px;
}

.payment-details::-webkit-scrollbar-track,
.modern-payment-form::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.payment-details::-webkit-scrollbar-thumb,
.modern-payment-form::-webkit-scrollbar-thumb {
  background: #BE05FA;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.payment-details::-webkit-scrollbar-thumb:hover,
.modern-payment-form::-webkit-scrollbar-thumb:hover {
  background: #9B08C7;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@media (max-width:600px) {
  .payment-content{
    flex-direction: column;
    gap:2rem;
  }
  .payment-details, .modern-payment-form{
    width: 100%;
  }

  
}