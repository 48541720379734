:root {
  --bg-dark: #0a0f1c;
  --bg-card: #1b2136;
  --star-color: #b026ff;
}


.testimonials-section{
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 5em 2em;
  padding-bottom:1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid gold; */
}


.testimonials-container{
  margin-bottom: 0;
  width:100%;
  height: 100%;
  /* border: 1px solid gold; */
}

.testimonials-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* border: 1px solid gold; */
  justify-content: start;
  gap:0.5rem;
  min-height: fit-content;
  height: 20%;
  margin: 0;
}

.testimonials-title {
  font-size: 2.4rem;
  color: var(--text-primary);
}

.testimonials-subtitle {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin: 0;
}

.testimonials-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: var(--buttons-background-color);
  font-size: 1.1em;
}

.stats-number {
  font-weight: 600;
}

.data-container{
  /* border: 1px solid gold; */
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding:2em;
}
.scroll-container {
  /* border: 1px solid gold; */
  width: 100%;
  min-height: 48%;
  overflow: hidden;
  position: relative;
  padding: 0.5rem 0;
  /* margin-bottom: 0.1em; */
}

.scroll-container::before,
.scroll-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  z-index: 10;
  pointer-events: none;
}

.scroll-container::before {
  left: 0;
  background: linear-gradient(to right, var(--screen-background-color) 0%, transparent 100%);
}

.scroll-container::after {
  right: 0;
  background: linear-gradient(to left, var(--screen-background-color) 0%, transparent 100%);
}

.scroll-content {
  height: 100%;
  display: flex;
  gap: 1rem;
  width: max-content;
}

.scroll-left .scroll-content {
  animation: leftScrollTestimonials 30s linear infinite;
}

.scroll-right .scroll-content {
  animation: rightScrollTestimonials 30s linear infinite;
}

.testimonial-card {
  background-color: var(--bg-card);
  color: var(--text-primary);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  /* max-width: 20%; */
  width: 400px;
  /* min-height: 100%; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 1rem;
}

.testimonial-card:hover {
  transform: translateY(-10px) scale(1.01);
  box-shadow: 
    -10px 0 20px rgba(199, 69, 173, 0.2),
    10px 0 20px rgba(236, 147, 223, 0.2),
    0 10px 20px rgba(226, 111, 232, 0.3);
  z-index: 10;
}

.testimonial-card-header {
  height: 20%;
  min-height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  /* margin-bottom: 1em; */
}

.testimonals-image {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1em;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  width: 100%;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.user-role {
  width: 100%;
  font-size: 0.75em;
  font-weight: 300;
  margin:0;
  padding: 0;
}

.user-company {
  width: 100%;
  font-size: 0.75em;
  margin: 0 ;
  padding: 0;
}

.rating {
  display: flex;
  gap: 0.25rem;
  margin: 0.75rem 0;
  height: 15%;
  /* border: 1px solid gold; */
}

.star {
  margin:0;
  /* width: 1.2rem; */
  /* height: 1.2rem; */
  height: 100%;
  aspect-ratio: 5/1;
  color: var(--buttons-background-color);
}

.star.filled {
  fill: var(--buttons-background-color);
}

.testimonial-content {
  flex: 1;
  /* border: 2px solid gold; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30%;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0;
}

@keyframes leftScrollTestimonials {
  0% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(-60%);
  }
}

@keyframes  rightScrollTestimonials {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.scroll-container:hover .scroll-content {
  animation-play-state: paused;
}
