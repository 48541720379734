/* src/Components/LandingPages/CareerGuruPage.css */
.career-guru-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: #f4f4f4; /* Light gray background */
    font-family: 'Arial', sans-serif;
    /* color: #fff; Set default text color to white */
}

.chat-header {
    background-color: #121522; /* Dark header */
    color: #BE05FA;
    padding: 20px;
    text-align: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.chat-header h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
}

.chat-header p {
    font-size: 1em;
    font-style: italic;
}

.chat-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #121522; /* Updated background color */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff; /* White text color for chat */
    display: flex;
    flex-direction: column;
}

.messages {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
}

.message {
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    max-width: 75%;
    font-size: 1em;
    word-break: break-word;
}

.user {
    background-color: rgba(190, 5, 250, 0.8); /* Semi-transparent purple */
    color: #fff; /* White text */
    align-self: flex-end;
}

.bot {
    background-color: rgba(76, 175, 80, 0.8); /* Semi-transparent green */
    color: #fff; /* White text */
    align-self: flex-start;
}

.input-container {
    display: flex;
    margin-top: 0;
    margin-bottom: 20px;
    width: calc(100% - 40px); /* Adjust width to fill almost the entire container */
    padding: 0 20px; /* Add padding to the container */
    box-sizing: border-box; /* Include padding in the element's total width */
}

input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 1em;
    background-color: #2a2a2a;
    color: #fff;
    width: 100%; /* Make input field take up the full width of its container */
    box-sizing: border-box; /* Include padding in the element's total width */
}

button {
    padding: 10px 15px;
    background-color: #BE05FA;
    color: #fff; /* White text on button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}