:root {
  --screen-background-color: #0e1428;
  --buttons-background-color:#BE05FA;
  --cardcontainer-background-color:#a86cda;
  --text-primary: #ffffff;
  --text-secondary: #a5acc0;
  --text-black: black;
  --HOVER-BG:rgba(58, 58, 57, 0.2);
}
/* 
body {
  overflow-y: auto !important;
} */

.app-wrapper {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  /* overflow-y: auto !important; */
  scroll-behavior: smooth;
  font-family: Poppins!important;
  background-color: var(--screen-background-color);
}

.app-wrapper > * {
  height: 100vh;
  display: flex; 
  width: 100%;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box; 
}

.home-header-container{
  position: fixed;
  height: 3.4rem;
  top: 0.5rem;
  z-index: 1000;
  text-align: center;
  width: 100%;
  background: transparent !important;
  .header{
    box-shadow: 0 3px 6px rgba(159, 154, 154, 0.3);
    width: 95%;
    height: 100%;
    background-color: var(--screen-background-color);
    color: var(--text-primary);
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
    padding: 0.5rem 1rem;
    padding-left: 0;
    transition: height 0.5s ease-in-out;
    .homepage-logo{
      width: 15%;
      padding: 0;
      max-width: fit-content;
      object-fit: contain;
      margin: 0;
      margin-left: 1rem;
      cursor: pointer;
      }
    .nav-menu{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex:1;
        width: 60%;
        font-size: 1rem;
        padding: 0rem 1rem;
        .main-menu{
          position: relative;
          cursor: pointer;
          border: none;
          color: var(--text-primary);
          /* font-weight:500; */
          font-size: 1.1rem;
          font-family: Poppins!important;
          text-decoration: none;
          background-color: transparent;
        }
        .main-menu::after {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          bottom: 0;
          left: 50%;
          background: var(--text-primary);
          transition: all 0.3s ease;
          transform: translateX(-50%);
        }
        .main-menu:hover::after {
          width: 80%;
        }
        .main-menu:hover{
          color: var(--buttons-background-color);
        }
        .dropdown {
          display: none;
          position: relative;
          font-size: 1rem;
          .custom-line {
            position: absolute;
            left:10%;
            border: none;       
            border-top: 0.5px solid rgba(255, 255, 255, 0.455);
            margin:0px;
          }
          .dropdown-toggle {
            width: fit-content;
            color:var(--text-primary);
            display: flex;
            align-items: center;
            .dropdown-icon {
              transition: all 0.3s ease;
              margin-left: 0.5rem;
            }
          }
        }
      }
      .dropdown-menu {
        display: none;
        position: absolute;
        background-color: var(--buttons-background-color); 
        width: fit-content;
        border-radius: 20px !important;
        overflow: hidden;
        z-index: 1000;
        padding-top: 0.5rem;
        transition: opacity 3s ease, transform 3s ease;
        text-align: left; 
        margin-top: 0px;
        button{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap:1rem;
          font-size: 1.2rem;
          cursor: pointer;
          color: white;
          border-radius: 1rem;
          max-width: 12rem;
          min-width: fit-content;
          margin: 0 1rem;
        }
        button:hover{
          background-color: var(--HOVER-BG);
        }
      }
      .dropdown:hover .dropdown-menu {
        display: block;
      }
      .dropdown:hover .dropdown-icon {
        transform: rotate(90deg);
      }
    .loginbutton{
      width: 15%;
      height: 100%;
      display:flex;
      align-items: center;
      justify-content: center;  
      .play{
        width: 1rem;
        margin-left: 0.5rem;
      }
    }
  }
}
      

.screen1 .content-2d {
  background-color: var(--screen-background-color);
}
.screen1 .content-3d {
  background-color: var(--screen-background-color);
}

.screen1 {
  height: 100vh;
  width: 100%;
  color: var(--text-primary);
  .content {
    height: 100%;
    width: 100%;
    margin-left: 0;
    display: flex;  
    position: relative;
    align-items: flex-end;

    .content-2d {
      /* border: 2px solid gold; */
      width: 40%;
      height: 90%;
      margin-bottom: 0;
      display: flex; 
      flex-direction: column;
      justify-content: center;

      .container {
        height: 100%;
        margin:0px 2rem;
      }

      .text-wrapper {
        /* border: 2px solid gold; */
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        
        .three-d-text1 {
          font-family: Poppins!important;
          font-size: 1.3rem;
          line-height: 2.4rem;
          .three-d-text {
            word-spacing: 0.1rem;
            text-align: center;
            padding-bottom: 1rem!important;
            span{
              background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%); 
              background-clip: text;
              color: transparent;
              font-size: 1.8rem;
            }
          }
          .success-text, .rocket-icon{
            background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%);
            background-clip: text;
            color: transparent;
            display: inline;
          }
          .rocket-icon {
            display: inline-block;
            color:  var(--buttons-background-color);
            transition: color 0.3s ease-in-out;
          }
          
          .rocket-icon:hover {
            color: var(--buttons-background-color);
          }
        }

        .video-card {
            .video-wrapper {
              align-items: center;
              object-fit: contain;
              /* border: 2px solid gold; */
              video {
                border-radius: 1rem; 
                width: 90%;
              }
            }
          }
        button{
          width:10rem;
          font-size: 1rem;
        }
        
        p {
          line-height: 1.2rem;
          margin: 0.4rem;
          font-size: 1rem; 
          text-align: left;
        }

      }
    }
    .content-3d {
      position: sticky;
      width: 60%;
      top: 0;
      left: 0;
      outline: none;
      height: 90%;
      width: 60%;
      pointer-events: all;
      .ThreedModel-shimmer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background: rgba(14, 20, 40, 0.9);
        backdrop-filter: blur(25px);
      }
      
      /* Loader animation */
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      
      .loader-icon {
        height: 3rem;
        width: 3rem;
        animation: spin 1.5s linear infinite;
        opacity: 0.5;
        color:var(--buttons-background-color);
      }
    }
  }

}

.screen2 {
  background-color: var(--screen-background-color);
}


.screen2  {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  background-color: var(--screen-background-color);
  position: relative;
    .screen2-content-2d {
      width: 45%;
      height: 90%;
      display: flex; 
      flex-direction: column;
      align-items: start;
      color:white;
      gap: 0.6rem;  
      position: relative;
    
      .profile { 
        height: 40%;
        display:flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width:100%;
        padding-bottom: 2rem;
      }
      .objectives-container{
        height: 60%;
        width: 100%;
      }
      .objectives{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 1rem;
        gap: 0rem 0.5rem;
        .objective-card{
          width: 48%;
          height: 48%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          background-color: #ffffffd6;
          /* background: linear-gradient(to bottom right, rgba(128, 0, 128, 0.5), rgba(128, 0, 128, 0.8)); */
          padding: 1rem;
          border-radius: 1rem;
          backdrop-filter: blur(5px);
          /* border: 2px solid rgba(128, 0, 128, 0.2); */
          transition: all 0.3s ease-in-out;
          color: black;
        }
        .objective-card:hover {
          /* border:2px solid rgba(128, 0, 128, 0.9); */
          background-color: var(--buttons-background-color);
          /* background: linear-gradient(to top left, rgba(193, 62, 193, 0.6),rgba(143, 15, 143, 0.9)); */
          transform: translateY(-0.3rem) scale(1.01);
        }
        .objective-icon {
          width: 2rem;
          height: 2rem;
          min-height: 2rem;
          color: var(--buttons-background-color); 
          margin-bottom: 0.3rem;
          transition: color 0.3s ease-in-out;
        }
        .objective-card:hover .objective-icon{
          color: black;
        }

        .objective-title {
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
          color: black;
        }
        .objective-description {
          font-size: 0.9rem;
          /* color: var(--text-secondary); */
          color: black;
          font-weight: 300;
        }
      }

      /* h2 {
        font-size: 1.5rem;
        margin-left: 3rem;
        margin-bottom: 1rem;
      }
      .course-card-container::-webkit-scrollbar{
        display: none;
      }
      .course-card-container:hover{
        transform: scale(1.01);
      }
      .course-card-container {
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        height: 40%;
        background-color: var(--buttons-background-color);
        color: var(--text-primary);
        border-radius: 1rem;
        box-shadow: 0 3px 10px rgba(61, 56, 56, 0.9);
        margin-left:3rem;
        margin-right: 2rem;
        transition: all 0.3s ease-in-out;
        position: relative;
        .number-list {
          display: flex;
          flex-direction: column;
          border-radius: 0.2em;
          background-color: #2d195c;
          height: 100%;
          width: 10%;
          .number-item{
            height: calc(100%/6);
            width: 100%;
            font-size: 1rem;
            background-color: transparent;
            color: var(--text-primary);
            font-weight: bold;
            border-radius: 0.5rem; 
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;
            padding: 0;
          }
        }
        
        .subclass-card-container{
          margin-left: 1rem;
          display: flex;
          flex-direction: column;
          overflow:auto;
          height: 100%;
          flex: 1;
          .overall-cards{
            min-height: 100%;
            max-height: 100%;
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            transition: opacity 0.5s ease, transform 0.5s ;
            overflow: hidden;
                
            .card-outline{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: left;
              height: 100%;
              width:60%;
              flex: 1;
              font-size: 0.8rem;
            }
            .sub-card {
              height: 80%;
              width: 30%;
              border-radius: 1rem;
              display: flex;
              flex-direction: column; 
              justify-content: space-evenly;
              align-items: center;
              text-align: center; 
              margin:0.9em 0 0.9em 0.9em;
              transition: transform 0.3s ease;
              cursor:pointer;
              margin-right: 1rem;
              h1{
                font-size: 0.8rem;
              }
  
              .icon2-button {
                background-color: rgba(255, 255, 255, 0.3);
                border: none; 
                border-radius: 50%; 
                padding: 0.4em; 
                cursor: pointer; 
                transition: background-color 0.3s, transform 0.2s;
                display: inline-flex; 
              }
            }
          }
        }
      } */
    }
    .content-3d {
      position: sticky;
      width: 50%;
      outline: none;
      height: 90%;
      pointer-events: all;
    }
}

.ChoosePlan-container{
  position: relative;
  .skip-subscription{
    display: flex;
    align-items: center;
    gap:0.5rem;
    top:2rem;
    right:2rem;
    position: absolute;
    transition: all 0.3s ease;
    font-size: 1.4rem;
    color: var(--text-primary);
  }
  .skip-subscription:active{
    transform: scale(0.9);
  }
  .skip-subscription:hover{
    background-color: transparent;
    border: 1px solid var(--buttons-background-color);
  }
}


/* for handling the portrait view in mobiles */
@media (max-width:600px) {
  .home-header-container{
    height: 5rem;
    .header{
      .homepage-logo{
        width: 20%;
      }
      .loginbutton{
        width: 20%;
      }
    }
  }
  .screen1{
    .content{
      .content-2d{
        width: 100%;
        .text-wrapper{
          .three-d-text1{
            line-height: 4rem; 
            font-size: 2rem;
            .three-d-text{
              span{
                font-size: 2.6rem;
              }
            }
          }
          p{
            font-size: 1.2rem;
            line-height: 2.2rem;
          }
          .video-card{
            .video-wrapper{
              video{
                width: 90%;
              }
            }
          }
        }
      }
      .content-3d{
        display: none;
      }
    }
  }
  .screen2{
    align-items: center;
    .screen2-content-2d{
      width: 100%;
      justify-content: start;
      gap:2rem;
      /* .course-card-container{
        height: 40%;
        .subclass-card-container{
          .overall-cards{
            .card-outline{
              font-size: 1.2rem!important;
            }
          }
        }
      } */
    }
    .content-3d{
      display: none;
    }
  }
  .screen3, .screen4{
    height: 100vh;
    width: 100vw;
    .page{
      justify-content: center;
      .row-1{
        align-items: flex-start;
        margin-bottom: 0rem;
        .row-1-container{
          padding:0 1em;
          width: 90%;
        }
      }
      .row-2{
        align-items: flex-start;
        .row-2-container{
          flex-direction: column-reverse;
          padding-left: 1em;
          .feature-list{
            width: 100%;
            height: 50%;
            .feature-item{
              /* .feature-logo{
              } */
              .feature-content{
                justify-content: center;
              }
              .feature-description{
                display: none;
              }
            }
          }
          .videoPlayer{
            width: 100%;
            height: 50%;
            align-items: flex-start;
            justify-content: center;
            div{
              text-align: left;
            }
            button{
              display: none;
            }
          }
        }
      }
      .row-3{
        .steps-title{
          font-size: 1.5rem;
        }
        .progress-container{
          height: 60%;
          transform: translateY(0.5rem);
          .icon-container{
            transform: scale(0.8) !important;
          }
          .label-container{
            margin-top: 1rem !important;
            font-size: 1.4rem !important;
            .step-description{
              font-size: 1rem !important;
            }
          }
        }
      }
    }
  }
  .screen8, .ChoosePlan-container{
    .pricing-container{
      justify-content: center;
      .section-title{
        width: 100%;
      }
      .saas-pricing-tabs{
        width: 100%;
        justify-content: center;
        .tabList{
          width: 98%;
        }
        .tabList-container{
          width: 90%;
          overflow-x: scroll;
          gap:10%;
          .tabData{
            height: 80%;
            min-width:60%;
            .content{
              .tip{
                font-size: 1rem;
              }
            }
            .choose-planBtn{
              width: 40%;
              padding: 0.2rem 1rem;
            }
          }
        }
        .tabList-container::-webkit-scrollbar{
          height: 0.5rem;
        }
      }
    }
  }
  .screen9{
    .testimonials-section{
      padding: 5em 0.5em;
      .testimonials-container{
        .data-container{
          display: flex;
          flex-direction: row;
          padding: 2em 0.5rem;
          .scroll-container{
            padding: 1em 0;
            .scroll-content{
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: start;

              .testimonial-card{
                width: 100%;
                aspect-ratio: 2/3;
                justify-content: start;
                .rating{
                  width: 60%;
                }
                .testimonial-content{
                  font-size: 1.2em;
                  margin-top: 0em;
                  
                }
              }
            }
          }
          .scroll-container::before {
            content: '';
            position: absolute;
            transform: translateY(-40%);
            top: 0;
            left: 0;
            right: 0;
            height: 10%;
            width: 100%;
            background: linear-gradient(to bottom, var(--screen-background-color) 0%, transparent 100%);
            pointer-events: none;
            z-index: 10;
          }
          .scroll-container::after {
            content: '';
            position: absolute;
            top:100%;
            transform: translateY(-50%);
            bottom: 0;
            left: 0;
            right: 0;
            height: 10%; /* Adjust fade size */
            width: 100%;
            background: linear-gradient(to top, var(--screen-background-color) 0%, transparent 100%);
            pointer-events: none;
            z-index: 10;
          }
          .scroll-left .scroll-content{
            animation: scrollDown 40s linear infinite;
          }
          .scroll-right .scroll-content{
            animation: scrollUp 40s linear infinite;
          }
        }
      }
    }
  }
  .chat-widget-container{
    width: 75% !important;
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-300%);
  }
}

@keyframes scrollDown {
  0% {
    transform: translateY(-300%);
  }
  100% {
    transform: translateY(0);
  }
}

.tabList-container::-webkit-scrollbar-track{
  background: rgba(30, 30, 63, 0.5);
}
.tabList-container::-webkit-scrollbar-thumb{
  background: rgba(190, 5, 250, 0.5);
  border-radius: 3px;
}
.tabList-container::-webkit-scrollbar-thumb:hover{
  background: var(--buttons-background-color);
}

/* for handling ipads portrait */
@media (max-width: 1024px) and (min-height:800px){
  .home-header-container{
    height: 5rem;
    .header{
      .homepage-logo{
        width: 20%;
      }
      .nav-menu{
        .dropdown{
          .dropdown-toggle{
            font-size: 1.4rem;
          }
          .dropdown-menu{
            button{
              font-size: 1.2rem;
            }
          }
        }
      }
      .loginbutton{
        width: 20%;
      }
    }
  }
  .screen1{
    .content{
      .content-2d{
        width: 100%;
        .text-wrapper{
          .three-d-text1{
            line-height: 4rem; 
            font-size: 2rem;
            .three-d-text{
              span{
                font-size: 2.6rem;
              }
            }
          }
          p{
            font-size: 1.3em;
            line-height: 3rem;
          }
          button{
            font-size: 1.4em;
            width: 16rem;
          }
          .screen1-scrolling-banner{
            height: 10rem;
            .screen1-scroll-container{
              height: 5rem;
              font-size: 2rem;
            }
            font-size: 2rem;
          }
          .video-card{
            .video-wrapper{
              video{
                width: 90%;
              }
            }
          }
        }
      }
      .content-3d{
        display: none;
      }
    }
  }
  .screen2{
    align-items: center;
    .screen2-content-2d{
      width: 100%;
      .objectives-container{
        height: 100%;
        width: 100%;
        .objectives{
          .objective-card{
            /* min-height: fit-content; */
            gap: 0.2rem;
            .objective-icon{
              width: 3.4rem;
              height: 3.4rem;
            }
            .objective-title{
              font-size: 2rem;
            }
            .objective-description{
              font-size: 1.6rem;
            }
          }
        }
      }
      /* h2{
        font-size: 2rem;
      } */
      /* .course-card-container{
        margin-top: 1rem;
        .number-list{
          .number-item{
            font-size: 2rem;
          }
        }
        .subclass-card-container{
          .overall-cards{
            .card-outline{
              font-size: 1.6rem;
            }
            .sub-card{
              h1{
                font-size: 1.4rem;
              }
            }
            .icon1-button{
              font-size: 1.8rem;
            }
          }
        }
      } */
    }
    .content-3d{
      display: none;
    }
  }
  .screen3, .screen4{
    .page{
      gap:1rem;
      .row-1{
        .row-1-container{
          width: 90%;
          h1{
            font-size: 3rem;
          }
          h2{
            font-size: 2rem;
          }
        }
      }
      .row-2{
        .row-2-container{
          width: 90%;
          .feature-list{
            .feature-item{
              .feature-logo{
                width: 3rem;
                height: 3rem;
              }
              .feature-content{
                .feature-title{
                  font-size: 1.6rem;
                }
                .feature-description{
                  font-size: 1.2rem;
                }
              }
            }
          }
          .videoPlayer{
            padding: 0;
          }
        }
      }
      .row-3{
        .progress-container{
          width: 80%;
          .icon-container{
            transform: scale(1.4);
          }
          .label-container{
            margin-top: 2rem;
            font-size:2rem;
            .step-description{
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
  .screen8, .ChoosePlan-container{
    .pricing-container{
      .section-title{
        width: 100%;
        margin-bottom: 2rem;
      }
      .saas-pricing-tabs{
        justify-content: space-around;
      }
    }
  }
  .chat-widget-container{
    width: 45% !important;
    height: 40% !important;
    overflow-y: scroll !important;
  }
}

/* for handling small heights in mobile(landscape modes) */
@media (max-height: 540px){
  .screen1{
    .content{
      .content-2d{
        .container{
          .text-wrapper{
            .three-d-text1{
              margin-top: 0.6rem;
              font-size: 1rem;
              .three-d-text{
                font-size: 1.4rem;
              }
            }
            p{
              display: none;
            }
            .video-card{
              .video-wrapper{
                video{
                  width:60%;
                }
              }
            }
          }
        }
      }
    }
  }
  .screen2{
    .screen2-content-2d{
      gap:0rem;
      .profile{
        padding-bottom: 0.5rem;
      }
      .objectives-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        .objectives{
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
          justify-content: start;
          overflow-y: scroll;
          .objective-card{
            width: 100%;
            height: 90%;
          }
        }
      }
      /* .course-card-container{
        .subclass-card-container{
          .overall-cards{
            .card-outline{
              font-size: 0.65rem;
            }
            .sub-card{
              display: none;
            }
          }
        }
      } */
    }
  }
  .screen3, .screen4{
    .page{
      gap:1rem;
      .row-1{
        padding: 0;
        margin-bottom: 0rem;
        /* .row-1-container{
        } */
      }
      .row-2{
        height: 40%;
        .row-2-container{
          width: 80%;
          .feature-list{
            .feature-item{
              .feature-content{
                font-size: 0.7em;
                .feature-description{
                  display: none;
                }
              }
            }
          }
          .videoPlayer{
            button{
              display: none;
            }
          }
        }
      }
      .row-3{
        .steps-title{
          display: none;
        }
        .progress-container{
          width: 80%;
          .icon-container{
            transform: scale(0.6);
          }
          .label-container{
            margin-top: 0rem;
          }
        }
      }
    }
  }
  .screen8, .ChoosePlan-container{
    .pricing-container{
      justify-content: flex-end;
      .section-title{
        span{
          display: none;
        }
      }
      .saas-pricing-tabs{
        width: 98%;
        /* height: 80%; */
        .tabList{
          width: 100%;
        }
        .tabList-container{
          width: 100%;
          padding: 1rem;
          /* height: 60% !important; */
          height:85%;
          min-height: 60% !important;
          overflow-x: scroll;
          overflow-y: hidden;
          gap:2rem;
          .tabData{
            min-width: 50%;
            height: 100%;
            .content{
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap:0rem 3rem;
              .pricing-section{
                width: fit-content;
                margin-top: 0rem;
                align-items: flex-start;
                padding-left: 3rem;
                .session-info{
                  text-align: left;
                  .unactive{
                    display: none;
                  }
                }
              }
              .counter-container{
                width: fit-content;
              }
              .session-features{
                margin-top: 0rem;
                padding-left: 1rem;
              }
              .tip{
                transform: translate(50%,-50%);
                padding: 0rem 0.5rem;
              }
            }
            .choose-planBtn{
              width: 30%;
              padding: 0.5rem 0.4rem;
            }
          }
        }
        .tabList-container::-webkit-scrollbar{
          height: 0.5rem;
        }
      }
    }
  }
  .screen9{
    .testimonials-section{
      .testimonials-container{
        .data-container{
          padding:0.5em;
          .scroll-right{
            display: none;
          } 
          .scroll-left{
            height: 80%;
          }
        }
      }
    }
  }
  .screen10{
    .footer{
      .FAQ-container{
        display: none;
      }
      .footer-bootom{
        padding-top: 0;
      }
    }
  }
  .chat-widget-container{
    width: 40% !important;
    overflow-y: scroll !important;
    .chat-widget-header{
      margin: 0;
      padding:0 ;
    }
    .chat-widget-body{
      padding: 0;
      .chat-form{
        padding: 0.3;
        .chat-form-group{
          margin: 0;
          .chat-form-input{
            padding: 0.2rem;
          }
          .chat-form-textarea{
            height: 15px;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}


.screen1, .screen2, .screen3, .screen4, .screen8{
  transition: all 0.5s ease;
}



/* ** */
@media (max-width:800px) {
  .home-header-container{
    .header{
      .nav-menu{
        justify-content: flex-start;
        .dropdown {
          display: block;
        }
        .main-menu{
          display: none;
        }
      } 
    }
  }
}

/* 250% */
@media (max-width: 650px) {
}

/* 300% */
@media (max-width:550px) {
}

@media (max-width: 400px) {
}



.loading-screen {
  position: fixed; 
  top: 0;         
  left: 0;       
  width: 100vw;    
  height: 100vh;   
  background-color: var(--screen-background-color); 
  color: white;    
  display: flex;  
  align-items: center; 
  justify-content: center; 
  z-index: 999;    
}


@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.screen3, .screen4 {
  align-items: flex-end;
  box-sizing: border-box
}

.screen3,.screen4,.screen5,.screen6,.screen7{
    background-color: var(--screen-background-color);
}
.screen8,.screen9{
  background-color: var(--screen-background-color);

}

.button-3d {
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin:20px 0px;
  background-color: var(--buttons-background-color) !important; 
  border-radius: 50px;
  cursor: pointer; 
}

.button-3d:hover {
  transform: translateY(-2px) scale(1.05); 
}

polygon{
  stroke: #a3b1b5;
  stroke-width: 3;
  fill:transparent;
  transition: transform 0.3s ease; /* Smooth transition */
}

.text {
  font-family: Poppins!important;
  font-size: 16px!important;
  font-weight: 500;
  fill: var(--text-primary);
}
.subtext{
  font-size: 18px!important;
  font-weight: 700;
  fill: var(--text-primary);
}




.price-tag {
  position: relative;
  background: rgb(37, 33, 33);
  min-width:140px;
  padding:10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  box-shadow: 0px 0px 4px var(--buttons-background-color);
}

.price-tag::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -10px; 
  border-top: 10px solid transparent; 
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgb(37, 33, 33); 
  transform: translateY(-50%); 
}

.price {
  font-size: 67px;
  color: var(--buttons-background-color) !important; 
  text-align: center;
  margin: 20px 0;
  animation: gradientAnimation 5s ease infinite;
}

.description {
  font-size: 14px;
  margin-top: 5px;
}


img{
  transition: transform 0.3s ease;
}

.sub-card:hover {
  transform: scale(1.02);
  img{
    transform: scale(0.9);
  }
  button{
    transform: scale(1.05);
  }
}

.icon1-button{
  align-self: flex-end;
  background-color: rgba(255, 255, 255, 0.3); 
  border: none; 
  border-radius: 50%; 
  margin: 0.5rem 0.5rem 0px 0px;
  padding:0.5rem 0.7rem; 
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1rem;
  z-index:100;
}
.icon1-button.active {
  background-color: #ffd700;
  color: var(--text-primary);
}
.icon1-button.active:hover {
  background-color: #ffd700;
  color: var(--text-primary);
}



.icon1-button:hover,
.icon2-button:hover{
  background-color: white;
}
.sub-card.sub1{
  background: linear-gradient(135deg,  #e48b50,#d55b14);
}
.sub-card.sub1.active {
  background: linear-gradient(135deg, #ffffff, #2c4b55);
}
.sub-card.sub2{
  background: linear-gradient(120deg, #000000,#fadb67);
}
.sub-card.sub2.active {
  background: linear-gradient(120deg, #fadb67,#113f4e);
}
.sub-card.sub3{
  background: linear-gradient(120deg,#545252, #884936);
}
.sub-card.sub3.active{
  background: linear-gradient(120deg,#a4a2a2, #884936);
}
.sub-card.sub4{
  background: linear-gradient(120deg, #000000,#144032);
}
.sub-card.sub4.active{
  background: linear-gradient(120deg, #d2c4c4,#516e66);
}
.sub-card.sub5{
  background: linear-gradient(120deg,#000000, #ffa948);
}
.sub-card.sub5.active{
  background: linear-gradient(120deg,#ffffff, #ffa948);
}
.sub-card.sub6{
  background: linear-gradient(180deg, #000000,#51707b);
}
.sub-card.sub6.active{
  background: linear-gradient(180deg, #ffffff,#51707b);
}

.number-item.active {
  color: var(--text-primary); 
  background: linear-gradient(
    180deg, 
    transparent -30%, 
    var(--buttons-background-color) 50%, /* Orange color with some transparency */
    transparent 120%
  );
}


.number-item.active::after {
  content: '';
  position: absolute;
  top: 0;
  right: -5px; /* Slightly outside the button */
  width: 4px;
  height: 100%;
  background-color: var(--buttons-background-color); /* Orange color for the line */
  border-radius: 2px 0 0 2px; /* Rounded edges on the inside */
}

.number-item:hover {
  transform: scale(1.1);
}
.subclass-card-container::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, Edge */
}
.subclass-card-container {
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}
.sub-card img {
  width: 100%;
  height: 40%;
  object-fit: contain;
  border-radius: 10rem;
}
.overall-cards:nth-child(4) .sub-card img,
.overall-cards:nth-child(6) .sub-card img,
.overall-cards:nth-child(2) .sub-card img
 {
  width: 70%; /* Increased width only for card 3 */
}

.btn-primary {
  cursor:pointer;
  border-radius: 10rem;
  color:var(--text-primary);
  /* background-color: #ffffff; */
  border: 2px solid var(--buttons-background-color)!important;

  &::before {
      border-radius: 30px;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      background-color: var(--buttons-background-color);

      z-index: -1;
      transition:all 0.5s ease;
  }
  &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
      color:  #ffffff;
      box-shadow: unset;
      background-color: transparent;
      border-color:  #367588;;
  }
  &.disabled, &:disabled {
    color: #ffffff; 
    background-color: #f5f5f5; 
    border: 1px solid #dcdcdc; 
    cursor: not-allowed; 
    pointer-events: none; 
    opacity: 0.7; 
  }
  &:hover, &:focus {
      color:  #ffffff;
      box-shadow: unset;
      background-color: transparent;
      border-color:  #367588;

      &::before {
          width: 0;
      }
  }
}
.btn {
  width: 75%;
  min-width: 60px;
  border: none;
  padding: 0.6rem 0;
  border-radius: 2rem;
  transition: all 0.5s ease;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  z-index: 1;
  text-wrap: nowrap;
  font-weight: 700!important;
  font-size: 1rem;
  &.disabled, &:disabled {
      opacity: 1;
  }
}



/* Footer Styles */

.footer {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--screen-background-color);
  color: var(--text-primary);
  padding:1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer-content {
  /* padding: 0.5em 0; */
  max-height: calc(40vh);
  min-height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 100%;
  margin: 0 auto;
  border-top: 1px solid var(--text-secondary); 
}

.footer-section {
  height: auto;
  max-height: 100%;
  padding: 0.5rem;
  flex: 1;
  min-width: 20%;
}

.footer-logo {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  height: 100%;
}

.footer-logo img {
  width: 50%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.footer-logo p {
  color: var(--text-secondary);
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  line-height: 1.5;
  .rocket-icon{
    color: var(--buttons-background-color) ;
    width: 1rem;
    height: 1rem;
  }
}
.FAQ-container{
  width: 100%;
  flex: 1;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  h2{
    margin-bottom: 0.5rem;
  }
}

.social-icons {
  height: 15%;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  margin-top: 3%;
}

.social-icons a {
  color: var(--text-primary);
  background-color: var(--buttons-background-color);
  aspect-ratio: 1/1;
  height: 100%;
  max-height: 3rem;
  max-width: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  padding: 0.5em;
}

.social-icons a:hover {
  transform: translateY(-5px);
}

.footer-section h3 {
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 1%;
  font-size: 0.9rem;
}

.footer-section ul li a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: var(--buttons-background-color);
}

.contact-info li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
}

.contact-info i {
  color: var(--buttons-background-color);
}

.footer-bottom {
  /* margin-top: ; */
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5%;
  color: var(--text-primary);
}

.copyright {
  font-size: 0.8rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
  margin-right: 5%;
}

.footer-links a {
  color: var(--text-primary);
  text-decoration: none;
  font-size: 0.8rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--buttons-background-color);
}


/* 125% (1229 px) */
@media (max-width: 1300px) {
}
/* 150% - footer (1024px) , ipad pro(1024 px landscape), iphone 14pro max(landscape) */
@media (max-width:1100px) {
}

/* 175% -200%  footer() , ipad pro(819px landscape), iphone 14pro max( 430px landscape) */
@media (max-width:900px) {
}

/* 250%  , ipad pro(portrait) and ipone 14pro max(portrait)*/
@media (max-width:700px) {
}



/* ::-webkit-scrollbar {
  display: none;
}


* {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
} */

.chat-widget {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid transparent; 
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease;
  background-color: var(--buttons-background-color);
  color: white;
}

/* 67% */
@media (max-width:3100px) {
  .chat-widget {
    width: 85px; 
    height: 85px;
    font-size: 32px;
  }
}

/* 75-90% */
@media (max-width:2100px) {
  .chat-widget {
    width: 70px; 
    height: 70px;
    font-size: 28px;
  }
}
/* 100% */
@media (max-width:1536px) {
  .chat-widget {
    width: 55px; 
    height: 55px;
    font-size: 24px;
  }
  
}

/* 110% */
@media (max-width: 1400px) {
  .chat-widget {
    width: 55px; 
    height: 55px;
  }
}
/* 125% */
@media (max-width: 1300px ) {
  .chat-widget {
    width: 50px; 
    height: 50px;
  }
}
/* 150% */
@media (max-width: 1100px ) {
  .chat-widget {
    width: 40px; 
    height: 40px;
    font-size: 16px;
  }
  /* .chat-widget-header{
    .form-title{
      font-size: 20px !important;
      margin-bottom: 0 !important;
    }
    .form-subtitle{
      font-size: 10px !important;
    }
  }
  .chat-widget-body {
    .chat-form-group{
      margin-bottom: 0em !important;
    }
    .chat-form-label{
      font-size: 10px !important;
    }
    .chat-form-input, .chat-form-textarea{
      font-size: 10px;
    }
    .chat-form-button{
      font-size: small;
      margin-top: 0.5em;
    }

  } */
}
/* 175% */
@media (max-width: 900px ) {
  .chat-widget {
    width: 36px; 
    bottom: 10px;
    right:10px;
    height: 36px;
    font-size: 14px;
  }
}
/* 200% */
@media (max-width: 800px ) {
  .chat-widget {
    width: 32px; 
    height: 32px;
    font-size: 12px;
  }
}

.chat-widget:hover {
  transform: scale(1.1);
}

.chat-widget-container {
  position: fixed;
  bottom: 30px;
  right: 20px;
  width: 30%; 
  max-height: 80%;
  height: auto; 
  background-color: rgb(14, 20, 40); 
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid #9B00B3; 
  justify-content: flex-start;
}

.chat-widget-header {
  position: relative;
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  width: 100%;
  .form-title{
    font-size: 32px;
    font-weight: 600;
    background: linear-gradient(135deg, var(--buttons-background-color) 0%, rgb(196, 79, 235)  50%, rgb(211, 123, 240) 70%); 
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
  }
  .form-subtitle{
    color: #a1a1aa;
    font-size: 0.9rem;
  }
}

.chat-widget-header .close-form-btn {
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--buttons-background-color)
}

.chat-widget-container.minimized {
  display: none; 
}

.chat-widget-body {
  width: 100%;
  flex: 1;
  padding: 10px; 
  background-color: rgb(14, 20, 40); 
  color: #333;
}

.chat-widget-body .chat-form {
  display: flex;
  flex-direction: column;
  background-color: rgba(14, 20, 40, 0.804); 
  padding: 10px 10px;
}

.ticket-status-success{
  font-size: 1.2rem;
  font-weight: 600;
  color:green;
}
.ticket-status-rejected{
  font-size: 1.2rem;
  font-weight: 600;
  color: red;
}

.chat-widget-body .chat-form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column; 
}

.chat-widget-body .chat-form-label {
  margin-bottom: 5px;
  font-weight: bold;
  color: white; 
}

.chat-widget-body .chat-form-input,
.chat-widget-body .chat-form-textarea {
  width: 100%; 
  padding: 10px 5px;
  border: 2px solid rgba(223, 137, 247, 0.2); 
  border-radius: 5px;
  background-color: rgba(14, 20, 40, 0.804);
  color: white;
}

.chat-form-textarea{
  max-height: 150px;
  max-width: 100%;
  min-width: 100%;
}

.chat-widget-body .chat-form-input:focus,
.chat-widget-body .chat-form-textarea:focus {
  border: 2px solid var(--buttons-background-color); 
  outline: none; 
}

.chat-widget-body .chat-form-button {
  align-self: flex-start;
  margin-left: 20%;
  padding: 10px 20px;
  background-color: var(--buttons-background-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-widget-body .chat-form-button:hover {
  background-color: #9b04d1;
}

.chat-widget-body .chat-form-input::placeholder,
.chat-widget-body .chat-form-textarea::placeholder {
  color: #999; 
  opacity: 1; 
  font-family: Poppins, sans-serif; 
}

.chat-widget-body .chat-form-input[type="email"] {
  padding-left: 0; 
  padding-right: 5px;
}

.animate-scroll img {
  margin: 0 2rem;
  height: 8rem; /* Increased height */
  width: auto;
  object-fit: contain;
}

.partners-container h2 {
  font-size: 1.5rem; /* Increased font size */
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2rem;
}