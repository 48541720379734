/* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  } */
  .upload-resume-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .upload-resume-buttons{
        display: flex;
        margin-left:3px;
        gap:10px;
    }
    button{
        margin-bottom: 5px;
        border-radius: 5px;
        transition: all 0.3s ease;
        cursor: pointer;
    }
    button:hover{
        transform: scale(1.1);
    }
    .new{
        background: linear-gradient(to left top, #705df2, #19114e);
        color:white;
        padding:6px;
        border:none;
        font-size: 12px;
 
    }
    .upload{
        background: linear-gradient(to left top, #ffffff, #dddce1);
        border: 0.1px solid #786bce !important;
        color: #2d2943 !important;
        font-size: 12px;
    }
  }
  .cs-modal-content {
    background: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 500px;
    max-width: 80%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .cs-close-button {
      color: #161515 !important;
      position: absolute;
      font-size: 20px;
      margin: 10px;
      border: none;
      cursor: pointer;
      right: 5px;
      top: 3px;
      background: transparent;
 
  }
  .cs-modal-content h2 {
    margin: 0 0 0px;
    font-size: 20px;
    color: #333;
  }
  .cs-modal-content label {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom:5px
  }
  .cs-modal-content select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    color: #0e0e0e;
    background-color: #f8f9fa;
    cursor: pointer;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  .cs-modal-content label span {
    color: #888;
    font-size: 12px;
    margin-left: 5px;
  }
 
  .footer{
    display: flex;
    justify-content: flex-end;
    gap:10px;
    .footer-button{
        padding:8px 15px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3 ease;
        font-size: 14px;
    }
    .footer-button:hover{
        transform: scale(1.1);
    }
    .submit{
        background: linear-gradient(to left top, #705df2, #19114e);
        color:white;
        border: none;
    }
    .cancel{
        background: linear-gradient(to left top, #ffffff, #dddce1);
        border: 0.1px solid #786bce !important;
        color: #2d2943 !important;
    }
  }
 
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
  }
 
  .pagination-button,
  .pagination-number {
    background-color: #ffffff;
    border: 1px solid #a2a2a2;
    color: #555;
    font-size: 16px;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
  }
 
  .pagination-number.active {
    background: linear-gradient(to left top, #705df2, #19114e);
    color: #fff;
    border-color: #3f8cff;
  }
 
  .pagination-button.disabled {
    color: #aaa;
    cursor: not-allowed;
    background-color: #f9f9f9;
  }
 
  .pagination-button:hover:not(.disabled),
  .pagination-number:hover:not(.active) {
    background-color: #e6e6e6;
  }
  .schedule-time{
    border:1px solid rgba(62, 65, 62, 0.212);
    margin:-10px 0px;
    border-radius: 5px!important;
    padding:10px 10px;
  }
  .datepicker-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:3px 20px;
  }

  .label{
    margin:0px!important;
    padding:0px;

  }
  
  .calendar-icon {
    position: absolute;
    top: 65%;
    right: 44%;
    transform: translateY(-50%);
    color: #4d2b9d;
    font-size: 1.2rem;
    pointer-events: none; 
    z-index: 1000;
  }
  
  .datepicker {
    position: relative;
    font-size: 1rem;
    padding: 0px 10px;
    width: 120%;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 2.6rem;
    box-sizing: border-box;
    cursor:pointer;
  }
 .datepicker:focus{
    border:2px solid #4d2b9d!important;
    background-color: white;
    color:black;
 } 


 
