
  .timepicker{
    display: flex;
    width:160px;
    border-radius: 4px;
    height:auto
  }
  .timepicker:focus{
    border:2px solid #4d2b9d!important;
    background-color: white!important;
  }
  
  .timepicker-select {
    margin-right: 5px;
    padding:9px 0px;
    border: none!important;
    outline: none!important;
    border-radius: 4px;
    font-size: 1rem;
    color: white;
    background-color: transparent!important;
    cursor: pointer;
    outline: none;
  }
  
  .timepicker-select:last-child {
    margin-right: 0;
  }
  .timepicker-select option {
    background-color: #e4ebf5; /* Background color for options */
    color: #000; /* Text color */
  }

  .timepicker-select:hover {
    background-color: #e6e6e6; 
  }
  