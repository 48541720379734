.analytics-container {
    padding: 0 2rem;
    background-color: var(--screen-background-color);
    color: white;
    overflow: hidden; /* Remove vertical scrolling */
    height: 100%;  /* Changed from 100vh */
}
 
.dashboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}
 
.dashboard-title {
    font-size: 1.8rem;
    font-weight: 600;
    color:var( --buttons-background-color);
    text-align: center;
    position: relative;
}
.dashboard-title::after{
    content: '';
    position: absolute;
    top: 40px;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(190, 5, 250, 0) 0%, rgba(190, 5, 250, 0.5) 50%, rgba(190, 5, 250, 0) 100%);
}

.session-label {
    margin-top: 0.4rem;
    font-size: 0.7rem;
    text-align: center;
}
 
.analytics-content,
.focus-content {
    margin-top: 1rem;
}

 
/* h3 {
    font-size: 0.95rem;
    font-weight: 600;
    color: white;
    margin-bottom: 1.8rem; 
    margin-top: -3%;
} */
 
/* h4 {
    color: #BE05FA;
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
} */
 
.strengths-section,
.improvement-section {
    margin-bottom: 1rem;
}
 
/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 6px;
}
 
::-webkit-scrollbar-track {
    background: rgba(30, 30, 63, 0.5);
}
 
::-webkit-scrollbar-thumb {
    background: rgba(190, 5, 250, 0.5);
    border-radius: 3px;
}
 
::-webkit-scrollbar-thumb:hover {
    background: #BE05FA;
}
 


.analytics-focus-sections::-webkit-scrollbar {
    width: 3px;
}
 

.analytics-focus-sections::-webkit-scrollbar-track {
    background: rgba(30, 30, 63, 0.5);
}
 

.analytics-focus-sections::-webkit-scrollbar-thumb {
    background: rgba(190, 5, 250, 0.5);
    border-radius: 3px;
}
 

.analytics-focus-sections::-webkit-scrollbar-thumb:hover {
    background: #BE05FA;
}


 
.kpi-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Force 4 columns */
    gap: 1rem;
    max-width: 100%;
}
 
.kpi-card {
    background: rgba(30, 30, 63, 0.5);
    border: 1px solid rgba(190, 5, 250, 0.2);
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.3s ease;
}
 
.kpi-card:hover {
    transform: translateY(-2px);
    border-color: #BE05FA;
    box-shadow: 0 4px 12px rgba(190, 5, 250, 0.1);
}
 
.kpi-icon {
    background: rgba(190, 5, 250, 0.1);
    width: 45px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BE05FA;
    font-size: 1.2rem;
}
 
.kpi-content {
    flex: 1;
}
 
.kpi-label {
    display: block;
    font-size: 0.85rem;
    color: #a4a6b3;
    margin-bottom: 0.4rem;
}
 
.kpi-value-wrapper {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
 
.kpi-value {
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
}
 
.kpi-trend {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-size: 0.85rem;
    padding: 0.2rem 0.5rem;
    border-radius: 15px;
}
 
.kpi-trend.positive {
    color: #4caf50;
    background: rgba(76, 175, 80, 0.1);
}
 
.kpi-trend.neutral {
    color: #a4a6b3;
    background: rgba(164, 166, 179, 0.1);
}
 
.progress-mini {
    flex: 1;
    height: 4px;
    background:rgb(209 164 223 / 23%);
    border-radius: 2px;
    overflow: hidden;
}
 
.progress-mini .progress-bar {
    height: 100%;
    background: #BE05FA;
    border-radius: 2px;
    transition: width 0.5s ease;
}
 
.kpi-status {
    font-size: 0.85rem;
    color: #BE05FA;
    background: rgba(190, 5, 250, 0.1);
    padding: 0.2rem 0.5rem;
    border-radius: 15px;
}
 
.sessions-progress-container {
    background-color: rgba(30, 30, 63, 0.5);
    padding: 1.2rem;
    border-radius: 12px;
    border: 1px solid rgba(190, 5, 250, 0.2);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
 
.sessions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 
.kpi-badge {
    font-size: 0.75rem;
    padding: 0.2rem 0.6rem;
    border-radius: 12px;
    background: rgba(190, 5, 250, 0.1);
    color: #BE05FA;
    font-weight: 500;
}
 
.performance-card {
    background: rgba(30, 30, 63, 0.5);
    border: 1px solid rgba(190, 5, 250, 0.2);
    border-radius: 12px;
    padding: 1.5rem;
    margin-top: 1rem;
    overflow: hidden;
}
 
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}
 
/* Dashboard Content Transitions */
.dashboard-content {
    animation: fadeIn 0.3s ease-in-out;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
 
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
 
.dashboard-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    margin: 1rem 0;
    border-bottom: 1px solid rgba(190, 5, 250, 0.2);
}
 
.nav-tab {
    flex: 1;
    background: transparent;
    border: none;
    color: white;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    text-align: center;
    font-weight: 600;
}

.nav-tab i{
color:#BE05FA ;
}
 
.nav-tab::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #BE05FA;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}
 
.nav-tab:hover {
    color: white;
}
 
.nav-tab:hover::after {
    transform: scaleX(0.8);
}
 
.nav-tab.active {
    color: #BE05FA;
}
 
.nav-tab.active::after {
    transform: scaleX(1);
    box-shadow: 0 0 10px rgba(190, 5, 250, 0.5);
}
 
.nav-icon {
    font-size: 1.1rem;
    transition: transform 0.3s ease;
}
 
.nav-tab:hover .nav-icon {
    transform: translateY(-2px);
}

.chart-section {
    display: flex;
    gap: 25px;
    padding: 2rem 2rem 0 2rem; /* Removed bottom padding */
    margin: 1.5rem 0 0 0;
    flex: 1;
    height: calc(100% - 150px); /* Use percentage instead of vh */
    overflow-y: auto;
    overflow-x: hidden;
}
 
.chart-section::-webkit-scrollbar {
    width: 6px; /* Scrollbar width */
}

.chart-section::-webkit-scrollbar-track {
    background: rgba(30, 30, 63, 0.5); /* Track background */
}

.chart-section::-webkit-scrollbar-thumb {
    background: rgba(190, 5, 250, 0.5); /* Scrollbar thumb color */
    border-radius: 3px; /* Rounded edges */
}

.chart-section::-webkit-scrollbar-thumb:hover {
    background: #BE05FA; /* Highlight color on hover */
}
.score-chart-container h3 {
    color: white;
    margin-bottom: -1%;
    font-size: 1.2rem;
}
 
.score-chart {
    /* height:250px; */
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    /* padding-bottom: 2rem; */
}
 
.focus-card {
    background: rgba(30, 30, 63, 0.5);
    border: 1px solid rgba(190, 5, 250, 0.2);
    border-radius: 12px;
    padding: 1.5rem;
    width: 30%;
    transition: all 0.3s ease;
    
}

.focus-card.combined {
    background: rgba(30, 30, 63, 0.5);
    border: 1px solid rgba(190, 5, 250, 0.2);
    border-radius: 12px;
    padding: 1.5rem;
    width: 30%;
    transition: all 0.3s ease;
  }


  
.focus-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(190, 5, 250, 0.1);
}
.focus-section-header{
display: flex;
gap: 5px;
}
 
.focus-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}
 
.focus-header h3 {
    color: white;
    margin: 0;
    font-size: 1.1rem;
}

.analytics-focus-sections{
width: 100%;
height: auto;
max-height: 250px;
overflow-y: auto;
padding: 0px 2px;
}
.focus-section {
    margin-bottom: 10px;
  }
  
  .focus-section h4 {
    margin-bottom: 5px;
    color: #d166ff;
    font-weight: 400;
  }
  
  .focus-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .focus-list li {
    background-color: #bd05fa2f;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    display: inline-block;
  }


  .focus-roles-list{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .focus-roles-list li{
    background-color: #bd05fa2f;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    display: inline-block;
  }

.focus-icon {
    font-size: 1.5rem;
    color: #BE05FA;
}

.focus-list li:hover {
    transform: translateX(5px);
}

.focus-roles-list li:hover{
    transform: translateX(5px);
}

.tooltip-text{
    font-size: 12px;
    line-height: 1;
}
.mock-interview-prompt {
    margin-top: 50px;
    padding: 40px;
    /* background: #1b1e2e; */
    border-radius: 10px;
    /* text-align: center; */
    color: white;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.mock-interview-prompt h2{
    margin-bottom: 15px;
    color: var(--buttons-background-color);
}

.mock-session-box {
    background: rgba(30, 30, 63, 0.5);
    border: 1px solid rgba(190, 5, 250, 0.2);
    border-radius: 12px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mock-session-content {
    text-align: center;
    width: 100%;

}

.mock-session-content h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--buttons-background-color);
}

.mock-session-content p {
    font-size: 14px;
    margin-bottom: 15px;
}

.mock-icon {
    font-size: 24px;
    display: block;
    margin: 0 auto 10px auto;
    color: var(--buttons-background-color);
}



.mock-session-button {
    background-color: var(--buttons-background-color);
    border: none;
    padding: 12px 25px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.mock-session-button:hover {
    transform: scale(1.05);
}


/* Media Queries */

@media screen and (max-width: 2560px) {
    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .focus-card {
        width: 34%;
        height: auto;
        max-height: 430px;
        padding: 1rem;
    }

    .focus-card.combined {
        width: 32%;
        height: auto;
        max-height: 430px;
        padding: 1rem;
    }

    .focus-card.roles {
        width: 32%;
        height: auto;
        max-height: 430px;
        padding: 1rem;
    }
    .chart-section {
        gap: 20px;
        justify-content: space-between;
    }
    
    .nav-tab {
        gap: 0.5rem;
        padding: 1rem 1rem;
    }

    .kpi-value-wrapper {
        gap: 0.6rem;
    }

    .sessions-progress-container {
        padding: 1rem;
    }
}


@media screen and (max-width: 1540px) {
    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .focus-card {
        width: 34%;
        height: auto;
        max-height: 300px;
        padding: 1rem;
    }

    .focus-card.combined {
        width: 32%;
        height: auto;
        max-height: 315px;
        padding: 1rem;
    }

    .focus-card.roles {
        width: 32%;
        height: auto;
        max-height: 315px;
        padding: 1rem;
    }

    
    .chart-section {
        gap: 20px;
    }
    
    .nav-tab {
        gap: 0.5rem;
        padding: 1rem 1rem;
    }

    .kpi-value-wrapper {
        gap: 0.6rem;
    }

    .sessions-progress-container {
        padding: 1rem;
    }
}

@media screen and (max-width: 1450px) {
    .analytics-container {
        padding: 0 1.6rem;
        height: calc(100vh - 60px);
    }

    .dashboard-title {
        font-size: 1.4rem;
    }

    .dashboard-nav {
        padding: 0 1.6rem;
    }

    .nav-tab {
        padding: 0.8rem 1.2rem;
        font-size: 1rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.8rem;
        margin-bottom: 1rem;
    }

    .kpi-card {
        padding: 0.7rem;
        min-height: 75px;
    }

    .kpi-value {
        font-size: 1rem;
    }

    .kpi-label {
        font-size: 0.7rem;
    }

    .kpi-icon {
        width: 35px;
        height: 35px;
    }

    .kpi-value-wrapper {
        gap: 0.4rem;
    }

    .kpi-trend {
        font-size: 0.8rem;
        padding: 0.2rem 0.4rem;
    }

    .chart-section {
        height: calc(100% - 240px);
        padding: 1.4rem 1.4rem 0;
        gap: 16px;
        overflow-y: auto;
    }

    .score-chart-container {
        height: 260px;
    }

    .focus-card {
        width: 34%;
        height: auto;
        max-height: 300px;
        padding: 1rem;
    }

    .focus-card.combined {
        width: 32%;
        height: auto;
        max-height: 280px;
        padding: 1rem;
    }

    .focus-card.roles {
        width: 32%;
        height: auto;
        max-height: 280px;
        padding: 1rem;
    }

    .focus-header h3 {
        font-size: 1rem;
    }

    .analytics-focus-sections {
        max-height: 147px;
        padding: 0 10px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 11px;
        padding: 4px 8px;
        margin: 2px;
    }

    .focus-area-tag {
        padding: 4px 8px;
        font-size: 0.9rem;
        margin: 2px;
    }
}

@media screen and (max-width: 1150px) {
    .analytics-container {
        padding: 0 1rem;
        height: calc(100vh - 55px);
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .kpi-card {
        padding: 0.5rem;
        min-height: 60px;
    }

    .kpi-value {
        font-size: 0.85rem;
    }

    .kpi-label {
        font-size: 0.6rem;
    }

    .kpi-icon {
        width: 28px;
        height: 28px;
    }

    .kpi-value-wrapper {
        gap: 0.3rem;
    }

    .chart-section {
        padding: 1rem 1rem 0 1rem;
        gap: 20px;
        flex-direction: column;
        height: calc(100% - 120px);
    }

    .score-chart-container {
        width: 75%;
        height: 250px;
        margin-bottom: 20px;
    }

    .focus-card {
        width: 31%;
        max-height: 200px;
        padding: 0.8rem;
        margin-top: 10px;
    }

    .focus-card.combined {
        width: 28%;
        height: 80px;
        min-height: 80px;
        overflow-y: auto;
        transform: translateY(-10%);
        margin-bottom: 3%;
    }

    .focus-card.roles {
        width: 28%;
        height: 80px;
        min-height: 80px;
        overflow-y: auto;
        transform: translateY(-10%);
        margin-bottom: 10%;
    }

    .analytics-focus-sections {
        max-height: 120px;
        overflow-y: auto;
        padding: 0 8px;
    }

    .focus-header h3 {
        font-size: 0.9rem;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 10px;
        padding: 3px 7px;
    }
}

@media screen and (max-width: 1190px) {
    .analytics-container {
        padding: 0 1.1rem;
        height: calc(100vh - 53px);
    }

    .dashboard-title {
        font-size: 1.2rem;
    }

    .dashboard-nav {
        padding: 0 1.1rem;
    }

    .nav-tab {
        padding: 0.65rem 0.9rem;
        font-size: 0.9rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.35rem;
        margin-bottom: 0.7rem;
    }

    .kpi-card {
        padding: 0.4rem;
        min-height: 58px;
    }

    .kpi-value {
        font-size: 0.82rem;
    }

    .kpi-label {
        font-size: 0.58rem;
    }

    .kpi-icon {
        width: 26px;
        height: 26px;
    }

    .kpi-value-wrapper {
        gap: 0.25rem;
    }

    .kpi-trend {
        font-size: 0.65rem;
        padding: 0.12rem 0.25rem;
    }

    .chart-section {
        height: calc(100% - 125px);
        padding: 1.1rem 1.1rem 0;
        gap: 13px;
    }

    .score-chart-container {
        height: 230px;
    }

    .focus-card {
        width: 32%;
        max-height: 260px;
        padding: 0.85rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 240px;
        padding: 0.85rem;
        /* transform: translateY(50%); */
    }

    .focus-card.roles {
        width: 100%;
        max-height: 240px;
        padding: 0.85rem;
        /* transform: translateY(10%); */
    }

    .focus-header h3 {
        font-size: 0.95rem;
    }

    .analytics-focus-sections {
        max-height: 170px;
        padding: 0 7px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 10.5px;
        padding: 3px 7px;
        margin: 2px;
    }

    .focus-area-tag {
        padding: 3px 7px;
        font-size: 0.85rem;
        margin: 2px;
    }
}

@media screen and (max-width: 1150px) {
    .analytics-container {
        padding: 0 1rem;
        height: calc(100vh - 20px);
    }

    .dashboard-title {
        font-size: 1.15rem;
    }

    .dashboard-nav {
        padding: 0 1rem;
    }

    .nav-tab {
        padding: 0.6rem 0.85rem;
        font-size: 0.85rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.3rem;
        margin-bottom: 0.6rem;
    }

    .kpi-card {
        padding: 0.35rem;
        min-height: 55px;
    }

    .kpi-value {
        font-size: 0.8rem;
    }

    .kpi-label {
        font-size: 0.55rem;
    }

    .kpi-icon {
        width: 24px;
        height: 24px;
    }

    .kpi-value-wrapper {
        gap: 0.2rem;
    }

    .kpi-trend {
        font-size: 0.6rem;
        padding: 0.1rem 0.2rem;
    }

    .chart-section {
        height: calc(100% - 120px);
        padding: 1rem 1rem 0;
        gap: 12px;
    }

    .score-chart-container {
        height: 225px;
    }

    .focus-card {
        width: 31%;
        max-height: 240px;
        padding: 0.8rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 320px;
        padding: 0.8rem;
        transform: translateY(55%);
    }

    .focus-card.roles {
        width: 100%;
        max-height: 320px;
        padding: 0.8rem;
        transform: translateY(15%);
    }

    .focus-header h3 {
        font-size: 0.85rem;
    }

    .analytics-focus-sections {
        max-height: 165px;
        padding: 0 6px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 9.5px;
        padding: 3px 5px;
        margin: 2px;
    }

    .focus-area-tag {
        padding: 3px 5px;
        font-size: 0.75rem;
        margin: 2px;
    }
}

@media screen and (max-width: 1050px) {
    .analytics-container {
        padding: 0 0.9rem;
        height: calc(100% - 50px);
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    
    .chart-section {
        flex-direction: column;
        padding: 1rem 1rem 0 1rem;
    }
    
    .focus-card {
        width: 31%;
        max-height: 200px;
        padding: 0.8rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 200px;
        padding: 0.8rem;
        transform: translateY(60%);
    }

    .focus-card.roles {
        width: 100%;
        max-height: 150px;
        padding: 0.8rem;
        transform: translateY(25%);
    }
    
    .sessions-info {
        gap: 0.8rem;
    }

    .kpi-trend {
        font-size: 0.8rem;
        padding: 0.2rem 0.4rem;
    }

    .dashboard-header {
        padding: 0.4rem;
    }
}

@media screen and (max-width: 950px) {
    .analytics-container {
        padding: 0 0.9rem;
        height: calc(100vh - 20px);
    }

    .dashboard-title {
        font-size: 1.1rem;
    }

    .dashboard-nav {
        padding: 0 0.9rem;
    }

    .nav-tab {
        padding: 0.55rem 0.8rem;
        font-size: 0.8rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.25rem;
        margin-bottom: 0.5rem;
    }

    .kpi-card {
        padding: 0.3rem;
        min-height: 52px;
    }

    .kpi-value {
        font-size: 0.75rem;
    }

    .kpi-label {
        font-size: 0.52rem;
    }

    .kpi-icon {
        width: 22px;
        height: 22px;
    }

    .kpi-value-wrapper {
        gap: 0.15rem;
    }

    .kpi-trend {
        font-size: 0.55rem;
        padding: 0.08rem 0.18rem;
    }

    .chart-section {
        height: calc(100% - 155px);
        padding: 0.9rem 0.9rem 0;
        gap: 10px;
    }

    .score-chart-container {
        height: 210px;
    }

    .focus-card {
        width: 30%;
        max-height: 220px;
        padding: 0.75rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 200px;
        padding: 0.75rem;
        transform: translateY(80%);
    }

    .focus-card.roles {
        width: 100%;
        max-height: 180px;
        padding: 0.75rem;
        transform: translateY(40%);
    }

    .focus-header h3 {
        font-size: 0.8rem;
    }

    .analytics-focus-sections {
        max-height: 160px;
        padding: 0 5px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 9px;
        padding: 2px 4px;
        margin: 1px;
    }

    .focus-area-tag {
        padding: 2px 4px;
        font-size: 0.7rem;
        margin: 1px;
    }
}

@media screen and (max-width: 769px) {
    .analytics-container {
        padding: 0 1rem;
    }
    
    .dashboard-title {
        font-size: 1.5rem;
    }
    
    .kpi-grid {
        gap: 0.75rem;
    }
    
    .kpi-card {
        padding: 0.75rem;
    }
    
    .dashboard-nav {
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .nav-tab {
        white-space: nowrap;
        min-width: auto;
    }

    .kpi-content {
        min-width: 0;
    }
}

@media screen and (max-width: 715px) {
    .analytics-container {
        padding: 0 0.8rem;
        height: calc(100vh - 10px);
    }

    .dashboard-title {
        font-size: 1rem;
    }

    .dashboard-nav {
        padding: 0 0.8rem;
    }

    .nav-tab {
        padding: 0.5rem 0.7rem;
        font-size: 0.75rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.2rem;
        margin-bottom: 0.4rem;
        transform: translateY(10%);
    }

    .kpi-card {
        padding: 0.25rem;
        min-height: 48px;
    }

    .kpi-value {
        font-size: 0.7rem;
    }

    .kpi-label {
        font-size: 0.48rem;
    }

    .kpi-icon {
        width: 20px;
        height: 20px;
    }

    .kpi-value-wrapper {
        gap: 0.12rem;
    }

    .kpi-trend {
        font-size: 0.5rem;
        padding: 0.06rem 0.15rem;
    }

    .chart-section {
        height: calc(100% - 200px);
        padding: 0.8rem 0.8rem 0;
        gap: 8px;
        overflow-y: auto;
    }

    .score-chart-container {
        height: 200px;
    }

    .focus-card {
        width: 100%;
        max-height: 200px;
        padding: 0.7rem;
        margin-bottom: 1rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 180px;
        padding: 0.7rem;
        margin-bottom: 1rem;
        transform: translateY(90%);
    }

    .focus-card.roles {
        width: 100%;
        max-height: 160px;
        padding: 0.7rem;
        margin-bottom: 1rem;
        transform: translateY(60%);
    }

    .focus-header h3 {
        font-size: 0.75rem;
    }

    .analytics-focus-sections {
        max-height: 150px;
        padding: 0 4px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 8.5px;
        padding: 2px 3px;
        margin: 1px;
    }

    .focus-area-tag {
        padding: 2px 3px;
        font-size: 0.65rem;
        margin: 1px;
    }
}

/* 530px breakpoint */
@media screen and (max-width: 540px) {
    .analytics-container {
        padding: 0 0.6rem;
        height: calc(100vh - 10px);
    }

    .dashboard-title {
        font-size: 0.9rem;
    }

    .dashboard-nav {
        padding: 0 0.6rem;
    }

    .nav-tab {
        padding: 0.45rem 0.6rem;
        font-size: 0.7rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.15rem;
        margin-bottom: 0.3rem;
    }

    .kpi-card {
        padding: 0.2rem;
        min-height: 45px;
    }

    .kpi-value {
        font-size: 0.65rem;
    }

    .kpi-label {
        font-size: 0.45rem;
    }

    .kpi-icon {
        width: 18px;
        height: 18px;
    }

    .kpi-value-wrapper {
        gap: 0.1rem;
    }

    .kpi-trend {
        font-size: 0.45rem;
        padding: 0.05rem 0.12rem;
    }

    .chart-section {
        height: calc(100% - 225px);
        padding: 0.6rem 0.6rem 0;
        gap: 6px;
    }

    .score-chart-container {
        height: 180px;
    }

    .focus-card {
        width: 100%;
        max-height: 180px;
        padding: 0.6rem;
        margin-bottom: 0.4rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 160px;
        padding: 0.6rem;
        margin-bottom: 0.4rem;
        transform: translateY(120%);
    }

    .focus-card.roles {
        width: 100%;
        max-height: 140px;
        padding: 0.6rem;
        margin-bottom: 0.4rem;
        transform: translateY(120%);
    }

    .focus-header h3 {
        font-size: 0.7rem;
    }

    .analytics-focus-sections {
        max-height: 140px;
        padding: 0 3px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 8px;
        padding: 1.5px 2.5px;
        margin: 0.5px;
    }

    .focus-area-tag {
        padding: 1.5px 2.5px;
        font-size: 0.6rem;
        margin: 0.5px;
    }
}

@media screen and (max-width: 480px) {
    .analytics-container {
        padding: 0 0.5rem;
        height: calc(100vh - 40px);
    }

    .dashboard-title {
        font-size: 1.2rem;
    }

    .dashboard-nav {
        padding: 0 0.5rem;
    }

    .nav-tab {
        flex-direction: column;
        padding: 0.4rem;
        gap: 0.3rem;
        font-size: 0.7rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.3rem;
        margin-bottom: 0.5rem;
    }

    .kpi-card {
        padding: 0.2rem;
        min-height: 45px;
    }

    .kpi-value {
        font-size: 0.65rem;
    }

    .kpi-label {
        font-size: 0.45rem;
    }

    .kpi-icon {
        width: 18px;
        height: 18px;
    }

    .kpi-value-wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.1rem;
    }

    .kpi-trend {
        font-size: 0.45rem;
        padding: 0.05rem 0.1rem;
    }

    .chart-section {
        padding: 0.5rem 0.5rem 0;
        gap: 6px;
        height: calc(100% - 220px);
        overflow-y: auto;
        overflow-x: auto;
    }

    .score-chart-container {
        height: 180px;
    }

    .focus-card {
        width: 100%;
        max-height: 160px;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 140px;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        transform: translateY(150%);
    }

    .focus-card.roles {
        width: 100%;
        max-height: 120px;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        transform: translateY(150%);
    }

    .focus-header h3 {
        font-size: 0.7rem;
    }

    .analytics-focus-sections {
        max-height: 130px;
        padding: 0 3px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 7.5px;
        padding: 1px 2px;
        margin: 0.5px;
    }

    .focus-area-tag {
        padding: 1px 2px;
        font-size: 0.55rem;
        margin: 0.5px;
    }

    .sessions-info > div {
        min-width: 100%;
    }

    .focus-section-header {
        flex-wrap: wrap;
    }

    .dashboard-content {
        height: 90%;
    }
}

@media screen and (max-width: 373px) {
    .analytics-container {
        padding: 0 0.3rem;
        height: calc(100vh - 10px);
    }

    .dashboard-title {
        font-size: 0.9rem;
    }

    .dashboard-nav {
        padding: 0 0.3rem;
    }

    .nav-tab {
        flex-direction: column;
        padding: 0.3rem;
        gap: 0.2rem;
        font-size: 0.6rem;
    }

    .kpi-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.15rem;
        margin-bottom: 0.3rem;
    }

    .kpi-card {
        padding: 0.15rem;
        min-height: 40px;
    }

    .kpi-value {
        font-size: 0.55rem;
    }

    .kpi-label {
        font-size: 0.35rem;
    }

    .kpi-icon {
        width: 14px;
        height: 14px;
    }

    .kpi-value-wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.08rem;
    }

    .kpi-trend {
        font-size: 0.35rem;
        padding: 0.04rem 0.08rem;
    }

    .chart-section {
        padding: 0.3rem;
        gap: 4px;
        height: calc(100vh - 250px);
        overflow-x: auto;
        overflow-y: auto;
    }

    .score-chart-container {
        width: 70%;
        height: 130px;
    }

    .focus-card {
        width: 100%;
        max-height: 120px;
        padding: 0.3rem;
        margin-bottom: 0.3rem;
    }

    .focus-card.combined {
        width: 100%;
        max-height: 130px;
        padding: 0.4rem;
        margin-bottom: 0.4rem;
        transform: translateY(205%);
    }

    .focus-card.roles {
        width: 100%;
        max-height: 120px;
        padding: 0.4rem;
        margin-bottom: 0.4rem;
        transform: translateY(190%);
    }

    .focus-header h3 {
        font-size: 0.6rem;
    }

    .analytics-focus-sections {
        max-height: 110px;
        padding: 0 2px;
    }

    .focus-list li,
    .focus-roles-list li {
        font-size: 6.5px;
        padding: 0.8px 1.2px;
        margin: 0.4px;
    }

    .focus-area-tag {
        padding: 0.8px 1.2px;
        font-size: 0.45rem;
        margin: 0.4px;
    }
}
