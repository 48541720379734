.main-landing-page {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.left-landing-page {
    display: flex;
    flex-direction: column;
    flex: 0 0 17%;
    height: 100vh;
    align-items: left;
    background: linear-gradient(180deg, var(--screen-background-color), var(--buttons-background-color));
    position: relative;
    overflow: hidden;
}

.logo {
    position: relative;
    width: 200px;
    padding: 25px 30px;
    transition: transform 0.3s ease;
    margin-bottom: -5px;
    margin-top: 10px;
}

.logo:hover {
    transform: scale(1.02);
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.2));
}

.logo::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
}

.landing-services {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding-top: 10px;
    /* z-index: 999; */
}

.landing-services button {
    font-size: 16px;
    background-color: transparent;
    color: white;
    border: none;
    padding: 14px 20px;
    margin: 4px 20px;
    text-align: left;
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 15px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(190, 5, 250, 0.1);
    font-family: Poppins !important;
}

.landing-services button img {
    width: 26px;
    height: 26px;
    opacity: 0.9;
    transition: all 0.3s ease;
    filter: brightness(1.1) drop-shadow(0 0 2px rgba(255, 255, 255, 0.3));
}

.landing-services button:hover {
    transform: translateX(5px);
    border-color: rgba(190, 5, 250, 0.2);
    background: rgba(190, 5, 250, 0.05);
}

.landing-services button:hover img {
    transform: scale(1.15);
    opacity: 1;
    filter: brightness(1.2) drop-shadow(0 0 4px rgba(255, 255, 255, 0.4));
}

.landing-services button.active {
    border-color: rgba(190, 5, 250, 0.25);
    box-shadow: 
        0 0 20px rgba(190, 5, 250, 0.1),
        inset 0 0 15px rgba(190, 5, 250, 0.05);
    font-weight: 500;
    letter-spacing: 0.3px;
}

.landing-services button.active img {
    opacity: 1;
    transform: scale(1.1);
    filter: brightness(1.3) drop-shadow(0 0 6px rgba(255, 255, 255, 0.5));
}

.landing-services button.active::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 24px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px 0 0 4px;
    box-shadow: -2px 0 6px rgba(255, 255, 255, 0.4);
}

.landing-services button::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.05),
        transparent
    );
    transform: translateX(-100%);
    transition: transform 0.5s ease;
}

.landing-services button:hover::before {
    transform: translateX(100%);
}

.burger-menu-btn {
        display: none;
    }

.right-landing-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: var(--screen-background-color);
}

.schedule {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
    color: #f4f2f2;
}

.schedule i {
    font-size: 20px;
    color: #f7f5ef;
}

.nav-footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.nav-footer button {
    font-size: 14px;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    padding: 10px 15px;
    text-align: left;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

/* Common styles for both icons */
.nav-footer button i {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-footer button:hover {
    color: white;
    background: rgba(190, 5, 250, 0.1);
}

/* Hover effect for both icons */
.nav-footer button:hover i {
    color: #FFFFFF;
    transform: scale(1.1);
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.version-info {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    margin-top: 5px;
    padding: 0 10px;
}

.profile-container {
    position: relative;
    width: 110%;
    z-index: 1002;
}

.profile-button {
    backdrop-filter: blur(8px);
    border: 1px solid rgba(217, 210, 219, 0.1) !important;
    width: calc(100% - 40px);
    z-index: 10000;
}

.profile-button:hover {
    transform: translateX(5px);
    border-color: rgba(190, 5, 250, 0.2);
    background: rgba(190, 5, 250, 0.05);
}

.profile-button.active {
    border-color: rgba(190, 5, 250, 0.25);
    box-shadow: 
        0 0 20px rgba(190, 5, 250, 0.1),
        inset 0 0 15px rgba(190, 5, 250, 0.05);
}

.profile-dropdown {
    position: absolute;
    bottom: 100%;
    left: 20px;
    width: calc(100% - 40px);
    min-width: 220px;
    background: linear-gradient(
        90deg, 
        var(--buttons-background-color),
        var(--screen-background-color)
    );
    border-radius: 12px;
    padding: 15px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(190, 5, 250, 0.2);
    box-shadow: 
        0 0 20px rgba(177, 173, 178, 0.1),
        inset 0 0 15px rgba(190, 5, 250, 0.05);
    z-index: 1001;
}

.profile-info {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
}


.profile-icon-wrapper {
    border-radius: 50%; /* Make the border circular */
    padding: 10px; /* Spacing around the icon */
    display: inline-flex; /* Align icon within the circle */
    justify-content: center;
    align-items: center;
    border: 1px solid white ;
    background-color:none; /* Add a background color */
    width: 40px; /* Fixed width for the circular wrapper */
    height: 40px; /* Fixed height for the circular wrapper */
}

.profile-icon {
    font-size: 20px;
    color: white;
 }

.profile-details h4 {
    margin: 0;
    color: white;
    font-size: 16px;
}

.profile-details p {
    margin: 2px 0 0;
    color: rgba(255,255,255,0.7);
    font-size: 12px;
}

.profile-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-actions button {
    background: transparent;
    color: white;
    border: none;
    padding: 10px;
    text-align: left;
    border-radius: 8px;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-actions button:hover {
    background: rgba(190, 5, 250, 0.2);
}
.profile-actions button::selection{
    background: rgba(190, 5, 250, 0.2);
}

.profile-actions .logout {
    color: #ff6b6b;
}

.profile-actions .logout:hover {
    background: rgba(190, 5, 250, 0.2);
    color: #ff6b6b;
}

/* Media Queries */

/* Large screens - 1500px */
@media screen and (max-width: 1590px) {
    .left-landing-page {
        flex: 0 0 18%;
    }

    .burger-menu-btn {
        display: none;
    }

    .logo {
        width: 170px;
        padding: 20px 25px;
    }

    .landing-services button {
        font-size: 16px;
        padding: 12px 16px;
        margin: 4px 16px;
    }

    .landing-services button img {
        width: 22px;
        height: 22px;
    }

    .profile-dropdown {
        min-width: 220px;
    }

    .profile-details h4 {
        font-size: 15px;
    }

    .profile-details p {
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
    }

    .profile-actions button {
        font-size: 14px;
        padding: 9px;
    }
}

@media screen and (max-width: 1400px) {

    .burger-menu-btn {
        display: none;
    }

    .profile-dropdown {
        min-width: 20px;
    }
    .profile-details h4 {
        font-size: 10px;
    }

    .profile-details p {
        font-size: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }

    .profile-actions button {
        font-size: 10px;
        padding: 7px;
    }
}

/* Medium-large screens - 1245px */
@media screen and (max-width: 1245px) {

    .burger-menu-btn {
        display: none;
    }

    .left-landing-page {
        flex: 0 0 22%;
    }

    .logo {
        width: 160px;
        padding: 18px 22px;
    }

    .landing-services button {
        font-size: 13px;
        padding: 10px 14px;
        margin: 3px 13px;
        gap: 10px;
    }

    .landing-services button img {
        width: 20px;
        height: 20px;
    }

    .nav-footer {
        padding: 0 7px;
    }

    .profile-container {
        width: 95%;
    }

    .profile-dropdown {
        min-width: 190px;
    }

    .profile-details h4 {
        font-size: 14px;
    }

    .profile-details p {
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }

    .profile-actions button {
        font-size: 13px;
        padding: 8px;
    }
}

/* Tablet screens - 1024px */
@media screen and (max-width: 1024px) {

    .burger-menu-btn {
        display: none;
    }

    .left-landing-page {
        flex: 0 0 28%;
    }

    .logo {
        width: 145px;
        padding: 16px 20px;
    }

    .landing-services button {
        font-size: 12px;
        padding: 9px 12px;
        margin: 3px 10px;
        gap: 8px;
    }

    .landing-services button img {
        width: 18px;
        height: 18px;
    }

    .profile-icon-wrapper {
        width: 30px;
        height: 30px;
    }

    .profile-details h4 {
        font-size: 12px;
    }

    .profile-details p {
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }
}

/* Medium tablet screens - 950px */
@media screen and (max-width: 950px) {

    .burger-menu-btn {
        display: none;
    }

    .left-landing-page {
        flex: 0 0 25%;
    }

    .logo {
        width: 135px;
        padding: 14px 18px;
    }

    .landing-services button {
        font-size: 11.5px;
        padding: 8px 10px;
        margin: 2px 8px;
        gap: 7px;
    }

    .landing-services button img {
        width: 16px;
        height: 16px;
    }

    .profile-icon-wrapper {
        width: 28px;
        height: 28px;
        transform: translateX(-7px);
    }

    .profile-details h4 {
        font-size: 11.5px;
    }

    .profile-details p {
        font-size: 9.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 130px;
    }

    .nav-footer button {
        font-size: 11px;
        padding: 7px 10px;
    }
}

@media screen and (max-width: 870px) {
    .profile-dropdown {
        min-width: 170px;
    }

    .profile-details h4 {
        font-size: 10px;   
    }   

    .profile-details p {
        font-size: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 110px;
    }

    .nav-footer button {
        font-size: 10px;
        padding: 5px 8px;
    }

    .burger-menu-btn {
        display: none;
    }
}

@media screen and (max-width: 785px) {
    
    .burger-menu-btn {
        display: none;
    }

    .profile-dropdown {
        min-width: 150px;
    }

    .profile-details h4 {
        font-size: 10px;   
    }   

    .profile-details p {
        font-size: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90px;
    }

    .nav-footer button {
        font-size: 10px;
        padding: 5px 8px;
    }
}

/* Small tablet screens - 715px */

@media screen and (max-width: 715px) {
    .burger-menu-btn {
        display: flex;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        color: white;
        cursor: pointer;
        padding: 8px;
        z-index: 1000;
    }

    .burger-menu-btn::before {
        content: '\2630';
        font-size: 20px;
    }

    .left-landing-page {
        flex: 0 0 18%;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 15px;
        width: 100%;
    }

    .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        padding: 8px 0;
        margin: 0;
    }

    .landing-services {
        position: fixed;
        top: 0;
        left: -100%;
        width: 250px;
        height: 100vh;
        background: var(--buttons-background-color);
        flex-direction: column;
        padding: 80px 20px 20px;
        transition: left 0.3s ease;
        z-index: 999;
    }

    .landing-services.menu-open {
        left: 0;
        max-width: 50%;
    }

    .landing-services button {
        width: 100%;
        height: auto;
        border-radius: 12px;
        font-size: 16px;
        padding: 12px 20px;
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        color: white;
    }

    .landing-services button img {
        width: 20px;
        height: 20px;
    }

    .menu-open::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    .main-landing-page {
        flex-direction: column;
        position: relative;
    }

    .profile-container {
        margin-left: auto;
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 1002;
        left: 83vw;
        top: -7.5vh;
    }

    .profile-button {
        width: 32px;
        height: 32px;
        padding: 0;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        z-index: 102;
        cursor: pointer;
        margin-right: 16px;
    }

    .profile-dropdown {
        position: fixed;
        top: 0;
        left: 70%;
        width: 230px;
        height: 35vh;
        background: var(--buttons-background-color);
        padding: 20px 20px 0px;
        z-index: 1001;
        transition: left 0.3s ease;
    }

    .profile-dropdown.open {
        left: 0;
    }

    .profile-dropdown::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        pointer-events: none;
    }

    .profile-dropdown.open::before {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .profile-details p {
        margin: 0;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    .profile-dropdown::-webkit-scrollbar {
        width: 6px;
    }

    .profile-dropdown::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 3px;
    }

    .profile-dropdown::-webkit-scrollbar-thumb {
        background-color: rgba(190, 5, 250, 0.5);
        border-radius: 3px;
    }

    .profile-dropdown::-webkit-scrollbar-thumb:hover {
        background-color: rgba(190, 5, 250, 0.7);
    }

    .profile-dropdown-content {
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .profile-info {
        margin-bottom: 12px;
    }

    .profile-actions button {
        padding: 8px 12px;
        font-size: 14px;
    }

    .version-info {
        display: none;
    }

    .right-landing-page {
        height: calc(100vh - 55px);
    }
    
}
    

/* Mobile screens - 530px */
@media screen and (max-width: 530px) {
    .burger-menu-btn {
        left: 15px;
        padding: 6px;
    }

    .burger-menu-btn::before {
        font-size: 18px;
    }

    .left-landing-page {
        min-height: 30px;
        height: 30px;
        padding: 0 12px;
    }

    .logo {
        width: 100px;
        padding: 6px 0;
    }

    .landing-services {
        width: 220px;
        padding: 60px 15px 15px;
    }

    .landing-services button {
        font-size: 14px;
        padding: 10px 16px;
        margin: 4px 0;
        gap: 12px;
    }

    .landing-services button img {
        width: 18px;
        height: 18px;
    }

    .profile-container {
        margin-left: auto;
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 1002;
        left: 83vw;
        top: -7.5vh;
    }

    .profile-button {
        width: 25px;
        height: 25px;
    }

    .profile-dropdown {
        position: fixed;
        top: 0;
        left: 65%;
        width: 220px;
        height: 30vh;
        background: var(--buttons-background-color);
        padding: 20px 15px 15px;
        z-index: 1001;
        transition: left 0.3s ease;
    }

    .profile-info {
        margin-bottom: 10px;
    }

    .profile-actions button {
        padding: 7px 10px;
        font-size: 13px;
    }

    .right-landing-page {
        height: calc(100vh - 30px);
    }

    .profile-icon-wrapper {
        width: 1px;
        height: 1px;
        transform: translateX(-7px);
    }

    
}

@media screen and (max-width: 445px) {
    .burger-menu-btn {
        left: 12px;
        padding: 5px;
    }

    .burger-menu-btn::before {
        font-size: 16px;
    }

    .left-landing-page {
        min-height: 25px;
        height: 25px;
        padding: 0 10px;
    }

    .logo {
        width: 85px;
        padding: 5px 0;
    }

    .landing-services {
        width: 200px;
        padding: 50px 12px 12px;
    }

    .landing-services button {
        font-size: 13px;
        padding: 8px 14px;
        margin: 3px 0;
        gap: 10px;
    }

    .landing-services button img {
        width: 16px;
        height: 16px;
    }

    .profile-container {
        margin-left: auto;
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 1002;
        left: 83vw;
        top: -7.5vh;
    }

    .profile-button {
        width: 22px;
        height: 22px;
    }

    .profile-dropdown {
        position: fixed;
        top: 0;
        left: 65%;
        width: 220px;
        height: 20vh;
        background: var(--buttons-background-color);
        padding: 20px 15px 15px;
        z-index: 1001;
        transition: left 0.3s ease;
    }

    .profile-info {
        margin-bottom: 8px;
    }

    .profile-details h4 {
        font-size: 13px;
    }

    .profile-details p {
        font-size: 11px;
        max-width: 120px;
    }

    .profile-actions button {
        padding: 6px 8px;
        font-size: 12px;
        gap: 8px;
    }

    .right-landing-page {
        height: calc(100vh - 10px);
    }
}

@media screen and (max-width: 350px) {
    .burger-menu-btn {
        left: 8px;
        padding: 4px;
    }

    .burger-menu-btn::before {
        font-size: 14px;
    }

    .left-landing-page {
        min-height: 20px;
        height: 20px;
        padding: 0 8px;
    }

    .logo {
        width: 70px;
        padding: 4px 0;
    }

    .landing-services {
        width: 180px;
        padding: 40px 10px 10px;
    }

    .landing-services button {
        font-size: 12px;
        padding: 6px 12px;
        margin: 2px 0;
        gap: 8px;
    }

    .landing-services button img {
        width: 14px;
        height: 14px;
    }

    .profile-container {
        margin-left: auto;
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 1002;
        left: 83vw;
        top: -7.5vh;
    }

    .profile-button {
        width: 20px;
        height: 20px;
    }

    .profile-dropdown {
        position: fixed;
        top: 0;
        left: 65%;
        width: 200px;
        height: 20vh;
        background: var(--buttons-background-color);
        padding: 20px 15px 15px;
        z-index: 1001;
        transition: left 0.3s ease;
    }

    .profile-info {
        margin-bottom: 6px;
    }

    .profile-details h4 {
        font-size: 11px;
    }

    .profile-details p {
        font-size: 9px;
        max-width: 100px;
    }

    .profile-actions button {
        padding: 4px 6px;
        font-size: 10px;
        gap: 6px;
    }

    .profile-icon-wrapper {
        width: 24px;
        height: 24px;
        padding: 3px;
    }

    .profile-icon {
        font-size: 12px;
    }
}


.logout-confirm-overlay {
    font: Poppins;
    position: fixed; /* Ensure it covers the screen */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.logout-confirm-model {
    background-color: var(--screen-background-color);
    color:white;
    width: 35%;
    min-height: fit-content;
    height: 25%;
    border: 2px solid var(--buttons-background-color);
    border-radius: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    padding: 1.5rem;
    justify-content: space-around;
    box-shadow: 0px 4px 10px rgba(68, 64, 64, 0.3); 
    gap:2rem;
}

.logout-cancel{
    position: absolute;
    right: 1rem;
    top:1rem;
    cursor: pointer;
}

.logout-model-header{
    display: flex;
    flex-direction: row;
    gap:1rem;
}
.logout-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap:1rem;
    button{
        font-size: 1rem;
        font-family: Poppins;
        border: 1px solid var(--buttons-background-color);
    }
    .logout-cancel-btn{
        background-color: transparent;
        border: 1px solid var(--buttons-background-color);
    }
}

.logout-buttons button:hover{
    opacity: 0.9;
}
.logout-buttons button:active{
    transform: scale(0.9);
}

@media (max-width:700px) {
    .logout-confirm-model{
        width: 80%;
        min-height: fit-content ;
    }
}
