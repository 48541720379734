.dashboard-container{
    margin:20px 40px;
    h3{
    font-size: 1.4rem;
    font-weight: 600;
    margin:0
    }
    p{
        margin-top:5px;
        color:rgb(51 65 85/var(--tw-text-opacity))
    }

}

.react-tabs__tab{
    background-color: white!important;
}
.prep-bot-status-tabs .react-tabs .react-tabs__tab-list {
    text-align: left;
    margin:0;

  }
  .prep-bot-status-tabs .react-tabs .react-tabs__tab-list .react-tabs__tab {
    margin-top: -10px;
    width: 150px;
    text-align: center;
    background-color: #fff;
    z-index: 1;
    color: #18181b;
    display: inline-block;
    border: none!important;
    padding: 5px 0px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
  }

  .prep-bot-status-tabs::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(to left top,#19114e,#705df2);
    border: none !important;
    transition: all 0.6s ease;
    opacity: 0;
    visibility: hidden;
  }
  .prep-bot-status-tabs .react-tabs .react-tabs__tab-list .react-tabs__tab:hover, .prep-bot-status-tabs .react-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    color: #fff;
    background: linear-gradient(to left top,#19114e,#705df2);
    border: none !important;
    outline: none;
  }

  .create{
    padding:12px 15px;
    background: linear-gradient(to left top,#19114e,#705df2);
    border: none !important;
    border-radius: 5px;
    color:white;
    font-size: 14px;
    cursor:pointer;
    float: right;  
    transition: all 0.3s ease;
    margin-bottom: 2px;
  }
  .create:hover {
    background: linear-gradient(to left top, #705df2, #19114e);  /* Reverse the gradient on hover */
    transform: scale(1.05);  /* Slightly increase the size */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);  /* Add a shadow */
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  th, td {
    padding: 8px 12px;
    text-align: left;
  }
  
  tr {
    border-bottom: 1px solid #dddddd74;
    font-size: 15px;
  }
  
  th {
    background-color: rgba(156, 156, 207, 0.204);
    font-weight: 600;
    font-size: 16px;
  }
  
  td {
    border: none;  /* Removes vertical lines on cells */
  }
  .prepbot-left{
    display: flex;
    flex-direction: row;
    margin:0px;
  }

  
  
